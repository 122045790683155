import { memo }           from 'react';
import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { TMobileOrganizationPreviewProps } from '../OrganizationPreview';

type HeaderProps = 'logoUri' | 'name';

type TPreviewHeaderProps = Pick<TMobileOrganizationPreviewProps['organization'], HeaderProps>;

const PreviewHeader = ({
  logoUri,
  name,
}: TPreviewHeaderProps) => {
  const { t } = useTranslation();

  return (
    <PreviewHeader.Wrapper>
      {logoUri ? (
        <img src={logoUri} alt="" />
      ) : (
        <p>{name || (<span>{t('organizationName')}</span>)}</p>
      )}
    </PreviewHeader.Wrapper>
  );
};

export default memo(PreviewHeader);

PreviewHeader.Wrapper = styled.div`
  align-items      : center;
  background-color : ${({ theme }) => theme.color.orange};
  display          : flex;
  height           : 40px;
  justify-content  : center;

  img { height: 22px; }

  span { opacity: 0.8; }

  p {
    color         : #fff;
    font-size     : 16px;
    line-height   : 23px;
    overflow-x    : hidden;
    padding       : 0 18px;
    text-align    : center;
    text-overflow : ellipsis;
    white-space   : nowrap;
  }
`;
