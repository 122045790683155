import { useCallback, useMemo } from 'react';
import { useHistory }           from 'react-router-dom';
import styled                   from 'styled-components';

import { Header as CommonHeader, TerritorySelect as CommonTerritorySelect } from '@common/components/Header';

import { AccountTypes, useAccountDispatch, useAccountState } from '@src/AccountContext';

import { routesConfig }     from '../Breadcrumbs/routesConfig';
import { NotificationBell } from '../NotificationBell/NotificationBell';

export const Header = () => {
  const history         = useHistory();
  const accountState    = useAccountState();
  const accountDispatch = useAccountDispatch();

  const onClickBilling     = useCallback(() => history.push(routesConfig.billing.endpoint), []);
  const onClickEditProfile = useCallback(() => history.push(routesConfig.profile.endpoint), []);
  const onClickHelpCenter  = useCallback(() => window.open(routesConfig.helpCenter.endpoint), []);
  const onClickLogo        = useCallback(() => history.push(routesConfig.dashboard.endpoint), []);

  const changeCurrentTerritory = useCallback((territoryId: number) => {
    if (!accountState.territories) {
      return;
    }

    accountDispatch({
      type         : AccountTypes.UpdateValues,
      newStateData : {
        ...accountState,
        currentTerritory : accountState.territories.find((territory) => territory.territoryId === territoryId),
      },
    });
  }, [accountState]);

  const onClickLogOut = useCallback(() => {
    localStorage.clear();
    history.replace('/login');
  }, []);

  const TerritorySelect = useMemo(() => (accountState.currentTerritory && (
    <CommonTerritorySelect
      currentTerritory  = {accountState.currentTerritory.territoryName}
      onSelectTerritory = {changeCurrentTerritory}
      territories       = {accountState.territories || []}
    />
  )), [accountState.currentTerritory, accountState.territories]);

  return (
    <CommonHeader
      accountImageUri    = {accountState.profileImage?.imageUri}
      NotificationBell   = {<NotificationBell />}
      onClickLogo        = {onClickLogo}
      onClickLogOut      = {onClickLogOut}
      onClickBilling     = {onClickBilling}
      onClickEditProfile = {onClickEditProfile}
      onClickHelpCenter  = {onClickHelpCenter}
      TerritorySelect    = {TerritorySelect}
      username           = {accountState.firstName ? `${accountState.firstName} ${accountState.lastName}` : null}
    />
  );
};

Header.LogoutWrapper = styled.div`
  cursor: pointer;
`;

Header.AlertBell = styled.div`
  cursor : pointer;
  height : 20px;
  width  : 20px;
`;

Header.LeftSide = styled.div<{ cursor: string; rotateAngle: string; }>`
  align-items: flex-end;

  > img { width: 106px; }

  p {
    font-size   : 14px;
    color       : ${({ theme }) => theme.color.black};
    cursor      : ${({ cursor }) => cursor};
    font-weight : 600;
    margin-left : 8px;
    min-width   : 100px;
    user-select : none;
  }

  span {
    display    : inline-block;
    font-size  : 18px;
    transform  : ${({ rotateAngle }) => `rotate(${rotateAngle})`};
    transition : all 0.3s;
  }
`;

Header.PopoverContent = styled.div`
  margin  : -12px -16px;
  padding : 10px 0;
`;

Header.TerritoryItem = styled.div`
  color      : ${({ theme }) => theme.color.black};
  cursor     : pointer;
  padding    : 5px 20px;
  transition : all 0.2s;

  &:hover {
    background-color : #0058ff1a;
    color            : ${({ theme }) => theme.color.blue};
  }
`;

Header.RightSide = styled.div`
  align-items: center;

  > p {
    color     : ${({ theme }) => theme.color.black};
    font-size : 14px;
    margin    : 0 20px 0 12px;
  }
`;

Header.Wrapper = styled.div`
  align-items     : center;
  display         : flex;
  height          : 100%;
  justify-content : space-between;
  padding         : 0 28px;
  width           : 100%;

  > div { display: flex; }
`;
