import { AxiosResponse } from 'axios';
import { useRef }        from 'react';
import useSWR            from 'swr';

import { IEssentialTipModel } from '@src/models';
import { callApi }            from '@utils/apiCaller';

type TEssentialTipsHook = () => {
  essentialTips         : IEssentialTipModel[];
  essentialTipsError    : { response?: { status: number } };
  essentialTipsFetching : boolean;
  refetchEssentialTips  : () => void;
}

const ORGANIZATION_TIPS_TYPE_ID = 1;

export const useEssentialTips: TEssentialTipsHook = () => {
  const essentialTips = useRef<IEssentialTipModel[]>([]);

  const { data, isValidating, mutate, error } = useSWR<AxiosResponse<IEssentialTipModel[]>>(
    `api/EssentialTip?type=${ORGANIZATION_TIPS_TYPE_ID}`,
    callApi,
  );

  if (data?.data) {
    essentialTips.current = data.data;
  }

  return {
    essentialTips         : essentialTips.current,
    essentialTipsError    : error,
    essentialTipsFetching : isValidating,
    refetchEssentialTips  : mutate,
  };
};
