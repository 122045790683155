import { Formik }                         from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { TFunction, useTranslation }      from 'react-i18next';
import * as Yup                           from 'yup';

import { ApproveModal, Modal } from '@common/components/Modal';
import { ECardStatuses }       from '@common/enums';
import { NoNullFields }        from '@common/models';

import { callApi }         from '@utils/apiCaller';
import { useAccountState } from '@src/AccountContext';

import { FeedForm } from '../components/FeedForm';

interface IFeedModal {
  closeModal   : () => void;
  refetchFeeds : () => void;
}

export type TFeedFormModel = {
  channelId     : number | null;
  notes         : string;
  publisherName : string;
  publisherUri  : string;
  territoryId   : number;
}

type TFeedPostModel = NoNullFields<TFeedFormModel>;

const useValidationSchema = (t: TFunction<'translation'>) => Yup.object().shape({
  publisherName : Yup.string()
    .max(100, `${t('maxCharactersLength')} 100`)
    .required(t('requiredField')),
  publisherUri : Yup.string()
    .url(t('notValidUrl'))
    .max(200, `${t('maxCharactersLength')} 200`)
    .required(t('requiredField')),
});

export const FeedModal = ({ closeModal, refetchFeeds }: IFeedModal) => {
  const { currentTerritory } = useAccountState();
  const { t }                = useTranslation();
  const validationSchema     = useValidationSchema(t);

  const [status, setStatus] = useState<ECardStatuses>(ECardStatuses.None);

  const initialValues = useMemo<TFeedFormModel>(() => ({
    notes         : '',
    publisherName : '',
    publisherUri  : '',
    channelId     : null,
    territoryId   : currentTerritory?.territoryId as number,
  }), [currentTerritory]);

  const onSubmit = useCallback(async (values: TFeedFormModel) => {
    const requestData: TFeedPostModel = {
      ...values,
      channelId : values.channelId as number,
    };

    setStatus(ECardStatuses.Pending);
    try {
      await callApi('/feature/FeedRequest', 'POST', requestData);
      refetchFeeds();
      setStatus(ECardStatuses.Success);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setStatus(ECardStatuses.Failure);
    }
  }, [refetchFeeds]);

  if (status === ECardStatuses.Success) {
    return (
      <ApproveModal
        comment     = {t('submittedFeedSuccessfulMessage')}
        title       = {`${t('thankYou')}!`}
        closeButton = {{
          label   : t('backToChannel'),
          onClick : closeModal,
        }}
      />
    );
  }

  return (
    <Modal
      onCancel = {closeModal}
      title    = {t('submitFeed')}
      width    = "718px"
    >
      <Formik
        initialValues    = {initialValues}
        onSubmit         = {onSubmit}
        validationSchema = {validationSchema}
      >
        {({ ...props }) => (
          <FeedForm
            {...props}
            disabled = {status === ECardStatuses.Pending}
          />
        )}
      </Formik>
    </Modal>
  );
};
