import { ISvgIconOptions } from './index';

export default ({ height = '22px', width = '22px' }: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 515 515">
    <path
      fill = "#cee1f2"
      d    = "M81,392V120c0-55.23,44.77-100,100-100h-61C64.77,20,20,64.77,20,120v272c0,55.23,44.77,100,100,100h61 C125.77,492,81,447.23,81,392z"
    />
    <path
      fill = "#0058ff"
      d    = "M392,512H120C53.83,512,0,458.17,0,392V120C0,53.83,53.83,0,120,0h272c66.17,0,120,53.83,120,120v272 C512,458.17,458.17,512,392,512z M120,40c-44.11,0-80,35.89-80,80v272c0,44.11,35.89,80,80,80h272c44.11,0,80-35.89,80-80V120 c0-44.11-35.89-80-80-80H120z"
    />
    <path
      fill = "#f0552a"
      d    = "M256,392c-11.05,0-20-8.95-20-20V219c0-11.05,8.95-20,20-20c11.05,0,20,8.95,20,20v153 C276,383.05,267.05,392,256,392z M256,120c-13.81,0-25,11.19-25,25s11.19,25,25,25s25-11.19,25-25S269.81,120,256,120"
    />
  </svg>
);
