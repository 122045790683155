interface IBellSettings {
  events : {
    new     : number[];
    watched : number[];
  };
  organizations : {
    new     : number[];
    watched : number[];
  };
}

export enum EBellStorageKey {
  Events        = 'BELL_EVENTS_KEY',
  Organizations = 'BELL_ORGANIZATIONS_KEY'
}

export const getBellSettingsFromLocalStorage = (): IBellSettings => {
  const eventsString        = localStorage.getItem(EBellStorageKey.Events);
  const organizationsString = localStorage.getItem(EBellStorageKey.Organizations);

  return {
    events        : eventsString ? JSON.parse(eventsString) : { new: [], watched: [] },
    organizations : organizationsString ? JSON.parse(organizationsString) : { new: [], watched: [] },
  };
};

export const setBellSettingsToLocalStorage = (settings: Partial<IBellSettings>) => {
  if (settings.events) {
    localStorage.setItem(EBellStorageKey.Events, JSON.stringify(settings.events));
  }
  if (settings.organizations) {
    localStorage.setItem(EBellStorageKey.Organizations, JSON.stringify(settings.organizations));
  }
};
