import { memo }           from 'react';
import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { TMobileOrganizationPreviewProps } from '../OrganizationPreview';
import { AlertIcon }                       from '../../../../../public/svg';

type AlertProps = 'alertHeader' | 'alertText';

type TPreviewAlertProps = Pick<TMobileOrganizationPreviewProps['organization'], AlertProps>;

const PreviewAlert = ({
  alertHeader,
  alertText,
}: TPreviewAlertProps) => {
  const { t } = useTranslation();

  if (!alertHeader && !alertText) {
    return null;
  }

  return (
    <PreviewAlert.Wrapper>
      <PreviewAlert.Header>
        <AlertIcon />
        <h2>{alertHeader || t('alertTitle')}</h2>
      </PreviewAlert.Header>
      <p>{alertText || t('alertDescription')}</p>
    </PreviewAlert.Wrapper>
  );
};

export default memo(PreviewAlert);

PreviewAlert.Header = styled.div`
  display       : flex;
  gap           : 11px;
  margin-bottom : 13px;

  h2 {
    color       : #fd9e26;
    font-size   : 16px;
    font-weight : 700;
    line-height : 22px;
    margin-top  : 8px;
  }
`;

PreviewAlert.Wrapper = styled.div`
  background-color : #fff9ed;
  border-top       : 15px solid #fd9e26;
  padding          : 22px 23px 97px;

  > p {
    color       : ${({ theme }) => theme.color.darkGray};
    font-size   : 13px;
    line-height : 17px;
  }
`;
