import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation }                            from 'react-i18next';
import { mutate }                                    from 'swr';

import { Table, TableProps } from '@common/components/Table/Table';
import { Card, CardHeader }  from '@common/components/Card';

import { ECardStatuses, ERequestedEntityStatus } from '@common/enums';

import { useFetchedEntity }  from '@hooks/useFetchedEntity';
import { useAccountState }   from '@src/AccountContext';
import { ILicenseFeedModel } from '@src/models';

import { FeedModal } from './FeedModal';

export const FeedsList = () => {
  const { t }                = useTranslation();
  const { currentTerritory } = useAccountState();

  const [currentPage, setPage]           = useState<number>(1);
  const [isModalVisible, setModalStatus] = useState<boolean>(false);
  const [status, setStatus]              = useState<ECardStatuses>(ECardStatuses.None);

  const [fetchedFeeds, isFetching] = useFetchedEntity<ILicenseFeedModel>(`feature/FeedRequest/license?page=${currentPage - 1}&itemsPerPage=10`);

  const onSubmitFeed = useCallback(() => {
    mutate(`feature/FeedRequest/license?page=${currentPage - 1}&itemsPerPage=10&territoryId=${currentTerritory?.territoryId}`);
  }, [currentPage]);

  const getFeedStatus = useCallback((feed: ILicenseFeedModel): ERequestedEntityStatus => {
    if (feed.isFeedRequest) {
      return feed.status;
    }

    return feed.status
      ? ERequestedEntityStatus.Approve
      : ERequestedEntityStatus.Deny;
  }, []);

  const header = useMemo(() => (
    <CardHeader
      buttons = {[{ action: () => setModalStatus(true), label: t('submitFeed') }]}
      title   = {t('feeds')}
    />
  ), []);

  const tableContent = useMemo<TableProps['content']>(() => (fetchedFeeds?.items || []).map((item) => ({
    publisher : item.publisherName,
    channel   : item.channelNames ? item.channelNames[0] : null,
    status    : {
      status : getFeedStatus(item),
      tag    : 'status',
    },
    id : item.id,
  })), [fetchedFeeds]);

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'publisher', label: t('publisher') },
    { id: 'channel', label: t('channel') },
    { id: 'status', label: t('status') },
  ], []);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    columns : [
      { flex: 4 },
      { flex: 2.5 },
      { flex: 1 },
    ],
    pagination : {
      setPage,
      current : currentPage,
      total   : fetchedFeeds?.totalCount || 0,
    },
  }), [fetchedFeeds, isFetching]);

  useEffect(() => {
    setStatus(isFetching ? ECardStatuses.Pending : ECardStatuses.None);
  }, [isFetching]);

  return (
    <>
      {isModalVisible && (
        <FeedModal
          closeModal   = {() => setModalStatus(false)}
          refetchFeeds = {onSubmitFeed}
        />
      )}
      <Card
        header = {header}
        status = {status}
        style  = {{ width: '948px' }}
      >
        <Table
          content  = {tableContent}
          headers  = {tableHeaders}
          settings = {tableSettings}
        />
      </Card>
    </>
  );
};
