import { AxiosError, AxiosResponse } from 'axios';
import { useHistory }                from 'react-router-dom';
import useSWR                        from 'swr';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';

import { IAccountData } from './models';
import { callApi }      from './utils/apiCaller';

export enum AccountTypes { UpdateValues }

type TAccountDispatch = (action: IAction) => void;

interface IAction {
  newStateData? : Partial<IAccountState>
  type          : AccountTypes,
}

export interface IAccountState extends Partial<IAccountData> {
  currentTerritory : IAccountData['territories'][0] | null;
  isAuthorized     : boolean;
}

const initialValues: IAccountState = {
  currentTerritory : null,
  email            : null,
  territories      : [],
  isAuthorized     : !!localStorage.getItem('accessToken'),
};

const AccountDispatchContext = createContext<TAccountDispatch | undefined>(undefined);
const AccountStateContext    = createContext<IAccountState>(initialValues);

const accountReducer = (state: IAccountState, action: IAction) => {
  switch (action.type) {
    case AccountTypes.UpdateValues: {
      return { ...state, ...(action.newStateData || null) };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const useAccountState = () => {
  const context = useContext(AccountStateContext);
  if (context === undefined) {
    throw new Error('useAccountState must be used within a AccountProvider');
  }

  return context;
};

const useAccountDispatch = () => {
  const context = useContext(AccountDispatchContext);
  if (context === undefined) {
    throw new Error('useAccountDispatch must be used within a AccountProvider');
  }

  return context;
};

const useFetchedAccountData = (isAuthorized: boolean): [IAccountData | null, number] => {
  const accountData = useRef<IAccountData | null>(null);

  const { data, error } = useSWR<AxiosResponse<IAccountData>, AxiosError>(isAuthorized ? 'LicenseAccount' : null, callApi);

  if (data?.data) {
    accountData.current = data.data;
  } else if (error?.response?.status === 401) {
    accountData.current = null;
  }

  return [accountData.current, error?.response?.status || 200];
};

const AccountProvider = ({ children }: { children: ReactNode }) => {
  const history = useHistory();

  const [state, dispatch] = useReducer(accountReducer, initialValues);

  const [fetchedAccountData, fetchStatus] = useFetchedAccountData(state.isAuthorized);

  const initializeAccountData = useCallback(async (newStateData: IAccountData) => {
    dispatch({
      type         : AccountTypes.UpdateValues,
      newStateData : { ...newStateData, currentTerritory: newStateData.territories[0], isAuthorized: true },
    });
  }, []);

  useEffect(() => {
    if (!state.email && fetchedAccountData) {
      initializeAccountData(fetchedAccountData);
    } else {
      dispatch({ type: AccountTypes.UpdateValues, newStateData: { ...state, ...fetchedAccountData } });
    }
  }, [fetchedAccountData]);

  useEffect(() => {
    const LOGIN_PAGE = '/login';

    if (fetchStatus === 401 && history.location.pathname !== LOGIN_PAGE) {
      dispatch({ type: AccountTypes.UpdateValues, newStateData: { ...state, isAuthorized: false } });
      history.replace(LOGIN_PAGE);
    }
  }, [fetchStatus]);

  return (
    <AccountStateContext.Provider value={state}>
      <AccountDispatchContext.Provider value={dispatch}>
        {children}
      </AccountDispatchContext.Provider>
    </AccountStateContext.Provider>
  );
};

export { AccountProvider, useAccountState, useAccountDispatch };
