import { format }                       from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation }               from 'react-i18next';
import { useHistory }                   from 'react-router-dom';

import { Table, TableProps } from '@common/components/Table/Table';
import { Card, CardHeader }  from '@common/components/Card';
import { ECardStatuses }     from '@common/enums';

import { routesConfig } from '@components/Breadcrumbs/routesConfig';

import { MailOutlined }          from '@ant-design/icons';
import { useFetchedEntity }      from '@hooks/useFetchedEntity';
import { useUnapprovalItems }    from '@hooks/useUnapprovalItems';
import { useAccountState }       from '@src/AccountContext';
import { IOrganizationListItem } from '@src/models';

import { OrganizationCopyIcon } from '../components/OrganizationCopyIcon';

export const OrganizationsList = () => {
  const history              = useHistory();
  const { t }                = useTranslation();
  const { currentTerritory } = useAccountState();

  const [currentPage, setPage] = useState<number>(1);
  const [status, setStatus]    = useState<ECardStatuses>(ECardStatuses.None);

  const [fetchedOrganizations, isFetching] = useFetchedEntity<IOrganizationListItem>(
    `feature/BusinessLocation/license/${currentTerritory?.territoryId}?page=${currentPage - 1}&itemsPerPage=10`,
    null,
    { useTerritoryId: false },
  );
  const [counters] = useUnapprovalItems();

  const header = useMemo(() => (
    <CardHeader
      entityCounter = {counters.organizations.length}
      title         = {t('reviewAndApprove')}
    />
  ), [counters]);

  const tableContent = useMemo<TableProps['content']>(() => (fetchedOrganizations?.items || []).map((item) => ({
    name : {
      onClick : () => history.push(routesConfig.organizationsReview.endpoint.replace(':id', item.businessLocationId.toString())),
      tag     : 'link',
      title   : item.name,
    },
    status : {
      status : item.status,
      tag    : 'status',
    },
    referalls : item.referalls,
    signedUp  : format(new Date(item.created), 'MMM dd, yyyy'),
    share     : {
      icon    : <OrganizationCopyIcon />,
      onClick : item.firebaseDynamicLink ? (() => navigator.clipboard.writeText(item.firebaseDynamicLink || '')) : null,
      tag     : 'icon',
    },
    email : {
      icon    : <MailOutlined />,
      onClick : item.email ? () => window.open(`mailto:${item.email}`) : null,
      tag     : 'icon',
    },
    id : item.businessLocationId,
  })), [fetchedOrganizations]);

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'name', label: t('name') },
    { id: 'status', label: t('status') },
    { id: 'referrals', label: t('referrals') },
    { id: 'signedUp', label: t('signedUp') },
    { id: 'share', label: t('share') },
    { id: 'email', label: t('email') },
  ], []);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    columns : [
      { flex: 4.7 },
      { flex: 1.6 },
      { flex: 1.4 },
      { flex: 1.6 },
      { flex: 1 },
      { flex: 1 },
    ],
    pagination : {
      setPage,
      current : currentPage,
      total   : fetchedOrganizations?.totalCount || 0,
    },
  }), [fetchedOrganizations, isFetching]);

  useEffect(() => {
    setStatus(isFetching ? ECardStatuses.Pending : ECardStatuses.None);
  }, [isFetching]);

  return (
    <Card
      header = {header}
      status = {status}
      style  = {{ width: '948px' }}
    >
      <Table
        content  = {tableContent}
        headers  = {tableHeaders}
        settings = {tableSettings}
      />
    </Card>
  );
};
