import { ISvgIconOptions } from './index';

export default ({ color, height, width }: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 24 35" fill={color}>
    <path
      fillRule = "evenodd"
      d        = "M0.307692308,12.3846154 C0.307692308,18.7576923 11.8461538,33.6631611 11.8461538,33.2583571 C11.8461538,32.8535532 23.3846154,18.7576923 23.3846154,12.3846154 C23.3846154,6.01153846 18.2153846,0.846153846 11.8461538,0.846153846 C5.47692308,0.846153846 0.307692308,6.01153846 0.307692308,12.3846154 Z M6.07692308,11.2307692 C6.07692308,14.4173077 8.66153846,17 11.8461538,17 C15.0307692,17 17.6153846,14.4173077 17.6153846,11.2307692 C17.6153846,8.04423077 15.0307692,5.46153846 11.8461538,5.46153846 C8.66153846,5.46153846 6.07692308,8.04423077 6.07692308,11.2307692 Z"
    />
  </svg>
);
