import { ISvgIconOptions } from './index';

export default ({ color, height, width }: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 24 24" fill={color}>
    <path d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M12,22C6.5,22,2,17.5,2,12S6.5,2,12,2s10,4.5,10,10   S17.5,22,12,22z" />
    <path d="M13.9,12.5c0-0.2,0.1-0.3,0.1-0.5c0-0.7-0.4-1.4-1-1.7V4c0-0.6-0.4-1-1-1s-1,0.4-1,1v6.3c-0.6,0.3-1,1-1,1.7   c0,1.1,0.9,2,2,2c0.2,0,0.3,0,0.5-0.1l1.8,1.8c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4L13.9,12.5z M12,13c-0.6,0-1-0.4-1-1s0.4-1,1-1   s1,0.4,1,1S12.6,13,12,13z" />
    <circle cx="20" cy="12" r="1" />
    <circle cx="4" cy="12" r="1" />
    <circle cx="17.7" cy="17.7" r="1" />
    <circle cx="6.3" cy="6.3" r="1" />
    <circle cx="12" cy="20" r="1" />
    <circle cx="6.3" cy="17.7" r="1" />
    <circle cx="17.7" cy="6.3" r="1"  />
  </svg>
);
