import { format }      from 'date-fns';
import { useCallback } from 'react';
import { useHistory }  from 'react-router-dom';
import styled          from 'styled-components';

import { Empty } from '@common/components/Empty';
import { Image } from '@common/components/Image';
import {
  ApprovedCircle,
  DeniedCircle,
  UnseenCircle,
} from '@common/components/StatusCircles';

import { ERequestedEntityStatus } from '@common/enums';
import { IEventModel }            from '@common/models';

import { routesConfig } from '@components/Breadcrumbs/routesConfig';

export const NewEvents = ({ events }: { events: IEventModel[] }) => {
  const history = useHistory();

  const onClickEventTitle = useCallback((event: IEventModel) => {
    if (event.creator === 'license') {
      history.push(routesConfig.eventEdit.endpoint.replace(':id', event.eventId.toString()));
    } else {
      history.push(routesConfig.eventReview.endpoint.replace(':id', event.eventId.toString()));
    }
  }, []);

  return (
    <NewEvents.Wrapper paddingBottom={events.length ? '32px' : '0'}>
      {!events.length && <Empty />}
      {events.map((event) => (
        <NewEvents.Event key={event.eventId}>
          <Image imageUri={event.rEventImage[0].image.imageUri} />
          <NewEvents.TitleDate>
            <NewEvents.Title onClick={() => onClickEventTitle(event)}>
              {event.title}
            </NewEvents.Title>
            <p>{format(new Date(event.dateStart as string), 'EEEE, MMM d')}</p>
          </NewEvents.TitleDate>
          {event.status === ERequestedEntityStatus.Approve && <ApprovedCircle />}
          {event.status === ERequestedEntityStatus.Deny && <DeniedCircle />}
          {[ERequestedEntityStatus.New, ERequestedEntityStatus.RequestChanges].includes(event.status) && (
            <UnseenCircle />
          )}
        </NewEvents.Event>
      ))}
    </NewEvents.Wrapper>
  );
};

NewEvents.Title = styled.p`
  color       : ${({ theme }) => theme.color.blue};
  cursor      : pointer;
  font-size   : 14px;
  font-weight : 500;
  line-height : 20px;
`;

NewEvents.Event = styled.div`
  align-items     : center;
  display         : flex;
  gap             : 13px;
  height          : 42px;
  justify-content : flex-start;
  width           : 100%;

  div:first-child {
    border-radius : 50%;
    height        : 42px;
    width         : 42px;
  }

  img {
    flex       : 0;
    object-fit : cover;
  }
`;

NewEvents.TitleDate = styled.div`
  flex  : 8;
  width : calc(100% - 115px);

  p {
    overflow      : hidden;
    text-overflow : ellipsis;
    white-space   : nowrap;
  }

  p:last-child {
    color       : ${({ theme }) => theme.color.gray};
    font-size   : 12px;
    line-height : 17px;
  }
`;

NewEvents.Wrapper = styled.div<{ paddingBottom: string }>`
  display        : flex;
  flex-direction : column;
  gap            : 28px;
  padding-bottom : ${({ paddingBottom }) => paddingBottom};
  width          : 100%;
`;
