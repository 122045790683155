import { ISvgIconOptions } from '../../../../models';

export default ({
  color,
  height = '30px',
  width  = '30px',
}: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 792 792" fill={color}>
    <g>
      <path
        className="st0"
        d="M373.1,480.8c-3.1-2.4-7.1-3.3-11-2.5c-1.4,0.3-31.6,6.9-56.2,28.7c-12.7-13.9-35.9-43.6-49.2-86.6l-7.9-26.7 c-6.6-28.9-6.4-53.5-5.3-67.4c2.3,0.1,4.5,0.2,6.7,0.2c29.8,0,54-11.7,55.3-12.3c3.5-1.7,6.1-4.9,7.1-8.7 c21.3-81.5-41-146-43.7-148.7c-3.1-3.1-7.4-4.5-11.7-3.9c-79.8,12.5-138.2,73.8-140.7,76.4c-2,2.1-3.2,4.7-3.6,7.6 c-4.4,35.2-5.8,69.4-4.4,101.5c0.3,7.4,6.7,13.2,14.1,12.9c7.4-0.3,13.2-6.6,12.9-14.1c-1.3-29.3-0.1-60.3,3.6-92.4 c7.7-7.4,29.5-27.1,59.6-43.3c6.2,8.2,18.4,26.4,26.9,51.6c1.9,5.6,7.2,9.2,12.8,9.2c1.4,0,2.9-0.2,4.4-0.7 c7.1-2.4,10.8-10.1,8.4-17.1c-8.4-24.7-19.7-43.4-27.5-54.4c9.6-3.7,19.8-6.9,30.4-9c12.2,14.8,44,59.3,33.7,111.4 c-10,3.7-31.7,10.2-53.4,5.6c-3.5-0.7-7.2,0-10.2,2s-5.1,5.1-5.8,8.6c-0.3,1.6-7.7,40.3,4.1,91.6c0.1,0.3,0.1,0.5,0.2,0.8l8,27.2 c0,0.1,0,0.1,0.1,0.2c21.4,68.8,64.5,106.5,66.3,108.1c2.7,2.4,6.3,3.5,9.9,3.2s6.9-2,9.2-4.7c2.9,4.1,6.5,9.7,10.3,16.4 c2.5,4.4,7.1,7,11.8,7c2.2,0,4.5-0.5,6.5-1.7c6.5-3.6,8.9-11.8,5.3-18.4c-4.8-8.7-9.5-15.7-13.1-20.7c9.6-5.9,19-9.4,24.8-11.2 C402,541.2,404.5,595.9,404,615c-9,4.5-18.2,8.1-27.4,11c-0.9-11.4-2.6-22.7-5.4-33.9c-1.8-7.2-9.1-11.7-16.3-9.9 c-7.2,1.8-11.7,9.1-9.9,16.3c2.8,11.2,4.4,22.6,5,34c-34.6,6.4-65,4.2-75.8,3.1c-60.5-61-100.9-125.4-120.2-191.4l-8-28.3 c-2-7.2-9.5-11.4-16.7-9.3c-7.2,2-11.4,9.5-9.3,16.7l8,28.4v0.1c20.9,71.6,64.8,141.1,130.4,206.3c2,2,4.7,3.3,7.5,3.8 c1.4,0.2,14.9,2.2,35.1,2.2c31.7,0,79.7-4.8,122.7-28.9c3.8-2.1,6.3-5.9,6.8-10.3C430.9,621,440.1,531.8,373.1,480.8z"
      />
      <path
        className="st0"
        d="M436.8,249.1c-6.6-3.3-14.7-0.7-18.1,6c-3.4,6.6-0.7,14.8,5.9,18.1c0.2,0.1,24.7,12.8,44.7,39.2 c26.3,34.6,33.1,75.9,20.4,122.7c-2,7.2,2.3,14.6,9.5,16.6c1.2,0.3,2.4,0.5,3.5,0.5c5.9,0,11.4-4,13-10 c18.9-69.5-3.6-118.4-25.7-147.1C466.3,264.1,438,249.7,436.8,249.1z"
      />
      <path
        className="st0"
        d="M598.8,356.2c-6-37.6-21.1-73-44.7-105.1c-39.9-54.3-90.1-80.2-92.2-81.3c-6.6-3.4-14.7-0.7-18.1,5.9 c-3.4,6.6-0.8,14.8,5.9,18.2c0.5,0.2,47.3,24.6,83.3,74.1c47.5,65.3,54.8,141.8,21.4,227.5c-2.7,6.9,0.7,14.8,7.7,17.5 c1.6,0.6,3.3,0.9,4.9,0.9c5.4,0,10.5-3.3,12.6-8.6C599.8,453.1,606.3,403,598.8,356.2z"
      />
      <path
        className="st0"
        d="M403.4,320c-5.7-3.9-13.5-2.3-17.3,3.4c-3.9,5.7-2.3,13.5,3.4,17.3c0.4,0.3,39,26.9,26,78.6 c-1.7,6.7,2.4,13.5,9.1,15.2c1,0.3,2,0.4,3,0.4c5.6,0,10.7-3.8,12.1-9.5c8.9-35.7-1.3-62.4-11.5-78.5 C417.5,329.8,404.8,321,403.4,320z"
      />
    </g>
  </svg>
);
