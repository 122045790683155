import { memo }           from 'react';
import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { TMobileOrganizationPreviewProps } from '../OrganizationPreview';

type TDescriptionProps = 'description';

type TPreviewDescriptionProps = Pick<TMobileOrganizationPreviewProps['organization'], TDescriptionProps>;

const PreviewDescription = ({ description }: TPreviewDescriptionProps) => {
  const { t } = useTranslation();

  const paragraphs = (description || '')
    .split('\n')
    .filter((paragraph: string) => paragraph.length > 0);

  return (
    <PreviewDescription.Wrapper>
      {paragraphs.length === 0 ? (
        <p>{t('fewWordsAboutOrganization')}</p>
      ) : (
        <>
          {paragraphs.map((paragraph) => (
            <div key={paragraph}>
              <p>{paragraph}</p>
            </div>
          ))}
        </>
      )}
    </PreviewDescription.Wrapper>
  );
};

export default memo(PreviewDescription);

PreviewDescription.Wrapper = styled.div`
  margin-bottom: 8px;

  p {
    color       : #454545;
    font-size   : 13px;
    line-height : 17px;
  }

  > p { color: #969696; }

  div:not(:last-of-type) { margin-bottom: 10px; }
`;
