import styled from 'styled-components';

import { Header } from '@components/Header/Header';
import { Footer } from '@common/components';

import { Menu }        from './Menu';
import { SupportChat } from './SupportChat';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';

interface IMenuItemContainer {
  children : React.ReactNode;
}

export const MenuItemContainer = ({ children }: IMenuItemContainer) => (
  <MenuItemContainer.Wrapper>
    <MenuItemContainer.HeaderWrapper>
      <Header />
    </MenuItemContainer.HeaderWrapper>
    <MenuItemContainer.ScrollArea>
      <MenuItemContainer.Body>
        <MenuItemContainer.SidebarWrapper>
          <Menu />
        </MenuItemContainer.SidebarWrapper>
        <MenuItemContainer.Content>
          <MenuItemContainer.BreadcrumbsWrapper>
            <Breadcrumbs />
            <SupportChat />
          </MenuItemContainer.BreadcrumbsWrapper>
          <div>
            {children}
          </div>
        </MenuItemContainer.Content>
      </MenuItemContainer.Body>
      <MenuItemContainer.Footer>
        <Footer />
      </MenuItemContainer.Footer>
    </MenuItemContainer.ScrollArea>
  </MenuItemContainer.Wrapper>
);

MenuItemContainer.BreadcrumbsWrapper = styled.div`
  display         : flex;
  justify-content : space-between;
  width           : 948px;
`;

MenuItemContainer.Footer = styled.div`
  position : relative;
  z-index  : 1;
`;

MenuItemContainer.SidebarWrapper = styled.div`
  background-color : #fff;
`;

MenuItemContainer.Body = styled.div`
  background-color : #f4f7fc;
  display          : flex;
  min-height       : calc(100% - 179px);
`;

MenuItemContainer.Content = styled.div`
  align-content  : flex-start;
  flex           : 1;
  padding-bottom : 40px;
  position       : relative;

  > div:first-child {
    margin-left : 40px;
    padding-top : 40px;
  }

  > div:nth-child(2) {
    display   : flex;
    flex-flow : row wrap;
    gap       : 20px;
    padding   : 0 40px 0 40px;
  }
`;

MenuItemContainer.ScrollArea = styled.div`
  flex     : 1;
  overflow : auto;
`;

MenuItemContainer.HeaderWrapper = styled.div`
  box-shadow : 0 1px 4px #15223214;
  flex       : 0 0 68px;
  z-index    : 300;
`;

MenuItemContainer.Wrapper = styled.div`
  display        : flex;
  flex-direction : column;
  height         : 100%;
  min-width      : 1285px;
`;
