import { memo } from 'react';
import styled   from 'styled-components';

import { useTranslation } from 'react-i18next';

import { StarIcon }                        from '../icons';
import { TMobileOrganizationPreviewProps } from '../OrganizationPreview';
import { theme as Theme }                  from '../../../../styles/theme';

type IPreviewReviewsProps = {
  reviews: NonNullable<TMobileOrganizationPreviewProps['reviews']>;
};

const PreviewReviews = ({ reviews }: IPreviewReviewsProps) => {
  const { t } = useTranslation();

  const getStarColor = (rating: number, index: number) => (
    rating >= (index + 1) ? Theme.color.orange : 'none'
  );

  if (reviews.length === 0) {
    return null;
  }

  return (
    <PreviewReviews.Wrapper>
      <div>
        {reviews.map((review) => (
          <PreviewReviews.Item>
            <PreviewReviews.Ratting>
              {Array.from({ length: 5 }).map((_, index) => (
                <div key={index.toString()}>
                  <StarIcon color={getStarColor(review.rating, index)} />
                </div>
              ))}
            </PreviewReviews.Ratting>
            <PreviewReviews.Text>
              <div>
                <span>{review.authorName}</span>
                <span>{review.text}</span>
              </div>
              <p>{t('readMore')}</p>
            </PreviewReviews.Text>
          </PreviewReviews.Item>
        ))}
      </div>
    </PreviewReviews.Wrapper>
  );
};

export default memo(PreviewReviews);

PreviewReviews.Item = styled.div`
  flex: 0 0 270px;
`;

PreviewReviews.Ratting = styled.div`
  display : flex;
  gap     : 5px;
`;

PreviewReviews.Text = styled.div`
  font-size   : 12px;
  line-height : 16px;
  position    : relative;

  div {
    display            : -webkit-box;
    margin-top         : 10px;
    overflow           : hidden;
    text-overflow      : ellipsis;
    -webkit-box-orient : vertical;
    -webkit-line-clamp : 4;
  }

  span:first-child {
    font-weight  : 600;
    margin-right : 8px;
  }

  span:last-of-type::before,
  span:last-of-type::after {
    content: '"';
  }

  p {
    background-color : #fff;
    bottom           : 0;
    box-shadow       : -15px 0 10px 4px #fff;
    color            : ${({ theme }) => theme.color.orange};
    position         : absolute;
    right            : 4px;
  }
`;

PreviewReviews.Wrapper = styled.div`
  padding-bottom: 19px;

  > div {
    box-shadow         : 0 0 6px 0 #90909057;
    display            : flex;
    gap                : 24px;
    margin-left        : 18px;
    overflow           : auto;
    padding            : 19px 16px;
    scrollbar-width    : none;
    -ms-overflow-style : none;

    ::-webkit-scrollbar { display: none; }
  }
`;
