import { ISvgIconOptions } from '../../../../models';

export default ({
  color,
  height = '30px',
  width  = '30px',
}: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 792 792" fill={color}>
    <g>
      <g>
        <path
          className="st0"
          d="M380,618.1c-115.6,0-209.7-94.1-209.7-209.7c0-35.7,9.2-71,26.5-102.1c3.6-6.5,11.9-8.8,18.4-5.2 c6.5,3.6,8.8,11.9,5.2,18.4c-15.1,27-23,57.8-23,88.9c0,100.8,82,182.7,182.7,182.7c100.8,0,182.7-82,182.7-182.7 c0-100.8-82-182.7-182.7-182.7c-33.1,0-65.6,9-93.8,25.9c-6.4,3.8-14.7,1.8-18.5-4.6c-3.8-6.4-1.8-14.7,4.6-18.5 C304.7,209,342,198.7,380,198.7c115.6,0,209.7,94.1,209.7,209.7S495.6,618.1,380,618.1z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M380,685.4c-74,0-143.5-28.8-195.8-81.1S103,482.4,103,408.4s28.8-143.5,81.1-195.8S306,131.4,380,131.4 s143.5,28.8,195.8,81.1S657,334.4,657,408.4c0,49.3-13.1,97.8-38,140.1c-3.8,6.4-12.1,8.6-18.5,4.8s-8.6-12.1-4.8-18.5 c22.4-38.2,34.3-81.9,34.3-126.4c0-137.8-112.1-250-250-250s-250,112.1-250,250c0,137.8,112.1,250,250,250 c37.1,0,72.8-7.9,106.2-23.6c6.7-3.2,14.8-0.3,18,6.5c3.2,6.7,0.3,14.8-6.5,18C460.7,676.6,421.1,685.4,380,685.4z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M455.2,502.9c-3.5,0-6.9-1.3-9.5-4l-75-75c-2.5-2.5-4-6-4-9.5V252.9c0-7.5,6-13.5,13.5-13.5s13.5,6,13.5,13.5 v155.8l71.1,71.1c5.3,5.3,5.3,13.8,0,19.1C462.1,501.6,458.7,502.9,455.2,502.9z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M384.9,578.2c-7.5,0-13.5-6-13.5-13.5v-19c0-7.5,6-13.5,13.5-13.5s13.5,6,13.5,13.5v19 C398.4,572.1,392.4,578.2,384.9,578.2z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M255.9,420.7h-19c-7.5,0-13.5-6-13.5-13.5s6-13.5,13.5-13.5h19c7.5,0,13.5,6,13.5,13.5 S263.4,420.7,255.9,420.7z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M528.9,421.5h-19c-7.5,0-13.5-6-13.5-13.5s6-13.5,13.5-13.5h19c7.5,0,13.5,6,13.5,13.5 S536.4,421.5,528.9,421.5z"
        />
      </g>
    </g>
  </svg>
);
