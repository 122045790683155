import { useCallback, useMemo, useState } from 'react';
import { useTranslation }                 from 'react-i18next';
import { mutate }                         from 'swr';
import * as Yup                           from 'yup';

import { Card }          from '@common/components/Card';
import { ECardStatuses } from '@common/enums';
import { ILocation }     from '@common/models';

import { INITIAL_LOCATION }  from '@common/components/PlacesAutocomplete/PlacesAutocomplete';
import { MemberDetailsForm } from '@common/modules/MemberDetails/components/MemberDetailsForm';
import {
  MemberDetails as CommonMemberDetails,
  IMemberDetailsValues,
} from '@common/modules/MemberDetails/MemberDetails';

import { MediaUploader } from '@components/MediaUploader/MediaUploader';

import { useAccountState } from '@src/AccountContext';
import { callApi }         from '@utils/apiCaller';

type TMemberDetailsFormModel = IMemberDetailsValues & {
  location: ILocation;
};

type TMemberDetailsPatchModel = Omit<IMemberDetailsValues, 'profileImage'> & {
  profileImage: {
    imageUri : string;
    imageId  : number | null;
    mimeType : 'image';
  };
}

const useValidationSchema = () => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => Yup.object().shape({
    location : Yup.object().shape({
      address1 : Yup.string()
        .required(t('requiredField')),
      city : Yup.string()
        .required(t('requiredField')),
      country : Yup.string()
        .required(t('requiredField')),
      googlePlaceId : Yup.string()
        .required(t('requiredField')),
      postCode : Yup.string()
        .required(t('requiredField')),
      state : Yup.string()
        .required(t('requiredField')),
    }),
  }), []);

  return validationSchema;
};

export const MemberDetails = () => {
  const { t } = useTranslation();

  const accountState     = useAccountState();
  const validationSchema = useValidationSchema();

  const [status, setStatus] = useState<ECardStatuses>(ECardStatuses.None);

  const initialValues = useMemo<TMemberDetailsFormModel>(() => ({
    firstName    : accountState.firstName || '',
    lastName     : accountState.lastName || '',
    location     : accountState.location || INITIAL_LOCATION,
    email        : accountState.email || '',
    phoneNumber  : accountState.phoneNumber || '',
    profileImage : accountState.profileImage ? {
      imageUri : accountState.profileImage.imageUri,
      mimeType : 'image',
    } : null,
  }), [accountState]);

  const onSubmit = useCallback(async (values: IMemberDetailsValues) => {
    const { profileImage, ...rest } = values;
    const prevProfileImage          = accountState.profileImage;

    const requestData: TMemberDetailsPatchModel = {
      ...rest,
      profileImage : {
        imageUri : values.profileImage?.imageUri as string,
        mimeType : 'image',
        imageId  : profileImage?.imageUri === prevProfileImage?.imageUri
          ? prevProfileImage?.imageId as number
          : null,
      },
    };

    try {
      await callApi('LicenseAccount/member-details', 'PATCH', requestData);
      mutate('LicenseAccount');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [accountState]);

  return (
    <CommonMemberDetails
      accountValues       = {initialValues}
      onSubmit            = {onSubmit}
      setCardStatus       = {setStatus}
      validationExtension = {validationSchema}
    >
      <Card
        header = {t('memberDetails')}
        status = {status}
        style  = {{ width: '465px' }}
      >
        <MemberDetailsForm
          withAddress
          disabled      = {status === ECardStatuses.Pending}
          MediaUploader = {MediaUploader}
        />
      </Card>
    </CommonMemberDetails>
  );
};
