import { Formik }                      from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation }              from 'react-i18next';
import * as Yup                        from 'yup';
import { Assign, ObjectShape }         from 'yup/lib/object';

import { ECardStatuses }       from '../../enums';
import { IImage }              from '../../models';
import { validatePhoneNumber } from '../../utils';

export interface IMemberDetailsValuesBase {
  email         : string | null;
  firstName     : string;
  lastName      : string;
  phoneNumber   : string | null;
  profileImage? : Pick<IImage, 'imageUri' | 'mimeType'> | null;
}

export type IMemberDetailsValues<WithProfileImage extends boolean = true> = WithProfileImage extends true
  ? Required<IMemberDetailsValuesBase>
  : IMemberDetailsValuesBase;

interface MemberDetailsProps<WithProfileImage extends boolean = true> {
  accountValues : IMemberDetailsValues<WithProfileImage>;
  children      : React.ReactNode;
  onSubmit      : (values: IMemberDetailsValues<WithProfileImage>) => Promise<void>;
  setCardStatus : (status: ECardStatuses) => void;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  validationExtension? : Yup.ObjectSchema<Assign<ObjectShape, Record<string, unknown>>>;
}

const useValidationSchema = (withProfileImage: boolean) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => Yup.object().shape({
    phoneNumber : validatePhoneNumber(t, true),
    firstName   : Yup.string()
      .max(50, `${t('maxCharactersLength')} 50`)
      .min(2, `${t('minCharactersLength')} 2`)
      .required(t('requiredField')),
    lastName : Yup.string()
      .max(50, `${t('maxCharactersLength')} 50`)
      .min(2, `${t('minCharactersLength')} 2`)
      .required(t('requiredField')),
    email : Yup.string()
      .email(t('itDoesNotLookLikeEmail'))
      .required(t('requiredField')),
    profileImage : withProfileImage
      ? Yup.object()
        .shape({ imageUri: Yup.string().required() })
        .typeError(t('uploadYourImage'))
        .required()
      : Yup.object().notRequired(),
  }), [withProfileImage]);

  return validationSchema;
};

export const MemberDetails = <WithProfileImage extends boolean = true>({
  accountValues,
  children,
  onSubmit,
  setCardStatus,
  validationExtension,
}: MemberDetailsProps<WithProfileImage>) => {
  const initialValidationSchema = useValidationSchema(typeof accountValues.profileImage !== 'undefined');

  const validationSchema = useMemo(() => {
    if (validationExtension) {
      return initialValidationSchema.concat(validationExtension);
    }

    return initialValidationSchema;
  }, [initialValidationSchema, validationExtension]);

  const onSubmitInner = useCallback(async (values: IMemberDetailsValues<WithProfileImage>) => {
    setCardStatus(ECardStatuses.Pending);
    try {
      await onSubmit(values);
      setCardStatus(ECardStatuses.Success);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setCardStatus(ECardStatuses.Failure);
    }
  }, [onSubmit]);

  return (
    <Formik
      enableReinitialize
      initialValues    = {accountValues}
      onSubmit         = {onSubmitInner}
      validationSchema = {validationSchema}
    >
      {children}
    </Formik>
  );
};
