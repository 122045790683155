import { Skeleton }            from 'antd';
import { useEffect, useState } from 'react';
import styled                  from 'styled-components';

import { NoImageIcon } from '../../../public/svg';

export const Image = ({ imageUri }: { imageUri: string }) => {
  const [validMediaUri, setMediaUri]      = useState<string | null>(null);
  const [isUploading, setUploadingStatus] = useState(false);

  const getValidMediaUri = async () => {
    setUploadingStatus(true);

    const http = new XMLHttpRequest();
    http.open('GET', imageUri, false);
    http.send();

    if (http.status !== 404) {
      setMediaUri(imageUri);
    } else {
      setMediaUri(null);
    }

    setUploadingStatus(false);
  };

  useEffect(() => {
    getValidMediaUri();
  }, [imageUri]);

  return (
    <Image.Wrapper withBorder={!validMediaUri}>
      {isUploading && <Skeleton active />}
      {!validMediaUri && <NoImageIcon height="42px" />}
      {!isUploading && validMediaUri && <Image.Component src={validMediaUri} />}
    </Image.Wrapper>
  );
};

Image.Component = styled.img`
  height : 100%;
  width  : 100%;
`;

Image.Wrapper = styled.div<{ withBorder: boolean }>`
  border   : ${({ theme, withBorder }) => (withBorder ? `1px solid ${theme.color.lightGray}` : 'none')};
  height   : 100%;
  overflow : hidden;
`;
