import { memo } from 'react';
import styled   from 'styled-components';

export interface IRelatedEntitiesItemModel {
  date     : string | JSX.Element;
  id       : number;
  imageUri : string;
  title    : string;
}

interface IRelatedEntitiesItemProps {
  item: IRelatedEntitiesItemModel;
}

const Component = ({ item }: IRelatedEntitiesItemProps) => (
  <Component.Wrapper>
    <img src={item.imageUri} alt="" />
    <Component.InfoBlock>
      <p>{item.date}</p>
      <Component.Title>{item.title}</Component.Title>
    </Component.InfoBlock>
  </Component.Wrapper>
);

export default memo(Component);

Component.InfoBlock = styled.div`
  padding: 2px 10px 0;

  p {
    color          : #aaa;
    font-size      : 9px;
    font-weight    : 600;
    line-height    : 11px;
    text-transform : uppercase;
  }
`;

Component.Title = styled.div`
  color              : ${({ theme }) => theme.color.orange};
  display            : block;
  display            : -webkit-box;
  font-size          : 19px;
  font-weight        : 800;
  line-height        : 22px;
  overflow           : hidden;
  text-overflow      : ellipsis;
  -webkit-box-orient : vertical;
  -webkit-line-clamp : 3;
`;

Component.Wrapper = styled.div`
  display: flex;

  img {
    height          : 100px;
    object-fit      : cover;
    object-position : center;
    width           : 100px;
  }
`;
