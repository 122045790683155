import { useCallback, useMemo } from 'react';

import { useAccountState } from '@src/AccountContext';

import { SupportChat as CommonSupportChat } from '@common/components';

import { useChat, UserProfileType } from '@common/hooks/useChat';

export const SupportChat = () => {
  const accountState = useAccountState();

  const userProfile = useMemo<UserProfileType<'License'> | null>(() => (
    accountState
      ? ({
        firstName                  : accountState.firstName || '',
        lastName                   : accountState.lastName || '',
        email                      : accountState.email as string,
        phone                      : accountState.phoneNumber || null,
        restoreId                  : null,
        cf_license_territory_names : accountState.territories?.map((territory) => territory.territoryName).join(',') || '',
        cf_license_territory_ids   : accountState.territories?.map((territory) => territory.territoryId).join(',') || '',
      })
      : null
  ),
  [accountState]);

  const { isChatReady, toggleChat } = useChat('License', userProfile);

  const onClickStartChat = useCallback(() => {
    if (isChatReady) {
      toggleChat();
    }
  }, [isChatReady]);

  return (
    <CommonSupportChat
      onClickStartChat = {onClickStartChat}
      workingHours     = "Mon – Fri: 9:00 am - 4:00 pm (PT)"
    />
  );
};
