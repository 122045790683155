import { Field, Form, FormikProps } from 'formik';
import { useCallback, useMemo }     from 'react';
import { useTranslation }           from 'react-i18next';
import { useHistory }               from 'react-router-dom';
import styled                       from 'styled-components';

import { FormButtons }               from '@common/components/Button';
import { DatePicker, Input, Select } from '@common/components';
import { TOption }                   from '@common/models';

import { AutoSuggest }         from '@components/AutoSuggest/AutoSuggest';
import { MultipleAutoSuggest } from '@components/AutoSuggest/MultipleAutoSuggest';
import { MediaUploader }       from '@components/MediaUploader/MediaUploader';
import { Wysiwyg }             from '@components/Wysiwyg/Wysiwyg';

import { useAccountState }                  from '@src/AccountContext';
import { IBusinessLocation, IChannelModel } from '@src/models';

import { TStoryFormModel } from '../views/Story';

interface IStoryForm extends FormikProps<TStoryFormModel> {
  disabled : boolean;
}

export const StoryForm = ({ disabled, values }: IStoryForm) => {
  const history              = useHistory();
  const { t }                = useTranslation();
  const { currentTerritory } = useAccountState();

  const storyStatuses = useMemo<TOption[]>(() => [
    { label: t('draft'), value: 0 },
    { label: t('active'), value: 1 },
  ], []);

  const mapChannels = useCallback((channels: IChannelModel[]): TOption[] => channels.map((item) => ({
    label  : item.name,
    source : item,
    value  : item.channelId,
  })), []);

  const mapOrganizations = useCallback((organizations: IBusinessLocation[]): TOption[] => organizations.map((item) => ({
    label  : item.name,
    source : item,
    value  : item.businessLocationId,
  })), []);

  return (
    <StoryForm.Wrapper>
      <StoryForm.Column>
        <Field
          component    = {Select}
          label        = {t('status')}
          name         = "status"
          options      = {storyStatuses}
          wrapperStyle = {{ margin: '0' }}
        />
        <Field
          component   = {Input}
          label       = {t('title')}
          name        = "title"
          placeholder = {t('enterTitle')}
        />
        <Field
          component   = {Input}
          label       = {t('author')}
          name        = "author"
          placeholder = {t('enterAuthor')}
        />
        <Field
          imageAvailable
          component    = {Wysiwyg}
          label        = {t('description')}
          name         = "description"
          wrapperStyle = {{ height: '382px' }}
        />
      </StoryForm.Column>
      <StoryForm.Column rightSide>
        <div>
          <Field
            component    = {DatePicker}
            label        = {t('starts')}
            name         = "startDateUtc"
            wrapperStyle = {{ margin: '0' }}
          />
          <Field
            component = {DatePicker}
            disabled  = {!values.startDateUtc}
            required  = {false}
            label     = {t('ends')}
            name      = "endDateUtc"
          />
          <Field
            component   = {Input}
            required    = {false}
            label       = {t('tags')}
            name        = "tags"
            placeholder = {`#${t('tag').toLowerCase()} #${t('tag').toLowerCase()}`}
            type        = "textarea"
          />
          <Field
            withResizer
            component       = {MediaUploader}
            label           = {t('previewImage')}
            name            = "rStoryImage"
            mimeTypeOptions = {['image']}
            imageStyle      = {{
              backgroundColor : '#E6E9F4',
              height          : '210px',
            }}
          />
          <Field
            component      = {MultipleAutoSuggest}
            endpoint       = {`feature/channel/search?territoryId=${currentTerritory?.territoryId}&name`}
            label          = {t('assignChannel')}
            name           = "rStoryChannel"
            placeholder    = {t('findSelectFitChannel')}
            responseMapper = {mapChannels}
          />
          <Field
            component      = {AutoSuggest}
            endpoint       = {`feature/businessLocation/search?territoryId=${currentTerritory?.territoryId}&name`}
            required       = {false}
            label          = {t('assignOrganization')}
            name           = "businessLocation"
            placeholder    = {t('findSelectOrganization')}
            responseMapper = {mapOrganizations}
          />
        </div>
        <FormButtons
          onClickCancel = {() => history.goBack()}
          disabled      = {{ main: disabled }}
        />
      </StoryForm.Column>
    </StoryForm.Wrapper>
  );
};

StoryForm.Wrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 80px;
  margin-top : 40px;
`;

StoryForm.Column = styled.div<{ rightSide?: boolean }>`
  flex  : 1;
  width : 100%;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
  `}
`;
