import { Field, Form, FormikProps } from 'formik';
import { useCallback, useState }    from 'react';
import { useTranslation }           from 'react-i18next';
import { useHistory }               from 'react-router-dom';
import styled                       from 'styled-components';

import { TOption } from '@common/models';

import { DatePicker, TIME_ZONE_OPTIONS } from '@common/components/DatePicker/DatePicker';
import {
  Checkbox,
  FormButtons,
  Input,
  Select,
} from '@common/components';
import {
  ConfirmationRecurringModal,
  RecurrenceEditor,
} from '@common/components/RecurrenceEditor';

import { MediaUploader }       from '@components/MediaUploader/MediaUploader';
import { MultipleAutoSuggest } from '@components/AutoSuggest/MultipleAutoSuggest';

import { useAccountState } from '@src/AccountContext';
import { IChannelModel }   from '@src/models';

import { TSlideFormModel } from '../views/Slide';

interface ISlideForm extends FormikProps<TSlideFormModel> {
  disabled : boolean;
}

export const SlideForm = ({ disabled, initialValues, setFieldValue, submitForm, values }: ISlideForm) => {
  const history              = useHistory();
  const { t }                = useTranslation();
  const { currentTerritory } = useAccountState();

  const [modalVisible, setModalStatus] = useState(false);

  const mapChannels = useCallback((channels: IChannelModel[]): TOption[] => channels.map((item) => ({
    label  : item.name,
    source : item,
    value  : item.channelId,
  })), []);

  const onClickSave = useCallback(() => {
    if (values.slideId && !!initialValues.withRegularRepeats) {
      setModalStatus(true);
    } else {
      submitForm();
    }
  }, [initialValues.withRegularRepeats, setFieldValue, submitForm, values.slideId]);

  const onConfirmRecurringModal = useCallback((withRecurring: boolean) => {
    setFieldValue('occurrenceChoice', withRecurring);
    setModalStatus(false);
    submitForm();
  }, [setFieldValue, submitForm]);

  return (
    <SlideForm.Wrapper>
      {modalVisible && (
        <ConfirmationRecurringModal
          entityName = "Slide"
          onCancel   = {() => setModalStatus(false)}
          onSelect   = {onConfirmRecurringModal}
        />
      )}
      <SlideForm.Column>
        <Field
          component    = {Input}
          label        = {t('title')}
          name         = "name"
          placeholder  = {t('enterTitle')}
          wrapperStyle = {() => 'margin: 0'}
        />
        <Field
          component   = {Input}
          required    = {false}
          label       = {t('headline')}
          name        = "text"
          placeholder = {t('appearsOnTheFooterOfTheImage')}
        />
        <Field
          component = {Checkbox}
          label     = {t('allDay')}
          name      = "allDay"
        />
        <Field
          component = {DatePicker}
          label     = {t('starts')}
          name      = "dateStart"
          timezone  = {values.timeZone}
          withTime  = {!values.allDay}
        />
        <Field
          component = {DatePicker}
          disabled  = {!values.dateStart}
          label     = {t('ends')}
          name      = "dateEnd"
          timezone  = {values.timeZone}
          withTime  = {!values.allDay}
        />
        <Field
          component = {Select}
          label     = {t('timezone')}
          name      = "timeZone"
          options   = {TIME_ZONE_OPTIONS}
        />
        <Field
          component = {Checkbox}
          label     = {t('addRegularRepeats')}
          name      = "withRegularRepeats"
        />
        {values.withRegularRepeats && (
          <Field
            component = {RecurrenceEditor}
            name      = "slideRecurrence.recurrenceRule"
          />
        )}
      </SlideForm.Column>
      <SlideForm.Column rightSide>
        <div>
          <Field
            withResizer
            component    = {MediaUploader}
            label        = {t('imageOrVideo')}
            name         = "image"
            wrapperStyle = {{ margin: '0' }}
            imageStyle   = {{
              backgroundColor : '#E6E9F4',
              height          : '210px',
            }}
          />
          {values.image?.mimeType === 'image' && (
            <Field
              component   = {Input}
              required    = {false}
              label       = {t('url')}
              name        = "websiteUri"
              placeholder = "https://"
            />
          )}
          <Field
            component      = {MultipleAutoSuggest}
            endpoint       = {`feature/Channel/search?territoryId=${currentTerritory?.territoryId}&name`}
            label          = {t('assignChannel')}
            name           = "rSlideChannel"
            placeholder    = {t('findSelectFitChannel')}
            responseMapper = {mapChannels}
          />
        </div>
        <FormButtons
          disabled      = {{ main: disabled }}
          onClickCancel = {() => history.goBack()}
          onSubmit      = {onClickSave}
        />
      </SlideForm.Column>
    </SlideForm.Wrapper>
  );
};

SlideForm.Wrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 80px;
  margin-top : 40px;
`;

SlideForm.Column = styled.div<{ rightSide?: boolean }>`
  flex  : 1;
  width : 100%;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
  `}
`;
