import styled from 'styled-components';

export const OrganizationReviewWrapper = styled.div`
  display    : flex;
  gap        : 20px;
  flex-wrap  : wrap;

  @media (min-width: 1284px) {
    > div {
      max-height : calc(100vh - 221px);
      min-height : 400px;
      overflow   : auto;
    }
  }
`;
