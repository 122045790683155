import { format }                       from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation }               from 'react-i18next';

import { Table, TableProps }                     from '@common/components/Table/Table';
import { Card }                                  from '@common/components/Card';
import { ECardStatuses, ERequestedEntityStatus } from '@common/enums';

import { useFetchedEntity } from '@hooks/useFetchedEntity';
import { useAccountState }  from '@src/AccountContext';

import { InvoiceModal } from '../components/InvoiceModal';

interface IFetchedInvoice {
  amount      : number;
  createdDate : string;
  id          : number;
}

export const InvoicesList = () => {
  const { t }                = useTranslation();
  const { currentTerritory } = useAccountState();

  const [currentPage, setPage]            = useState<number>(1);
  const [selectedInvoiceId, setInvoiceId] = useState<number | null>(null);
  const [status, setStatus]               = useState<ECardStatuses>(ECardStatuses.None);

  const [fetchedInvoices, isFetching] = useFetchedEntity<IFetchedInvoice>(
    `Subscription/transactions/territory/${currentTerritory?.territoryId}?page=${currentPage - 1}&itemsPerPage=10`,
    null,
    { useTerritoryId: false },
  );

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'invoiceNumber', label: `${t('invoice')} #` },
    { id: 'date', label: t('date') },
    { id: 'status', label: t('status') },
    { id: 'amount', label: t('amount') },
  ], []);

  const tableContent = useMemo<TableProps['content']>(() => (fetchedInvoices?.items || []).map((item) => ({
    invoiceNumber : {
      onClick : () => setInvoiceId(item.id),
      tag     : 'link',
      title   : item.id,
    },
    date   : format(new Date(item.createdDate), 'MM/dd/yy'),
    status : {
      status : ERequestedEntityStatus.Approve,
      tag    : 'status',
    },
    amount : item.amount,
    id     : item.id,
  })), [fetchedInvoices]);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    columns : [
      { flex: 1 },
      { flex: 1 },
      { flex: 1 },
      { flex: 1 },
    ],
    pagination : {
      setPage,
      current : currentPage,
      total   : fetchedInvoices?.totalCount,
    },
  }), [fetchedInvoices]);

  useEffect(() => {
    setStatus(isFetching ? ECardStatuses.Pending : ECardStatuses.None);
  }, [isFetching]);

  return (
    <>
      {selectedInvoiceId && (
        <InvoiceModal
          closeModal = {() => setInvoiceId(null)}
          invoiceId  = {selectedInvoiceId}
        />
      )}
      <Card
        status = {status}
        header = {t('invoices')}
      >
        <Table
          content  = {tableContent}
          headers  = {tableHeaders}
          settings = {tableSettings}
        />
      </Card>
    </>
  );
};
