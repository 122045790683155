import { ISvgIconOptions } from './index';

export default ({ height = '22px', width = '22px' }: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 515 515">
    <path
      fill = "#cee1f2"
      d    = "M79.99,391.94V177.52c-33-8.49-57.74-37.51-59.83-72.63L20,105.06v286.88C20,447.2,64.8,492,120.06,492h59.99 C124.79,492,79.99,447.2,79.99,391.94z"
    />
    <path
      fill = "#0058ff"
      d    = "M491.93,141.06c11.04,0,20-8.95,20-20v-21C511.93,44.89,467.04,0,411.86,0c0,0-313.46,0.05-314.23,0.14 c-26.39,0.92-51.73,12.24-70,31.39C8.9,51.15-0.86,76.67,0.06,103.52C0.02,104.03,0,391.94,0,391.94C0,458.14,53.86,512,120.06,512 h291.8c55.17,0,100.06-44.89,100.06-100.06V260.11c0-55.17-44.89-100.06-100.06-100.06H99.99c-32.11,0-58.43-25.09-59.93-57.13 c-0.76-16.29,5.1-31.84,16.5-43.79c11.57-12.12,27.81-19.07,44.56-19.07c0.59,0,310.74-0.08,310.74-0.08 c33.12,0,60.07,26.95,60.07,60.07v21C471.93,132.1,480.88,141.06,491.93,141.06L491.93,141.06z M99.99,200.05h311.88 c33.12,0,60.07,26.95,60.07,60.07v151.83c0,33.12-26.95,60.07-60.07,60.07h-291.8c-44.15,0-80.06-35.92-80.06-80.06V180.13 C56.72,192.66,77.5,200.05,99.99,200.05L99.99,200.05z"
    />
    <path
      fill = "#f0552a"
      d    = "M431.94,336.03c0,13.8-11.19,25-25,25c-13.8,0-25-11.19-25-25s11.19-25,25-25 C420.75,311.03,431.94,322.22,431.94,336.03z M431.94,100.06c0-11.04-8.95-20-20-20H99.99c-11.04,0-20,8.95-20,20s8.95,20,20,20 h311.95C422.98,120.06,431.94,111.11,431.94,100.06z"
    />
  </svg>
);
