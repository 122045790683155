import styled from 'styled-components';

export const FlexItemsColumn = ({ children }: { children: React.ReactNode }) => (
  <FlexItemsColumn.Wrapper>
    {children}
  </FlexItemsColumn.Wrapper>
);

FlexItemsColumn.Wrapper = styled.div`
  display   : flex;
  flex      : 0;
  flex-wrap : wrap;
  gap       : 20px;
`;
