import { Formik, FormikProps }                       from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TFunction, useTranslation }                 from 'react-i18next';
import { useHistory, useParams }                     from 'react-router-dom';
import * as Yup                                      from 'yup';

import { TOption, IEventModel, IImage }          from '@common/models';
import { INITIAL_LOCATION }                      from '@common/components/PlacesAutocomplete/PlacesAutocomplete';
import { Card }                                  from '@common/components/Card';
import { ECardStatuses, ERequestedEntityStatus } from '@common/enums';
import { validateTheCorrectTimeDirection }       from '@common/utils';

import { routesConfig }  from '@components/Breadcrumbs/routesConfig';

import { useEntityById }   from '@hooks/useEntityById';
import { useAccountState } from '@src/AccountContext';
import { callApi }         from '@src/utils/apiCaller';

import { useTags }   from '../hooks';
import { EventForm } from '../components/EventForm';

type TOmittedEventValues = 'businessLocation'
| 'businessLocationId'
| 'creator'
| 'dateEnd'
| 'dateStart'
| 'eventId'
| 'eventRecurrence'
| 'eventRecurrenceId'
| 'locationId'
| 'rEventTerritory'
| 'rEventImage'
| 'rEventTag';

type TEventPostModel = Omit<IEventModel, 'businessLocation' | 'creator' | 'dateEnd' | 'eventId' | 'rEventTerritory' | 'rEventImage' | 'eventRecurrence' | 'rEventTag'> & {
  dateEnd         : string | null;
  eventId?        : number;
  eventRecurrence : TEventFormModel['eventRecurrence'] | null;
  rEventTag       : { tagId: number }[];
  rEventTerritory : [{ territoryId: number }];
  rEventImage     : [{
    eventId? : number;
    imageId  : number | null;
    image    : Pick<IImage, | 'imageUri' | 'mimeType'> & { imageId: number | null };
  }]
}

export type TEventFormModel = Omit<IEventModel, TOmittedEventValues> & {
  businessLocation   : TOption | null;
  dateEnd            : string | null;
  dateStart          : string | null;
  eventId?           : number;
  occurrenceChoice   : boolean;
  rEventImage        : Pick<IImage, | 'imageUri' | 'mimeType'> | null;
  rEventTerritory    : [{ territoryId: number }];
  rEventTag          : number[];
  withRegularRepeats : boolean;
  eventRecurrence    : {
    recurrenceDateStart : string | null;
    recurrenceRule      : string | null;
  };
}

const useValidationSchema = (t: TFunction<'translation'>) => Yup.object().shape({
  dateStart : Yup.string()
    .typeError(t('requiredField'))
    .required(),
  dateEnd : Yup.string()
    .typeError(t('requiredField'))
    .test(...validateTheCorrectTimeDirection(t, 'dateStart'))
    .nullable(),
  description : Yup.string()
    .test(
      'max-length',
      t(`${t('maxCharactersLength')} 600`),
      (value?: string) => {
        if (!value || value.trim().length === 0) {
          return false;
        }

        const div        = document.createElement('div');
        div.innerHTML    = value.trim();
        const plaintText = div.textContent || div.innerText || '';

        return !(plaintText.length > 600);
      },
    )
    .required(t('requiredField')),
  rEventImage : Yup.object()
    .shape({ imageUri: Yup.string().required() })
    .typeError(t('uploadImageVideo'))
    .required(),
  title : Yup.string()
    .max(100, `${t('maxCharactersLength')} 100`)
    .required(t('requiredField')),
  websiteUri : Yup.string()
    .url(t('notValidUrl'))
    .max(200, `${t('maxCharactersLength')} 200`)
    .nullable(),
});

export const Event = () => {
  const history          = useHistory();
  const params           = useParams<{ id?: string }>();
  const { t }            = useTranslation();
  const validationSchema = useValidationSchema(t);

  const isClonePage = history.location.pathname.includes('/clone/');

  const [status, setStatus] = useState(ECardStatuses.None);

  const { currentTerritory }             = useAccountState();
  const { tags }                         = useTags();
  const [editableEvent, isEventFetching] = useEntityById<IEventModel>('feature/event', Number(params.id));

  const initialValues = useMemo<TEventFormModel>(() => ({
    allDay             : editableEvent?.allDay || false,
    dateEnd            : editableEvent?.dateEnd || null,
    dateStart          : editableEvent?.dateStart || null,
    description        : editableEvent?.description || '',
    eventId            : editableEvent?.eventId,
    location           : editableEvent?.location || INITIAL_LOCATION,
    occurrenceChoice   : false,
    rEventTerritory    : [{ territoryId: currentTerritory?.territoryId as number }],
    status             : ERequestedEntityStatus.Approve,
    rEventTag          : (editableEvent?.rEventTag || []).map(({ tag }) => tag.id),
    timeZone           : editableEvent?.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    title              : editableEvent?.title || '',
    websiteUri         : editableEvent?.websiteUri || '',
    withRegularRepeats : !!editableEvent?.eventRecurrence,
    businessLocation   : editableEvent?.businessLocation ? {
      label : editableEvent.businessLocation.name,
      value : editableEvent.businessLocation.businessLocationId,
    } : null,
    eventRecurrence : editableEvent?.eventRecurrence || {
      recurrenceDateStart : null,
      recurrenceRule      : '',
    },
    rEventImage : editableEvent?.rEventImage ? {
      imageUri : editableEvent.rEventImage[0].image.imageUri,
      mimeType : 'image',
    } : null,
  }), [editableEvent]);

  const eventTags = useMemo(() => (
    tags.map((tag) => ({
      label : tag.name,
      value : tag.id,
    }))
  ), [tags]);

  const onSubmit = useCallback(async (values: TEventFormModel) => {
    const { businessLocation, eventRecurrence, location, occurrenceChoice, ...rest } = values;
    const businessData = businessLocation?.source
      ? businessLocation.source
      : { businessLocationId: businessLocation?.value };

    const getEventRecurrence = () => {
      if (!values.withRegularRepeats || !eventRecurrence.recurrenceRule) { return null; }

      if (!editableEvent || !editableEvent.eventRecurrenceId) {
        return { ...eventRecurrence, recurrenceDateStart: values.dateStart };
      }

      return eventRecurrence;
    };

    const getImageId = () => {
      if (!editableEvent || isClonePage || !values.rEventImage) {
        return null;
      }

      const isSameImages = editableEvent.rEventImage[0].image.imageUri === values.rEventImage.imageUri;

      return isSameImages ? editableEvent.rEventImage[0].imageId : null;
    };

    const requestData: TEventPostModel = {
      ...rest,
      businessLocationId : (businessData as { businessLocationId: number }).businessLocationId,
      dateEnd            : values.dateEnd,
      dateStart          : values.dateStart as string,
      eventId            : isClonePage ? undefined : editableEvent?.eventId,
      eventRecurrence    : getEventRecurrence(),
      eventRecurrenceId  : (editableEvent && getEventRecurrence()) ? editableEvent.eventRecurrenceId : null,
      location           : location?.googlePlaceId ? location : null,
      locationId         : location?.googlePlaceId ? location?.locationId : null,
      rEventTag          : values.rEventTag.map((tagId) => ({ tagId })),
      rEventImage        : [{
        eventId : isClonePage ? undefined : editableEvent?.eventId,
        imageId : getImageId(),
        image   : {
          imageId  : getImageId(),
          imageUri : values.rEventImage?.imageUri as string,
          mimeType : values.rEventImage?.mimeType as NonNullable<IImage['mimeType']>,
        },
      }],
    };

    setStatus(ECardStatuses.Pending);
    try {
      const isPutMethod = editableEvent && !isClonePage;

      await callApi(`feature/event${isPutMethod ? `?withRecurring=${occurrenceChoice}` : ''}`, isPutMethod ? 'put' : 'post', requestData);
      history.push(routesConfig.events.endpoint);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setStatus(ECardStatuses.Failure);
    }
  }, [editableEvent]);

  useEffect(() => {
    setStatus(isEventFetching ? ECardStatuses.Initialization : ECardStatuses.None);
  }, [isEventFetching]);

  return (
    <Card
      header     = {t('eventDetails')}
      status     = {status}
      style      = {{ padding: '38px 32px 50px' }}
    >
      <Formik
        enableReinitialize
        initialValues    = {initialValues}
        onSubmit         = {onSubmit}
        validationSchema = {validationSchema}
      >
        {({ ...props }: FormikProps<TEventFormModel>) => (
          <EventForm
            {...props}
            disabled  = {status === ECardStatuses.Pending}
            eventTags = {eventTags}
          />
        )}
      </Formik>
    </Card>
  );
};
