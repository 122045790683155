import { useState, useCallback, useEffect } from 'react';

import { IEventModel } from '@common/models';
import { callApi }     from '@utils/apiCaller';

export const useEventsByBusinessLocationId = ({ businessLocationId }: { businessLocationId: number }) => {
  const [events, setEvents] = useState<IEventModel[]>([]);

  const fetchEvents = useCallback(async () => {
    try {
      const { data } = await callApi<IEventModel[]>(`feature/BusinessLocation/mobile/${businessLocationId}/events`);

      setEvents(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (businessLocationId) {
      fetchEvents();
    }
  }, [businessLocationId]);

  return { events };
};
