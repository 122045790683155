import { useMemo }        from 'react';
import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { Button }     from '@common/components/Button';
import { CardHeader } from '@common/components/Card';

import { OrganizationPreview as CommonOrganizationPreview } from '@common/components/PreviewEntity';

import { IBusinessLocation } from '@src/models';
import { theme as Theme }    from '@styles/theme';

import {
  useEventsByBusinessLocationId,
  useStoriesByBusinessLocationId,
} from '../hooks';

interface IOrganizationPreview {
  onClickApprove : () => void;
  onClickDeny    : () => void;
  onClickRequest : () => void;
  organization   : IBusinessLocation;
}

export const OrganizationPreview = ({
  onClickApprove,
  onClickDeny,
  onClickRequest,
  organization,
}: IOrganizationPreview) => {
  const { t } = useTranslation();

  const { events }  = useEventsByBusinessLocationId({ businessLocationId: organization.businessLocationId });
  const { stories } = useStoriesByBusinessLocationId({ businessLocationId: organization.businessLocationId });

  const buttons = [
    { background: Theme.color.red, label: t('deny'), onClick: onClickDeny },
    { background: Theme.color.lightOrange, label: t('requestChanges'), onClick: onClickRequest },
    { background: Theme.color.green, label: t('approve'), onClick: onClickApprove },
  ];

  const mappedOrganization = useMemo(() => {
    const { rBusinessBusinessCategory, rBusinessImage, ...rest } = organization;

    return {
      ...rest,
      categories       : rBusinessBusinessCategory,
      offer            : null,
      featuredScroller : rBusinessImage.map((item) => ({
        image : {
          ...item.image,
          mimeType : (item.image.mimeType === 'mp4' ? 'video' : item.image.mimeType) as 'youtube' | 'image' | 'video' | null,
        },
      })),
    };
  }, [organization]);

  const mappedStories = useMemo(() => stories.map((story) => ({
    ...story,
    imageUri : story.images[0].imageUri,
  })), [stories]);

  const mappedEvents = useMemo(() => events.map((event) => ({
    ...event,
    imageUri : event.rEventImage[0].image.imageUri,
  })), [events]);

  return (
    <OrganizationPreview.Wrapper>
      <OrganizationPreview.Header>
        <CardHeader title={t('preview')} />
        <OrganizationPreview.Buttons>
          {buttons.map((item) => (
            <Button
              {...item}
              bordered       = {false}
              key            = {item.label}
              type           = "button"
              componentStyle = {{
                background : item.background,
                color      : '#fff',
                padding    : '0 13px',

              }}
            />
          ))}
        </OrganizationPreview.Buttons>
      </OrganizationPreview.Header>
      <OrganizationPreview.Body>
        <CommonOrganizationPreview
          events       = {mappedEvents}
          organization = {mappedOrganization}
          stories      = {mappedStories}
        />
      </OrganizationPreview.Body>
    </OrganizationPreview.Wrapper>
  );
};

OrganizationPreview.Body = styled.div`
  padding-top : 20px;
  width       : 100%;
`;

OrganizationPreview.Buttons = styled.div`
  display : flex;
  gap     : 10px;
`;

OrganizationPreview.Content = styled.div`
  padding : 18px 18px 0;
  width   : 100%;
`;

OrganizationPreview.Header = styled.div`
  align-items     : center;
  border-bottom   : 1px solid ${({ theme }) => theme.color.lightGray};
  display         : flex;
  justify-content : space-between;
  padding-bottom  : 22px;

  > div:first-child { margin: 0; }
`;

OrganizationPreview.OrganizationBanner = styled.div`
  align-items      : center;
  background-color : ${({ theme }) => theme.color.orange};
  display          : flex;
  position         : relative;
  height           : 40px;
  justify-content  : center;

  img { height: 22px; }

  > p {
    color         : #fff;
    font-family   : ${({ theme }) => theme.font.futuraMedium};
    font-size     : 20px;
    line-height   : 26px;
    overflow-x    : hidden;
    text-align    : center;
    text-overflow : ellipsis;
    width         : 80%;
    white-space   : nowrap;
  }
`;

OrganizationPreview.Wrapper = styled.div`
  align-self : flex-start;
  max-height : 100%;

  hr { opacity: 0.2; }
`;
