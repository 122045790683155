import { useMemo }        from 'react';
import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { EventDeleteButton }    from './EventDeleteButton';
import { Button, CardHeader }   from '../../components';
import { getDateTimeIntervals } from '../../utils';

interface IHeaderButton {
  background : string;
  label      : string;
  onClick    : () => void;
}

interface IEventReview {
  buttons        : IHeaderButton[];
  onClickDelete? : (withRecurrence: boolean) => void;
  event          : {
    dateEnd          : string | null;
    dateStart        : string;
    description      : string;
    imageUri         : string;
    locationAddress? : string;
    organizationName : string;
    tags             : string[];
    timeZone         : string;
    title            : string;
    withRecurrence?  : boolean;
  };
}

export const EventPreview = ({
  buttons,
  event,
  onClickDelete,
}: IEventReview) => {
  const { t } = useTranslation();

  const { dateInterval, timeInterval } = useMemo(() => getDateTimeIntervals({ ...event }, 'EEEE, MMM d', 'K:mm aaa'), [event]);

  return (
    <EventPreview.Wrapper>
      <EventPreview.Header>
        <CardHeader title={t('preview')} />
        <EventPreview.Buttons>
          {onClickDelete && (
            <EventDeleteButton
              onClickDelete   = {onClickDelete}
              recurrenceEvent = {!!event.withRecurrence}
            />
          )}
          {buttons.map(({ label, onClick, background }) => (
            <Button
              bordered       = {false}
              key            = {label}
              label          = {label}
              onClick        = {onClick}
              type           = "button"
              componentStyle = {{
                background,
                color   : '#fff',
                padding : '0 13px',
              }}
            />
          ))}
        </EventPreview.Buttons>
      </EventPreview.Header>
      <EventPreview.Body>
        <img src={event.imageUri} alt="event" />
        <EventPreview.Title>{event.title}</EventPreview.Title>
        <EventPreview.Business>
          {t('broughtToYouBy')} <span>{event.organizationName || 'Newsie'}</span>
        </EventPreview.Business>
        <EventPreview.DateTime>
          <p>{dateInterval}</p>
          <p>{timeInterval}</p>
        </EventPreview.DateTime>
        <EventPreview.Venue>
          {event.locationAddress || 'No place'}
        </EventPreview.Venue>
        <EventPreview.Description dangerouslySetInnerHTML={{ __html: event.description }} />
        <EventPreview.Tags>
          {event.tags.map((item) => (
            <p key={item}>#{item}</p>
          ))}
        </EventPreview.Tags>
      </EventPreview.Body>
    </EventPreview.Wrapper>
  );
};

EventPreview.Body = styled.div`
  padding-top : 20px;

  > img { width: 100%; }
`;

EventPreview.Business = styled.p`
  border-bottom  : 1px solid ${({ theme }) => theme.color.lightGray};
  color          : ${({ theme }) => theme.color.black};
  font-family    : ${({ theme }) => theme.font.futuraMedium};
  font-size      : 18px;
  padding-bottom : 18px;

  > span {
    color       : ${({ theme }) => theme.color.orange};
    font-family : ${({ theme }) => theme.font.futuraDemi};
  }
`;

EventPreview.Buttons = styled.div`
  align-items : center;
  display     : flex;
  gap         : 10px;
`;

EventPreview.DateTime = styled.div`
  color       : ${({ theme }) => theme.color.black};
  font-family : ${({ theme }) => theme.font.futuraMedium};
  font-size   : 18px;

  > p:first-child {
    font-family : ${({ theme }) => theme.font.futuraDemi};
    margin      : 14px 0 9px;
  }
`;

EventPreview.Description = styled.div`
  font-family : ${({ theme }) => theme.font.roboto};
  margin      : 10px 0 12px;
`;

EventPreview.Header = styled.div`
  align-items     : center;
  border-bottom   : 1px solid ${({ theme }) => theme.color.lightGray};
  display         : flex;
  justify-content : space-between;
  padding-bottom  : 22px;

  > div:first-child { margin: 0; }
`;

EventPreview.Tags = styled.div`
  display     : flex;
  font-family : ${({ theme }) => theme.font.roboto};
  flex-wrap   : wrap;

  p {
    color     : ${({ theme }) => theme.color.orange};
    font-size : 14px;
  }
`;

EventPreview.Title = styled.p`
  color       : ${({ theme }) => theme.color.orange};
  font-family : ${({ theme }) => theme.font.benton};
  font-size   : 23px;
  line-height : 25px;
  margin      : 18px 0 12px;
`;

EventPreview.Venue = styled.p`
  color       : ${({ theme }) => theme.color.orange};
  font-family : ${({ theme }) => theme.font.futuraMedium};
  font-size   : 14px;
  margin-top  : 9px;
`;

EventPreview.Wrapper = styled.div`
  align-self : flex-start;
  max-height : 100%;
`;
