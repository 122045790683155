import styled from 'styled-components';

export const DashboardWrapper = styled.div`
  display : flex;
  gap     : 20px;

  > div:first-child {
    display        : flex;
    flex-direction : column;
    gap            : 20px;
    width          : 626px;
  }
`;
