import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation }                            from 'react-i18next';
import { useHistory }                                from 'react-router-dom';
import styled                                        from 'styled-components';

import {
  BillingIcon,
  ChannelIcon,
  DashboardIcon,
  EventsIcon,
  HelpIcon,
  OrganizationsIcon,
  ProfileIcon,
} from '@public/svg';

import { useUnapprovalItems } from '@hooks/useUnapprovalItems';

import { routesConfig, IRouteItem } from '../Breadcrumbs/routesConfig';

interface IMenuItem extends Omit<IRouteItem, 'path'> {
  icon : JSX.Element;
}

export const menuItems: IMenuItem[] = [
  {
    endpoint        : routesConfig.dashboard.endpoint,
    icon            : <DashboardIcon />,
    localizationKey : routesConfig.dashboard.localizationKey,
  },
  {
    endpoint        : routesConfig.profile.endpoint,
    icon            : <ProfileIcon />,
    localizationKey : routesConfig.profile.localizationKey,
  },
  {
    endpoint        : routesConfig.channel.endpoint,
    icon            : <ChannelIcon />,
    localizationKey : routesConfig.channel.localizationKey,
  },
  {
    endpoint        : routesConfig.organizations.endpoint,
    icon            : <OrganizationsIcon />,
    localizationKey : routesConfig.organizations.localizationKey,
  },
  {
    endpoint        : routesConfig.events.endpoint,
    icon            : <EventsIcon />,
    localizationKey : routesConfig.events.localizationKey,
  },
  {
    endpoint        : routesConfig.billing.endpoint,
    icon            : <BillingIcon />,
    localizationKey : routesConfig.billing.localizationKey,
  },
  {
    endpoint        : routesConfig.helpCenter.endpoint,
    icon            : <HelpIcon />,
    localizationKey : routesConfig.helpCenter.localizationKey,
  },
];

export const Menu = () => {
  const history    = useHistory();
  const { t }      = useTranslation();
  const [counters] = useUnapprovalItems();

  const [activeMenuTab, setMenuTab] = useState<string | null>(null);

  const currentPageUrl = useMemo(() => history.location.pathname, [history.location.pathname]);

  const openHelpCenterPage = () => {
    window.open(routesConfig.helpCenter.endpoint);
  };

  const getActiveMenuTab = useCallback(() => {
    const menuEndpoints   = menuItems.map((item) => item.endpoint);
    const currentUrlArray = currentPageUrl.split('/').filter((item) => item.length);
    const pageKey         = currentUrlArray[1];
    let newActiveMenuTab  = routesConfig.dashboard.endpoint;

    menuEndpoints.forEach((endpoint) => {
      if (endpoint.includes(pageKey)) {
        newActiveMenuTab = endpoint;
      }
    });

    setMenuTab(newActiveMenuTab);
  }, [currentPageUrl]);

  const getMenuItemCounter = useCallback((item: IMenuItem) => {
    if (item.endpoint === routesConfig.organizations.endpoint) {
      return counters.organizations.length;
    }

    if (item.endpoint === routesConfig.events.endpoint) {
      return counters.events.length;
    }

    return null;
  }, [counters]);

  const onClickMenuItem = useCallback((endpoint: string) => {
    if (endpoint === routesConfig.helpCenter.endpoint) {
      openHelpCenterPage();
    } else if (endpoint !== currentPageUrl) {
      history.replace(endpoint);
    }
  }, [currentPageUrl]);

  useEffect(() => {
    getActiveMenuTab();
  }, [currentPageUrl]);

  return (
    <Menu.Wrapper>
      {menuItems.map((item) => (
        <Menu.ItemWrapper
          key      = {item.endpoint}
          onClick  = {() => onClickMenuItem(item.endpoint)}
          selected = {item.endpoint === activeMenuTab}
        >
          <Menu.ItemLeftBorder selected={item.endpoint === activeMenuTab} />
          {item.icon}
          <Menu.ItemLabel>{t(item.localizationKey)}</Menu.ItemLabel>
          {!!getMenuItemCounter(item) && (
            <Menu.Counter>
              <p>{getMenuItemCounter(item)}</p>
            </Menu.Counter>
          )}
        </Menu.ItemWrapper>
      ))}
    </Menu.Wrapper>
  );
};

Menu.Counter = styled.div`
  align-items      : center;
  background-color : ${({ theme }) => theme.color.red};
  border-radius    : 12px;
  display          : flex;
  height           : 16px;
  justify-content  : center;
  margin-left      : 9px;
  min-width        : 26px;
  padding          : 0 6px;

  p {
    color       : #fff;
    font-size   : 12px;
    font-weight : 600;
  }
`;

Menu.ItemLabel = styled.h4`
  font-weight : 500;
  margin-left : 14px;
  white-space : nowrap;
`;

Menu.ItemLeftBorder = styled.div<{ selected: boolean }>`
  background-color : ${({ theme }) => theme.color.blue};
  border-radius    : 2px;
  height           : 100%;
  left             : ${({ selected }) => (selected ? '0' : '-4px')};
  position         : absolute;
  transition       : all 0.2s;
  width            : 4px;
`;

Menu.ItemWrapper = styled.div<{ selected: boolean }>`
  align-items      : center;
  background-color : ${({ selected, theme }) => (selected ? theme.color.ultraLightBlue : 'transparent')};
  border-radius    : 2px;
  cursor           : pointer;
  display          : flex;
  height           : 53px;
  margin-right     : 22px;
  overflow         : hidden;
  padding-left     : 30px;
  position         : relative;
  transition       : all 0.3s;
  user-select      : none;
  width            : 228px;

  > h4,
  svg {
    color      : ${({ selected, theme }) => (selected ? theme.color.blue : '#000')};
    transition : all 0.3s;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.ultraLightBlue};

    > h4,
    svg { color: ${({ theme }) => theme.color.blue}; }
  }
`;

Menu.Wrapper = styled.div`
  background-color : #fff;
  padding          : 20px 0;
`;
