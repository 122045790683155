import { ISvgIconOptions } from './index';

export default ({ height = '22px', width = '22px' }: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 515 485">
    <path
      fill = "#cee1f2"
      d    = "M80,380V140c0-44.18,35.82-80,80-80h-20h-40c-44.18,0-80,35.82-80,80v240c0,44.18,35.82,80,80,80h60 C115.82,460,80,424.18,80,380z"
    />
    <g>
      <path
        fill = "#0058ff"
        d    = "M412,40h-20V20c0-11.05-8.95-20-20-20s-20,8.95-20,20v80c0,11.05,8.95,20,20,20s20-8.95,20-20V80h20 c33.08,0,60,26.92,60,60v240c0,33.08-26.92,60-60,60H100c-33.08,0-60-26.92-60-60V140c0-33.08,26.92-60,60-60h20v20 c0,11.05,8.95,20,20,20s20-8.95,20-20V20c0-11.05-8.95-20-20-20s-20,8.95-20,20v20h-20C44.86,40,0,84.86,0,140v240 c0,55.14,44.86,100,100,100h312c55.14,0,100-44.86,100-100V140C512,84.86,467.14,40,412,40z"
      />
      <path
        fill = "#0058ff"
        d    = "M296,80c11.05,0,20-8.95,20-20s-8.95-20-20-20h-80c-11.05,0-20,8.95-20,20s8.95,20,20,20H296z"
      />
      <path
        fill = "#0058ff"
        d    = "M80,180c0,11.05,8.95,20,20,20h312c11.05,0,20-8.95,20-20s-8.95-20-20-20H100C88.95,160,80,168.95,80,180z"
      />
    </g>
    <path
      fill = "#f0552a"
      d    = "M341.86,245.86L236,351.72l-45.86-45.86c-7.81-7.81-20.47-7.81-28.28,0s-7.81,20.47,0,28.28l60,60 c7.81,7.81,20.47,7.81,28.28,0l120-120c7.81-7.81,7.81-20.47,0-28.28S349.67,238.05,341.86,245.86z"
    />
  </svg>
);
