import './RobotoCondensed-Regular.ttf';
import './RobotoCondensed-Regular.otf';
import './RobotoCondensed-Regular.woff';
import './RobotoCondensed-Regular.woff2';
import './FuturaPT_Medium.ttf';
import './FuturaPT_Medium.woff';
import './FuturaPT_Medium.woff2';
import './FuturaPT_Demi.ttf';
import './FuturaPT_Demi.woff';
import './FuturaPT_Demi.woff2';
import './henriette-bold-webfont.woff2';
import './henriette-bold-webfont.woff';
import './henriette-bold-webfont.ttf';
import './BasicSans-Light.woff';
import './BasicSans-Light.ttf';
import './BasicSans-Regular.woff';
import './BasicSans-Regular.ttf';
import './BasicSans-Bold.woff';
import './BasicSans-Bold.ttf';

const fonts = `
  @font-face {
    font-family : "Roboto Condensed Regular";
    src         :
      url("/fonts/RobotoCondensed-Regular.otf") format("opentype"), /* Open Type Font */
      url("/fonts/RobotoCondensed-Regular.woff2") format("woff2"),
      url("/fonts/RobotoCondensed-Regular.woff") format("woff"),
      url("/fonts/RobotoCondensed-Regular.ttf") format("truetype");
    font-weight : normal;
    font-style  : normal;
  }

  @font-face {
    font-family : "Futura PT Medium";
    src         :
      url("/fonts/FuturaPT_Medium.woff2") format("woff2"),
      url("/fonts/FuturaPT_Medium.woff") format('woff'),
      url("/fonts/FuturaPT_Medium.ttf") format('truetype');
    font-weight : normal;
    font-style  : normal;
  }

  @font-face {
  font-family : "Futura PT Demi";
  src         :
    url("/fonts/FuturaPT_Demi.woff2") format("woff2"),
    url("/fonts/FuturaPT_Demi.woff") format("woff"),
    url("/fonts/FuturaPT_Demi.ttf") format("truetype");
  font-weight : normal;
  font-style  : normal;
  }

  @font-face {
    font-family : "Henriette Bold";
    src         :
      url("/fonts/henriette-bold-webfont.woff2") format("woff2"),
      url("/fonts/henriette-bold-webfont.woff") format("woff"),
      url("/fonts/henriette-bold-webfont.ttf") format("truetype");
    font-weight : normal;
    font-style  : normal;
  }

  @font-face {
    font-family    : "Basic Sans Light";
    src            :
      url("/fonts/BasicSans-Light.woff") format("woff"),
      url("/fonts/BasicSans-Light.ttf") format("truetype");
    font-style     : normal;
    font-weight    : normal;
  }

  @font-face {
    font-family    : "Basic Sans Regular";
    src            :
      url("/fonts/BasicSans-Regular.woff") format("woff"),
      url("/fonts/BasicSans-Regular.ttf") format("truetype");
    font-style     : normal;
    font-weight    : normal;
  }

  @font-face {
    font-family    : "Basic Sans Bold";
    src            :
      url("/fonts/BasicSans-Bold.woff") format('woff'),
      url("/fonts/BasicSans-Bold.ttf") format('truetype');
    font-style     : normal;
    font-weight    : normal;
  }
`;

export { fonts };
