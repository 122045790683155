import { Field, Form }    from 'formik';
import { useTranslation } from 'react-i18next';

import { PhoneInput } from '../../../components/PhoneInput';

import {
  FormButtons,
  Input,
  PlacesAutocomplete,
} from '../../../components';

interface IMemberDetailsForm {
  disabled          : boolean;
  MediaUploader?    : React.ReactNode;
  withAddress?      : boolean;
  withProfileImage? : boolean;
}

export const MemberDetailsForm = ({
  disabled,
  MediaUploader,
  withAddress,
  withProfileImage = true,
}: IMemberDetailsForm) => {
  const { t } = useTranslation();

  return (
    <Form>
      {withProfileImage && MediaUploader && (
        <Field
          withResizer
          aspectRatio        = {1}
          component          = {MediaUploader}
          deleteIconPosition = "center"
          label              = {t('profilePhoto')}
          mimeTypeOptions    = {['image']}
          name               = "profileImage"
          imageStyle         = {{
            borderRadius : '50%',
            height       : '58px',
            margin       : '11px 0 0',
            width        : '58px',
          }}
        />
      )}
      <Field
        component    = {Input}
        label        = {t('firstName')}
        name         = "firstName"
        placeholder  = {t('firstName')}
        wrapperStyle = {() => 'margin: 18px 0 0;'}
      />
      <Field
        component   = {Input}
        label       = {t('lastName')}
        name        = "lastName"
        placeholder = {t('lastName')}
      />
      <Field
        component   = {Input}
        label       = {t('email')}
        name        = "email"
        placeholder = {t('yourEmailAddress')}
        type        = "email"
      />
      <Field
        component   = {PhoneInput}
        label       = {t('phone')}
        name        = "phoneNumber"
        placeholder = {t('yourPhoneNumber')}
      />
      {withAddress && (
        <>
          <Field
            component     = {PlacesAutocomplete}
            label         = {t('address')}
            name          = "location.address1"
            searchOptions = {{ types: ['address'] }}
          />
          <Field
            component = {Input}
            label     = {t('city')}
            name      = "location.city"
          />
          <Field
            component = {Input}
            label     = {t('state')}
            name      = "location.state"
          />
          <Field
            component = {Input}
            label     = {t('country')}
            name      = "location.country"
          />
          <Field
            component = {Input}
            label     = {t('zip')}
            name      = "location.postCode"
          />
        </>
      )}
      <FormButtons disabled={{ main: disabled }} />
    </Form>
  );
};
