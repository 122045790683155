import styled from 'styled-components';

import { ILocation } from '../../../models';
import {
  PreviewAlert,
  PreviewButtons,
  PreviewCarousel,
  PreviewCategories,
  PreviewDescription,
  PreviewEstablishedDate,
  PreviewEvents,
  PreviewFollowButton,
  PreviewHeader,
  PreviewReviews,
  PreviewStories,
} from './blocks';

interface IEvent {
  allDay    : boolean;
  dateEnd   : string | null;
  dateStart : string;
  eventId   : number;
  imageUri  : string;
  timeZone  : string;
  title     : string;
}

interface IOrganization {
  alertHeader      : string | null;
  alertText        : string | null;
  description      : string | null;
  establishedDate  : string | null;
  location         : ILocation | null;
  logoUri          : string | null;
  name             : string | null;
  offer            : null;
  operationalHours : string | null;
  phoneNumber      : string | null;
  websiteUri       : string | null;
  categories       : {
    categoryId   : number;
    categoryName : string;
  }[];
  featuredScroller : {
    image : {
      description : string | null;
      imageUri    : string;
      mimeType    : 'youtube' | 'image' | 'video' | null;
    };
  }[];
}

export interface IReview {
  authorName : string;
  rating     : number;
  text       : string;
  url        : string;
}

interface IStory {
  created  : string;
  imageUri : string;
  storyId  : number;
  title    : string;
}

export type TMobileOrganizationPreviewProps = {
  events?      : IEvent[];
  organization : IOrganization;
  reviews?     : IReview[];
  stories?     : IStory[];
}

export const OrganizationPreview = ({
  organization,
  events  = [],
  reviews = [],
  stories = [],
}: TMobileOrganizationPreviewProps) => (
  <OrganizationPreview.Wrapper>
    <PreviewHeader
      logoUri = {organization.logoUri}
      name    = {organization.name}
    />
    <OrganizationPreview.Body>
      <PreviewCarousel
        featuredScroller = {organization.featuredScroller}
      />
      <PreviewButtons
        location         = {organization.location}
        operationalHours = {organization.operationalHours}
        phoneNumber      = {organization.phoneNumber}
        offer            = {organization.offer}
        websiteUri       = {organization.websiteUri}
      />
      <PreviewEstablishedDate
        establishedDate = {organization.establishedDate}
        location        = {organization.location}
      />
      <PreviewDescription
        description = {organization.description}
      />
      <PreviewCategories
        categories = {organization.categories}
      />
      <PreviewFollowButton
        name = {organization.name}
      />
      <PreviewStories
        stories = {stories}
      />
      <PreviewEvents
        events = {events}
        name   = {organization.name}
      />
    </OrganizationPreview.Body>
    <PreviewReviews reviews={reviews} />
    <PreviewAlert
      alertHeader = {organization.alertHeader}
      alertText   = {organization.alertText}
    />
  </OrganizationPreview.Wrapper>
);

OrganizationPreview.Body = styled.div`
  padding: 19px 18px 0;
`;

OrganizationPreview.Wrapper = styled.div`
  box-shadow: 0 0 3px 0 #dfdfdf;
`;
