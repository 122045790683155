import { IAccountState } from '@src/AccountContext';

type TExtractEntityChannelFunc = (
  accountTerritory : IAccountState['currentTerritory'],
  entityChannels?  : {
    channelId   : number;
    channelName : string;
  }[],
) => {
  label : string;
  value : number;
}[];

export const extractEntityChannel: TExtractEntityChannelFunc = (accountTerritory, entityChannels) => {
  if (entityChannels) {
    return entityChannels.map((channel) => ({
      label : channel.channelName,
      value : channel.channelId,
    }));
  }
  if (accountTerritory?.channelId && accountTerritory.channelName) {
    return [{
      label : accountTerritory.channelName,
      value : accountTerritory.channelId,
    }];
  }

  return [];
};
