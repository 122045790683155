import { FlexItemsColumn } from '@components/Menu/FlexItemsColumn';

import { MemberDetails } from './views/MemberDetails';
import { PublicBio }     from './views/PublicBio';
import { ResetPassword } from './views/ResetPassword';

export const Profile = () => (
  <>
    <FlexItemsColumn>
      <MemberDetails />
      <ResetPassword />
    </FlexItemsColumn>
    <PublicBio />
  </>
);
