import styled from 'styled-components';

interface ReceiptFieldProps {
  label    : string;
  oneLine? : boolean;
  value    : (string | number)[];
}

export const ReceiptField = ({ label, value, oneLine = true }: ReceiptFieldProps) => (
  <ReceiptField.Wrapper oneLine={oneLine}>
    <span>{label}: </span>
    {value.map((item) => (<span>{item}</span>))}
  </ReceiptField.Wrapper>
);

ReceiptField.Wrapper = styled.p<{ oneLine: boolean }>`
  color       : ${({ theme }) => theme.color.black};
  line-height : 18px;

  > span:first-child {
    font-size      : 14px;
    font-weight    : 700;
    text-transform : capitalize;
  }

  > span:not(:first-child) {
    display     : ${({ oneLine }) => (oneLine ? 'inline-block' : 'block')};
    font-weight : 300;
    font-size   : 14px;
  }
`;
