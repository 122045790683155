import { format }         from 'date-fns';
import { useCallback }    from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory }     from 'react-router-dom';
import styled             from 'styled-components';

import { Empty } from '@common/components/Empty';

import { routesConfig }          from '@components/Breadcrumbs/routesConfig';
import { IOrganizationListItem } from '@src/models';

export const NewOrganizations = ({ organizations }: { organizations: IOrganizationListItem[] }) => {
  const history = useHistory();
  const { t }   = useTranslation();

  const onClickOrganizationName = useCallback((organizationId: number) => {
    history.push(routesConfig.organizationsReview.endpoint.replace(':id', organizationId.toString()));
  }, []);

  return (
    <NewOrganizations.Wrapper paddingBottom={organizations.length ? '32px' : '0'}>
      {!organizations.length && <Empty />}
      {organizations.map((item) => (
        <NewOrganizations.Organization key={item.businessLocationId}>
          <img src={item.iconUri} alt="organization icon" />
          <NewOrganizations.LabelDate>
            <NewOrganizations.OrganizationName onClick={() => onClickOrganizationName(item.businessLocationId)}>
              {item.name}
            </NewOrganizations.OrganizationName>
            <p>{t('signedUp')} {format(new Date(item.created), 'EEEE, MMM d')}</p>
          </NewOrganizations.LabelDate>
        </NewOrganizations.Organization>
      ))}
    </NewOrganizations.Wrapper>
  );
};

NewOrganizations.Organization = styled.div`
  align-items     : center;
  display         : flex;
  gap             : 13px;
  height          : 42px;
  justify-content : flex-start;
  width           : 100%;

  img {
    background    : lightgray;
    border-radius : 50%;
    flex          : 0;
    height        : 42px;
    object-fit    : cover;
    width         : 42px;
  }
`;

NewOrganizations.OrganizationName = styled.p`
  color       : ${({ theme }) => theme.color.blue};
  cursor      : pointer;
  font-size   : 14px;
  font-weight : 500;
  line-height : 20px;
`;

NewOrganizations.LabelDate = styled.div`
  flex  : 8;
  width : calc(100% - 115px);

  p {
    overflow      : hidden;
    text-overflow : ellipsis;
    white-space   : nowrap;
  }

  p:last-child {
    color       : ${({ theme }) => theme.color.gray};
    font-size   : 12px;
    line-height : 17px;
  }
`;

NewOrganizations.Wrapper = styled.div<{ paddingBottom: string }>`
  display        : flex;
  flex-direction : column;
  gap            : 28px;
  padding-bottom : ${({ paddingBottom }) => paddingBottom};
  width          : 100%;
`;
