import Carousel                                       from 'nuka-carousel';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation }                             from 'react-i18next';
import styled                                         from 'styled-components';

import { TMobileOrganizationPreviewProps } from '../OrganizationPreview';

type CarouselProps = 'featuredScroller';

type TPreviewCarouselProps = Pick<TMobileOrganizationPreviewProps['organization'], CarouselProps>;

const PreviewCarousel = ({ featuredScroller }: TPreviewCarouselProps) => {
  const wrapperRef = useRef<HTMLElement | null>(null);

  const { t } = useTranslation();

  const [carouselWidth, setCarouselWidth] = useState(0);

  const slides = useMemo(() => (
    featuredScroller
      .filter((slide) => !!slide.image.imageUri)
      .map((slide) => slide.image)
  ), [featuredScroller]);

  useEffect(() => {
    const onChangeWidth = () => {
      if (wrapperRef.current) {
        setCarouselWidth(wrapperRef.current.offsetWidth);
      }
    };

    onChangeWidth();

    window.onresize = onChangeWidth;

    return () => {
      window.onresize = () => null;
    };
  }, []);

  return (
    <PreviewCarousel.Wrapper
      ref   = {(ref) => { wrapperRef.current = ref; }}
      width = {carouselWidth}
    >
      {slides.length === 0 ? (
        <PreviewCarousel.Placeholder>
          <p>{t('organizationMedia')}</p>
        </PreviewCarousel.Placeholder>
      ) : (
        <Carousel
          withoutControls
          dragging = {slides.length > 0}
        >
          {slides.map((slide) => (
            <PreviewCarousel.MediaSlide key={slide.imageUri}>
              {slide.mimeType === 'image' && (
                <PreviewCarousel.Image>
                  <img src={slide.imageUri} alt="" />
                  {slide.description && (
                    <div><p>{slide.description}</p></div>
                  )}
                </PreviewCarousel.Image>
              )}
              {slide.mimeType === 'video' && (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video controls>
                  <source src={slide.imageUri} type="video/mp4" />
                </video>
              )}
              {slide.mimeType === 'youtube' && (
                <>
                  <iframe src={slide.imageUri} title="Organization YouTube video" />
                </>
              )}
            </PreviewCarousel.MediaSlide>
          ))}
        </Carousel>
      )}
    </PreviewCarousel.Wrapper>
  );
};

export default memo(PreviewCarousel);

PreviewCarousel.Image = styled.div`
  height   : 100%;
  position : relative;
  width    : 100%;

  > img { object-fit : cover; }

  > div {
    align-items : center;
    background  : rgba(0, 0, 0, 0.45);
    bottom      : 0;
    display     : flex;
    height      : 36px !important;
    position    : absolute;
    width       : 100%;
  }

  > div p {
    color         : #fff;
    font-size     : 12px;
    line-height   : 16px;
    padding       : 10px;
    text-overflow : ellipsis;
    white-space   : nowrap;
  }
`;

PreviewCarousel.MediaSlide = styled.div`
  background : #000;
  height     : 100%;
  width      : 100%;

  div {
    overflow : hidden;
    width    : 100%;
  }

  img,
  iframe,
  source,
  video {
    border-radius : 3px;
    height        : 100%;
    width         : 100%;
  }
`;

PreviewCarousel.Placeholder = styled.div`
  align-items     : center;
  display         : flex;
  height          : 100%;
  justify-content : center;
  opacity         : 0.8;
  width           : 100%;
`;

PreviewCarousel.Wrapper = styled.div<{ width: number }>`
  background-color : ${({ theme }) => theme.color.lightGray};
  height           : ${({ width }) => width / (16 / 9)}px;
  position         : relative;
  width            : 100%;

  div { height : 100%; }

  .slider-frame { height: 100% !important; }
`;
