import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation }                            from 'react-i18next';
import { useHistory }                                from 'react-router-dom';

import { BaseInput }                 from '@common/components';
import { ECardStatuses, ESortOrder } from '@common/enums';

import { Table, TableProps } from '@common/components/Table/Table';
import { Card, CardHeader }  from '@common/components/Card';
import {
  FiltrationSortingInfo,
  FiltrationSorting,
  IFilterOption,
  setFitFilterField,
} from '@common/components/FiltrationSorting';

import { useFetchQueryRules } from '@hooks/useFetchQueryRules';
import { useFetchedEntity }   from '@hooks/useFetchedEntity';

import { routesConfig } from '@components/Breadcrumbs/routesConfig';
import { IStoryModel }  from '@src/models';
import { callApi }      from '@utils/apiCaller';

import { StoriesListItem } from '../components/StoriesLIstItem';

const sortOptions = [
  { label: 'Created', value: 'created' },
  { label: 'Title', value: 'title' },
];

const filterOptions: IFilterOption[] = [{
  component          : (props) => setFitFilterField(BaseInput, props),
  initialFilterValue : '',
  label              : 'Author',
  value              : 'Author',
}];

export const FeaturedStories = () => {
  const history = useHistory();
  const { t }   = useTranslation();

  const [currentPage, setPage]             = useState<number>(1);
  const [popoverVisible, setPopoverStatus] = useState(false);
  const [status, setStatus]                = useState<ECardStatuses>(ECardStatuses.None);

  const { rules, handlers } = useFetchQueryRules(
    sortOptions,
    'stories-list',
    { filtration: {}, sorting: { ...sortOptions[0], order: ESortOrder.Desc } },
  );
  const [fetchedStories, isFetching, refetchStories] = useFetchedEntity<IStoryModel>(`feature/story?page=${currentPage - 1}&itemsPerPage=10`, rules);

  const tableHeaders = useMemo<TableProps['headers']>(() => [{ id: 'title', label: t('title') }], []);

  const header = useMemo(() => (
    <CardHeader
      buttons        = {[{ action: () => history.push(routesConfig.storyAdd.endpoint), label: t('createStory') }]}
      title          = {t('featuredStories')}
      popoverOptions = {{
        onVisibleChange : setPopoverStatus,
        visible         : popoverVisible,
        content         : <FiltrationSorting
          filterOptions = {filterOptions}
          sortOptions   = {sortOptions}
          {...handlers}
        />,
      }}
      additionalContent={(
        <FiltrationSortingInfo
          deleteFiltrationRule = {handlers.deleteFiltrationRule}
          rules                = {rules}
        />
      )}
    />
  ), [popoverVisible, rules, handlers]);

  const tableContent = useMemo<TableProps['content']>(() => (fetchedStories?.items || []).map((item) => ({
    title : {
      component : <StoriesListItem story={item} />,
      tag       : 'component',
    },
    id : item.storyId,
  })), [fetchedStories]);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    columns    : [{ flex: 1 }],
    settings   : { content: { gap: '22px' } },
    pagination : {
      setPage,
      current : currentPage,
      total   : fetchedStories?.totalCount || 0,
    },
  }), [fetchedStories, isFetching]);

  const deleteStories = useCallback(async (storiesIds: (number | string)[]) => {
    setStatus(ECardStatuses.Pending);

    try {
      for (let i = 0; i < storiesIds.length;) {
        // eslint-disable-next-line no-await-in-loop
        await callApi('feature/story', 'DELETE', storiesIds[i]);
        i += 1;
      }

      setStatus(ECardStatuses.Success);
      refetchStories();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setStatus(ECardStatuses.Failure);
    }
  }, [currentPage]);

  useEffect(() => {
    setStatus(isFetching ? ECardStatuses.Pending : ECardStatuses.None);
  }, [isFetching]);

  useEffect(() => {
    setPopoverStatus(false);
  }, [rules]);

  return (
    <Card
      header = {header}
      status = {status}
      style  = {{ width: '100%' }}
    >
      <Table
        content  = {tableContent}
        headers  = {tableHeaders}
        onDelete = {deleteStories}
        settings = {tableSettings}
      />
    </Card>
  );
};
