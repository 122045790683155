import { Formik }                         from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { TFunction, useTranslation }      from 'react-i18next';
import { mutate }                         from 'swr';
import * as Yup                           from 'yup';

import { Card }          from '@common/components/Card';
import { ECardStatuses } from '@common/enums';

import { IAccountData, IImage } from '@src/models';
import { useAccountState }      from '@src/AccountContext';

import { callApi } from '@utils/apiCaller';

import { PublicBioForm } from '../components/PublicBioForm';

type TPublicDetailsModel = Pick<IAccountData, 'bio' | 'contactEmail' | 'displayName' | 'facebookUri' | 'instagramUri' | 'photoImage' | 'twitterUri'>;

type TPublicDetailsPatchModel = Omit<TPublicDetailsFormModel, 'photoImage'> & {
  photoImage: {
    imageId  : number | null;
    imageUri : string;
    mimeType : 'image';
  }
}

export type TPublicDetailsFormModel = Omit<TPublicDetailsModel, 'photoImage'> & {
  photoImage: Pick<IImage, 'imageUri' | 'mimeType'> | null;
};

const useValidationSchema = (t: TFunction<'translation'>) => Yup.object().shape({
  bio : Yup.string()
    .required(t('requiredField')),
  contactEmail : Yup.string()
    .email(t('itDoesNotLookLikeEmail')),
  displayName : Yup.string()
    .max(101, `${t('maxCharactersLength')} 101`)
    .min(2, `${t('minCharactersLength')} 2`)
    .required(t('requiredField')),
  photoImage : Yup.object()
    .shape({ imageUri: Yup.string().required() })
    .typeError(t('uploadYourImage'))
    .required(),
});

export const PublicBio = () => {
  const accountState     = useAccountState();
  const { t }            = useTranslation();
  const validationSchema = useValidationSchema(t);

  const [status, setStatus] = useState<ECardStatuses>(ECardStatuses.None);

  const initialValues = useMemo<TPublicDetailsFormModel>(() => ({
    bio          : accountState.bio || '',
    contactEmail : accountState.contactEmail || '',
    displayName  : accountState.displayName || '',
    facebookUri  : accountState.facebookUri || '',
    instagramUri : accountState.instagramUri || '',
    twitterUri   : accountState.twitterUri || '',
    photoImage   : accountState.photoImage ? {
      imageUri : accountState.photoImage.imageUri,
      mimeType : 'image',
    } : null,
  }), [accountState]);

  const onSubmit = useCallback(async (values: TPublicDetailsFormModel) => {
    const prevPhotoImage = accountState.photoImage;
    const {
      contactEmail,
      facebookUri,
      instagramUri,
      photoImage,
      twitterUri,
      ...rest
    } = values;

    const requestData: TPublicDetailsPatchModel = {
      ...rest,
      contactEmail : contactEmail || null,
      facebookUri  : facebookUri || null,
      instagramUri : instagramUri || null,
      twitterUri   : twitterUri || null,
      photoImage   : {
        imageUri : photoImage?.imageUri as string,
        mimeType : 'image',
        imageId  : prevPhotoImage?.imageUri === photoImage?.imageUri
          ? prevPhotoImage?.imageId as number
          : null,
      },
    };

    setStatus(ECardStatuses.Pending);
    try {
      await callApi('LicenseAccount/public-bio', 'PATCH', requestData);
      setStatus(ECardStatuses.Success);
      mutate('LicenseAccount');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setStatus(ECardStatuses.Failure);
    }
  }, []);

  return (
    <Formik
      initialValues    = {initialValues}
      onSubmit         = {onSubmit}
      validationSchema = {validationSchema}
    >
      <Card
        header = {t('publicBio')}
        status = {status}
        style  = {{ width: '465px' }}
      >
        <PublicBioForm
          disabled = {status === ECardStatuses.Pending}
          t        = {t}
        />
      </Card>
    </Formik>
  );
};
