import { ISvgIconOptions } from './index';

export default ({ height = '22px', width = '22px' }: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 515 515">
    <path
      fill = "#cee1f2"
      d    = "M80,392V120c0-55.23,44.77-100,100-100l211.95-0.78L392,19l-272,1C64.77,20,20,64.77,20,120v272 c0,55.23,44.77,100,100,100l52.06-0.31C120.54,487.64,80,444.55,80,392z"
    />
    <path
      fill = "#0058ff"
      d    = "M392,512H120C53.83,512,0,458.17,0,392V120C0,53.83,53.83,0,120,0h272c66.17,0,120,53.83,120,120v272 C512,458.17,458.17,512,392,512z M120,40c-44.11,0-80,35.89-80,80v272c0,44.11,35.89,80,80,80h272c44.11,0,80-35.89,80-80V120 c0-44.11-35.89-80-80-80H120z M432,129c0-11.05-8.95-20-20-20H100c-11.05,0-20,8.95-20,20s8.95,20,20,20h312 C423.05,149,432,140.05,432,129z M433,316c0-11.05-8.95-20-20-20H296c-11.05,0-20,8.95-20,20s8.95,20,20,20h117 C424.05,336,433,327.05,433,316z M237,316c0-11.05-8.95-20-20-20H100c-11.05,0-20,8.95-20,20s8.95,20,20,20h117 C228.05,336,237,327.05,237,316z M237,396c0-11.05-8.95-20-20-20H100c-11.05,0-20,8.95-20,20s8.95,20,20,20h117 C228.05,416,237,407.05,237,396z M392,396c0-11.05-8.95-20-20-20h-76c-11.05,0-20,8.95-20,20s8.95,20,20,20h76 C383.05,416,392,407.05,392,396z"
    />
    <path
      fill = "#f0552a"
      d    = "M413,256H189c-11.05,0-20-8.95-20-20s8.95-20,20-20h224c11.05,0,20,8.95,20,20S424.05,256,413,256z M80,234 c0,13.81,11.19,25,25,25s25-11.19,25-25s-11.19-25-25-25S80,220.19,80,234z"
    />
  </svg>
);
