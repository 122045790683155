import { memo }           from 'react';
import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { TMobileOrganizationPreviewProps } from '../OrganizationPreview';

type EstablishedDateProps = 'establishedDate' | 'location';

type TPreviewEstablishedDateProps = Pick<TMobileOrganizationPreviewProps['organization'], EstablishedDateProps>;

const PreviewEstablishedDate = ({
  establishedDate,
  location,
}: TPreviewEstablishedDateProps) => {
  const { t } = useTranslation();

  if (!establishedDate && !location) {
    return null;
  }

  return (
    <PreviewEstablishedDate.Wrapper>
      <p>
        {!!establishedDate && (
          <>
            {t('establishedIn')} {new Date(establishedDate).getFullYear()}
          </>
        )}
        {!!location?.city && !!location?.state && (
          <>
            {!!establishedDate && ' { '}
            <span>{location?.city}, {location.state}</span>
            {!!establishedDate && ' }'}
          </>
        )}
      </p>
    </PreviewEstablishedDate.Wrapper>
  );
};

export default memo(PreviewEstablishedDate);

PreviewEstablishedDate.Wrapper = styled.div`
  color         : #454545;
  display       : flex;
  font-size     : 10px;
  line-height   : 12px;
  margin-bottom : 9px;

  span { font-weight : 600; }
`;
