import { ISvgIconOptions } from '../../../../models';

export default ({
  color,
  height = '70px',
  width  = '70px',
}: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 792 792" fill={color}>
    <g>
      <path
        className="st0"
        d="M616.7,334.2l-55.2-42.8c-5.9-4.6-14.4-3.5-18.9,2.4c-4.6,5.9-3.5,14.4,2.4,18.9l55.2,42.8 c17.6,13.7,21.6,38.2,8.9,54.5L487.4,566.7c-12.7,16.4-37.4,18.6-55,4.9L159.8,360c-10.6-8.2-16.7-20.8-16.3-33.6l2.4-80.6 c1.3-43.8,30.7-80.6,73.2-91.6l72.2-18.7c12-3.1,25.3-0.2,35.6,7.8L451,239.7c5.9,4.6,14.4,3.5,18.9-2.4c4.6-5.9,3.5-14.4-2.4-18.9 L343.4,122c-16.8-13.1-38.9-17.8-58.9-12.6l-72.2,18.7c-54.2,14-91.8,61-93.4,116.9l-2.4,80.6c-0.6,21.4,9.4,42.2,26.8,55.7 l272.6,211.6c12.7,9.9,27.7,14.7,42.4,14.7c19.3,0,38.1-8.3,50.6-24.3l62.1-80l16.5,32.6c10.1,19.9,3.2,43.7-15.3,53.1l-177,89.6 c-8.8,4.5-19,5-28.7,1.5c-10-3.6-18.2-10.9-23.1-20.7l-61.5-121.4c-3.4-6.7-11.5-9.3-18.1-5.9c-6.7,3.4-9.3,11.5-5.9,18.1 l61.5,121.4c8.1,16,21.6,28,38.1,33.9c7.2,2.6,14.6,3.9,21.9,3.9c9.7,0,19.3-2.3,28.1-6.7l177-89.6c31.8-16.1,44-56.2,27.1-89.4 l-22.3-44l41.2-53.1C652.3,398.5,646.1,357,616.7,334.2z"
      />
      <path
        className="st0"
        d="M246.7,216.8c5.9,4.6,14.4,3.5,18.9-2.4c4.6-5.9,3.5-14.4-2.4-18.9c-22.6-17.6-55.3-13.5-72.9,9.2 c-8.5,11-12.2,24.6-10.5,38.3c1.7,13.8,8.7,26,19.7,34.5c9.2,7.1,20.3,10.9,31.8,10.9c2.2,0,4.4-0.1,6.6-0.4 c13.8-1.7,26-8.7,34.5-19.7c4.6-5.9,3.5-14.4-2.4-18.9s-14.4-3.5-18.9,2.4c-4.1,5.3-10,8.6-16.6,9.5c-6.6,0.8-13.2-1-18.4-5 c-10.9-8.4-12.8-24.1-4.4-35C220.2,210.3,235.9,208.4,246.7,216.8z"
      />
      <path
        className="st0"
        d="M399.3,263.5c-7.4,0.9-12.6,7.7-11.7,15.1l26.4,209.6c0.9,6.8,6.7,11.8,13.4,11.8c0.6,0,1.1,0,1.7-0.1 c7.4-0.9,12.6-7.7,11.7-15.1l-26.4-209.6C413.4,267.8,406.7,262.6,399.3,263.5z"
      />
      <path
        className="st0"
        d="M364.6,343c-16.6-12.9-40.7-9.9-53.6,6.8c-6.3,8.1-9,18.1-7.7,28.2s6.4,19.2,14.5,25.4c7,5.4,15.2,8,23.4,8 c11.4,0,22.7-5.1,30.2-14.8C384.3,380,381.3,356,364.6,343C364.6,343,364.6,343,364.6,343z M350.1,380.1c-3.8,4.9-10.9,5.8-15.8,2 c-2.4-1.8-3.9-4.5-4.3-7.5c-0.4-3,0.4-5.9,2.3-8.3c2.2-2.8,5.5-4.3,8.9-4.3c2.4,0,4.8,0.8,6.9,2.4 C353,368.2,353.9,375.2,350.1,380.1z"
      />
      <path
        className="st0"
        d="M456.8,365.3c-12.9,16.6-9.9,40.7,6.8,53.6c6.8,5.3,14.9,8,23.4,8c1.6,0,3.2-0.1,4.9-0.3 c10.1-1.3,19.2-6.4,25.4-14.5c6.3-8.1,9-18.1,7.7-28.2c-1.3-10.1-6.4-19.2-14.5-25.4c-8.1-6.3-18.1-9-28.2-7.7 C472,352.1,463,357.2,456.8,365.3z M478.1,381.8c1.8-2.4,4.5-3.9,7.5-4.3c0.5-0.1,1-0.1,1.4-0.1c2.5,0,4.9,0.8,6.9,2.4 c2.4,1.8,3.9,4.5,4.3,7.5c0.4,3-0.4,5.9-2.3,8.3s-4.5,3.9-7.5,4.3c-3,0.4-5.9-0.4-8.3-2.3C475.2,393.8,474.3,386.7,478.1,381.8z"
      />
    </g>
  </svg>
);
