import { Skeleton }                       from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation }                 from 'react-i18next';
import { useHistory, useParams }          from 'react-router-dom';

import { Card }                                  from '@common/components/Card';
import { ApproveModal, DenyModal, RequestModal } from '@common/components/Modal';
import { EventPreview, EventDetails }            from '@common/modules/Event';

import { ERequestedEntityStatus } from '@common/enums';
import { IEventModel }            from '@common/models';

import { routesConfig }   from '@components/Breadcrumbs/routesConfig';
import { useEntityById }  from '@hooks/useEntityById';
import { theme as Theme } from '@styles/theme';
import { callApi }        from '@utils/apiCaller';

import { useEventShareLink } from '../hooks';

interface IHeaderButton {
  background : string;
  label      : string;
  onClick    : () => void;
}

export const EventReview = () => {
  const history = useHistory();
  const params  = useParams<{ id?: string }>();
  const { t }   = useTranslation();

  const [fetchedEvent] = useEntityById<IEventModel>('feature/event', Number(params.id));
  const [shareLink]    = useEventShareLink(fetchedEvent
    ? { ...fetchedEvent, imageUri: fetchedEvent?.rEventImage[0].image.imageUri || '' }
    : null);

  const [modalType, setModalType] = useState<ERequestedEntityStatus | null>(null);

  const sendReviewResponse = useCallback(async (emailText: string | null, status: ERequestedEntityStatus) => {
    const requestData = { emailText, status };

    try {
      await callApi(`feature/Event/status?eventId=${params.id}`, 'PATCH', requestData);

      if (status !== ERequestedEntityStatus.Approve) {
        history.push(routesConfig.events.endpoint);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, []);

  const onClickApprove = useCallback(() => {
    sendReviewResponse(null, ERequestedEntityStatus.Approve);
    setModalType(ERequestedEntityStatus.Approve);
  }, []);

  const previewHeaderButtons = useMemo<IHeaderButton[]>(() => [
    {
      background : Theme.color.red,
      label      : t('deny'),
      onClick    : () => setModalType(ERequestedEntityStatus.Deny),
    },
    {
      background : Theme.color.orange,
      label      : t('requestChanges'),
      onClick    : () => setModalType(ERequestedEntityStatus.RequestChanges),
    },
    {
      background : Theme.color.green,
      label      : t('approve'),
      onClick    : onClickApprove,
    },
  ], []);

  if (!fetchedEvent || !shareLink) {
    return <Skeleton />;
  }

  return (
    <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
      {modalType === ERequestedEntityStatus.Approve && (
        <ApproveModal
          title       = {`${t('approved')}!`}
          closeButton = {{
            label   : t('backToEvents'),
            onClick : () => history.push(routesConfig.events.endpoint),
          }}
        />
      )}
      {modalType === ERequestedEntityStatus.Deny && (
        <DenyModal
          comment  = {t('sendMessageExplainingWhy')}
          onSubmit = {(msg) => sendReviewResponse(msg, ERequestedEntityStatus.Deny)}
          title    = {t('denied')}
          button   = {{
            label   : t('backToEvent'),
            onClick : () => setModalType(null),
          }}
        />
      )}
      {modalType === ERequestedEntityStatus.RequestChanges && (
        <RequestModal
          comment  = {t('describeWhatNeedsToBeCorrected')}
          onCancel = {() => setModalType(null)}
          onSubmit = {(msg) => sendReviewResponse(msg, ERequestedEntityStatus.RequestChanges)}
          title    = {t('requestChanges')}
        />
      )}
      <EventDetails event={{ ...fetchedEvent, shareLink }} />
      <Card style={{ width: '465px' }}>
        <EventPreview
          buttons = {previewHeaderButtons}
          event   = {{
            ...fetchedEvent,
            tags             : fetchedEvent.rEventTag.map(({ tag }) => tag.name),
            imageUri         : fetchedEvent.rEventImage[0].image.imageUri,
            organizationName : fetchedEvent.businessLocation?.name || '',
            locationAddress  : fetchedEvent.location?.address1,
          }}
        />
      </Card>
    </div>
  );
};
