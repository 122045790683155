import { useEffect, useState } from 'react';
import { useHistory }          from 'react-router-dom';

import { Login as CommonLogin } from '@common/modules/Login';

import { routesConfig }                                      from '@components/Breadcrumbs/routesConfig';
import { AccountTypes, useAccountDispatch, useAccountState } from '@src/AccountContext';
import { callApi }                                           from '@utils/apiCaller';

const { CLIENT_ID }     = process.env;
const { CLIENT_SECRET } = process.env;

export const Login = () => {
  const history          = useHistory();
  const { isAuthorized } = useAccountState();
  const accountDispatch  = useAccountDispatch();

  const [tokenSaved, setTokenStatus] = useState(false);

  const onLogin = () => {
    accountDispatch({
      newStateData : { isAuthorized: true },
      type         : AccountTypes.UpdateValues,
    });
    setTokenStatus(true);
  };

  useEffect(() => {
    if (tokenSaved && isAuthorized) {
      history.replace(routesConfig.dashboard.endpoint);
    }
  }, [tokenSaved, isAuthorized]);

  return (
    <CommonLogin
      apiHandler        = {callApi}
      clientId          = {CLIENT_ID || ''}
      clientSecret      = {CLIENT_SECRET || ''}
      onLogin           = {onLogin}
      resetPasswordLink = "LicenseAccount/password"
    />
  );
};
