import { Field, Form, FormikProps } from 'formik';
import { useCallback, useState }    from 'react';
import { useTranslation }           from 'react-i18next';
import { useHistory }               from 'react-router-dom';
import styled                       from 'styled-components';

import { TOption }                       from '@common/models';
import { DatePicker, TIME_ZONE_OPTIONS } from '@common/components/DatePicker/DatePicker';

import {
  Checkbox,
  FormButtons,
  Input,
  MultipleSelect,
  PlacesAutocomplete,
  Select,
} from '@common/components';
import {
  ConfirmationRecurringModal,
  RecurrenceEditor,
} from '@common/components/RecurrenceEditor';

import { AutoSuggest }   from '@components/AutoSuggest/AutoSuggest';
import { MediaUploader } from '@components/MediaUploader/MediaUploader';
import { Wysiwyg }       from '@components/Wysiwyg/Wysiwyg';

import { useAccountState }   from '@src/AccountContext';
import { IBusinessLocation } from '@src/models';

import { TEventFormModel } from '../views/Event';

interface IEventForm extends FormikProps<TEventFormModel> {
  disabled  : boolean;
  eventTags : TOption[];
}

export const EventForm = ({
  disabled,
  eventTags,
  initialValues,
  setFieldValue,
  submitForm,
  values,
}: IEventForm) => {
  const history              = useHistory();
  const { t }                = useTranslation();
  const { currentTerritory } = useAccountState();

  const [modalVisible, setModalStatus] = useState(false);

  const mapOrganizations = useCallback((organizations: IBusinessLocation[]): TOption[] => organizations.map((item) => ({
    label  : item.name,
    source : item,
    value  : item.businessLocationId,
  })), []);

  const onClickSave = useCallback(() => {
    if (values.eventId && !!initialValues.withRegularRepeats) {
      setModalStatus(true);
    } else {
      submitForm();
    }
  }, [initialValues.withRegularRepeats, setFieldValue, submitForm, values.eventId]);

  const onConfirmRecurringModal = useCallback((withRecurring: boolean) => {
    setFieldValue('occurrenceChoice', withRecurring);
    setModalStatus(false);
    submitForm();
  }, [setFieldValue, submitForm]);

  return (
    <EventForm.Wrapper>
      {modalVisible && (
        <ConfirmationRecurringModal
          entityName = "Event"
          onCancel   = {() => setModalStatus(false)}
          onSelect   = {onConfirmRecurringModal}
        />
      )}
      <EventForm.Column>
        <Field
          component    = {Input}
          label        = {t('title')}
          name         = "title"
          placeholder  = {t('enterEventTitle')}
          wrapperStyle = {() => 'margin: 0'}
        />
        <Field
          component = {DatePicker}
          label     = {t('starts')}
          name      = "dateStart"
          timezone  = {values.timeZone}
          withTime  = {!values.allDay}
        />
        <Field
          component = {DatePicker}
          disabled  = {!values.dateStart}
          label     = {t('ends')}
          name      = "dateEnd"
          required  = {false}
          timezone  = {values.timeZone}
          withTime  = {!values.allDay}
        />
        <Field
          component = {Select}
          label     = {t('timezone')}
          name      = "timeZone"
          options   = {TIME_ZONE_OPTIONS}
        />
        <EventForm.Checkboxes>
          <Field
            component = {Checkbox}
            label     = {t('addRegularRepeats')}
            name      = "withRegularRepeats"
          />
          <Field
            component = {Checkbox}
            label     = {t('allDay')}
            name      = "allDay"
          />
        </EventForm.Checkboxes>
        {values.withRegularRepeats && (
          <Field
            component = {RecurrenceEditor}
            name      = "eventRecurrence.recurrenceRule"
          />
        )}
        <Field
          component    = {Wysiwyg}
          label        = {t('description')}
          maxLength    = {600}
          name         = "description"
          wrapperStyle = {{ height: '280px' }}
        />
      </EventForm.Column>
      <EventForm.Column rightSide>
        <div>
          <Field
            withResizer
            aspectRatio     = {null}
            component       = {MediaUploader}
            label           = {t('image')}
            mimeTypeOptions = {['image']}
            name            = "rEventImage"
            wrapperStyle    = {{ margin: '0' }}
            imageStyle      = {{
              backgroundColor : '#E6E9F4',
              height          : '210px',
            }}
          />
          <Field
            component   = {Input}
            label       = {t('linkToEvent')}
            name        = "websiteUri"
            placeholder = "http://"
            required    = {false}
          />
          <Field
            showSearch
            component   = {MultipleSelect}
            label       = {t('tags')}
            name        = "rEventTag"
            placeholder = {t('Start typing')}
            options     = {eventTags}
            required    = {false}
          />
          <Field
            component     = {PlacesAutocomplete}
            label         = {t('searchAddress')}
            name          = "location.address1"
            placeholder   = {t('findSelectAddress')}
            required      = {false}
            searchOptions = {{ types: ['address'] }}
          />
          <Field
            component      = {AutoSuggest}
            endpoint       = {`feature/businessLocation/search?territoryId=${currentTerritory?.territoryId}&name`}
            label          = {t('assignOrganization')}
            name           = "businessLocation"
            placeholder    = {t('findSelectOrganization')}
            required       = {false}
            responseMapper = {mapOrganizations}
          />
        </div>
        <FormButtons
          disabled      = {{ main: disabled }}
          onClickCancel = {() => history.goBack()}
          onSubmit      = {onClickSave}
        />
      </EventForm.Column>
    </EventForm.Wrapper>
  );
};

EventForm.Checkboxes = styled.div`
  display         : flex;
  justify-content : space-between;

  > div { margin: 28px 0 9px; }
`;

EventForm.Column = styled.div<{ rightSide?: boolean }>`
  width : 402.5px;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
  `}
`;

EventForm.Wrapper = styled(Form)`
  display    : flex;
  flex-wrap  : wrap;
  gap        : 20px 80px;
  margin-top : 40px;

  @media (max-width: 1284px) { width: 402.5px; }

  @media (min-width: 1284px) { width: 100%; }
`;
