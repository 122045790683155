import { format }                                    from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation }                            from 'react-i18next';
import { useHistory }                                from 'react-router-dom';

import { BaseInput, DatePicker }     from '@common/components';
import { ECardStatuses, ESortOrder } from '@common/enums';
import { IEventModel }               from '@common/models';

import { IClickableItem }    from '@common/components/Table/TableItems';
import { Table, TableProps } from '@common/components/Table/Table';
import { Card, CardHeader }  from '@common/components/Card';
import {
  FiltrationSorting,
  IFilterOption,
  FiltrationSortingInfo,
  setFitFilterField,
} from '@common/components/FiltrationSorting';

import { useFetchedEntity }   from '@hooks/useFetchedEntity';
import { useUnapprovalItems } from '@hooks/useUnapprovalItems';
import { useFetchQueryRules } from '@hooks/useFetchQueryRules';

import { routesConfig }       from '@components/Breadcrumbs/routesConfig';
import { DeleteConfirmation } from '@components/DeleteConfirmation/DeleteConfirmation';

import { callApi } from '@utils/apiCaller';

const sortOptions = [
  { label: 'Ends', value: 'DateEnd' },
  { label: 'Organization', value: 'BusinessBranchName' },
  { label: 'Starts', value: 'DateStart' },
  { label: 'Status', value: 'Status' },
  { label: 'Title', value: 'Title' },
];

const filterOptions: IFilterOption[] = [{
  component          : (props) => setFitFilterField(BaseInput, props),
  initialFilterValue : '',
  label              : 'Title',
  value              : 'Name',
}, {
  component          : (props) => setFitFilterField(DatePicker, { ...props, withTime: false }),
  initialFilterValue : null,
  label              : 'Starts',
  value              : 'DateFrom',
}, {
  initialFilterValue : '',
  label              : 'Tags',
  multiple           : true,
  value              : 'Tags',
  component          : (props) => setFitFilterField(BaseInput, props),
}];

export const EventsList = () => {
  const history    = useHistory();
  const { t }      = useTranslation();
  const [counters] = useUnapprovalItems();

  const [currentPage, setPage]              = useState<number>(1);
  const [popoverVisible, setPopoverStatus]  = useState(false);
  const [selectedEvents, setSelectedEvents] = useState<TableProps['content']>([]);
  const [status, setStatus]                 = useState<ECardStatuses>(ECardStatuses.None);

  const { rules, handlers } = useFetchQueryRules(
    sortOptions,
    'events-list',
    { filtration: {}, sorting: { ...sortOptions[2], order: ESortOrder.Desc } },
  );
  const [fetchedEvent, isFetching, refetchEvents] = useFetchedEntity<IEventModel>(`feature/event?page=${currentPage - 1}&itemsPerPage=10`, rules);

  const onClickEvent = useCallback((event: IEventModel) => {
    if (event.creator === 'license') {
      history.push(routesConfig.eventEdit.endpoint.replace(':id', event.eventId.toString()));
    } else {
      history.push(routesConfig.eventReview.endpoint.replace(':id', event.eventId.toString()));
    }
  }, []);

  const onClickCloneEvent = useCallback((eventId: number | string) => {
    history.push(routesConfig.eventClone.endpoint.replace(':id', eventId.toString()));
  }, []);

  const header = useMemo(() => (
    <CardHeader
      buttons        = {[{ action: () => history.push(routesConfig.eventAdd.endpoint), label: t('createEvent') }]}
      entityCounter  = {counters.events.length}
      title          = {t('reviewAndApprove')}
      popoverOptions = {{
        onVisibleChange : setPopoverStatus,
        visible         : popoverVisible,
        content         : <FiltrationSorting
          filterOptions = {filterOptions}
          sortOptions   = {sortOptions}
          {...handlers}
        />,
      }}
      additionalContent = {(
        <FiltrationSortingInfo
          deleteFiltrationRule = {handlers.deleteFiltrationRule}
          rules                = {rules}
        />
      )}
    />
  ), [counters, popoverVisible, rules, handlers]);

  const selectedEventTitlesWithIds = useMemo(() => (
    selectedEvents.map((item) => `${(item.title as IClickableItem).title}__${item.id}`)
  ), [selectedEvents]);

  const tableButtons = useMemo<TableProps['buttons']>(() => (
    !fetchedEvent ? [] : [{
      label   : t('cloneEvent'),
      onClick : (eventId) => onClickCloneEvent(eventId as number),
      tag     : 'clone',
    }]
  ), [fetchedEvent]);

  const tableContent = useMemo<TableProps['content']>(() => (fetchedEvent?.items || []).map((item) => ({
    title : {
      onClick : () => onClickEvent(item),
      tag     : 'link',
      title   : item.title,
    },
    status : {
      status : item.status,
      tag    : 'status',
    },
    starts       : format(new Date(item.dateStart), 'EEEE, MMM d'),
    ends         : item.dateEnd && format(new Date(item.dateEnd), 'EEEE, MMM d'),
    organization : item.businessLocation?.name || null,
    id           : item.eventId,
  })), [fetchedEvent, onClickEvent]);

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'title', label: t('title') },
    { id: 'status', label: t('status') },
    { id: 'starts', label: t('starts') },
    { id: 'ends', label: t('ends') },
    { id: 'organization', label: t('organization') },
  ], []);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    columns : [
      { flex: 3 },
      { flex: 1.2 },
      { flex: 1.7, minWidth: 85 },
      { flex: 1.7, minWidth: 85 },
      { flex: 1.7, minWidth: 85 },
    ],
    pagination : {
      setPage,
      current : currentPage,
      total   : fetchedEvent?.totalCount || 0,
    },
  }), [fetchedEvent, currentPage]);

  const deleteEvents = useCallback(async () => {
    setStatus(ECardStatuses.Pending);

    const eventsIds = selectedEvents.map((item) => item.id);

    setSelectedEvents([]);

    try {
      for (let i = 0; i < eventsIds.length;) {
        // eslint-disable-next-line no-await-in-loop
        await callApi(`feature/event/${eventsIds[i]}`, 'DELETE');
        i += 1;
      }

      setStatus(ECardStatuses.Success);
      refetchEvents();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setStatus(ECardStatuses.Failure);
    }
  }, [currentPage, selectedEvents]);

  useEffect(() => {
    setStatus(isFetching ? ECardStatuses.Pending : ECardStatuses.None);
  }, [isFetching]);

  useEffect(() => {
    setPopoverStatus(false);
  }, [rules]);

  return (
    <>
      <DeleteConfirmation
        itemsWithIds = {selectedEventTitlesWithIds}
        onCancel     = {() => setSelectedEvents([])}
        onConfirm    = {deleteEvents}
      />
      <Card
        header = {header}
        status = {status}
        style  = {{ width: '948px' }}
      >
        <Table
          buttons  = {tableButtons}
          content  = {tableContent}
          headers  = {tableHeaders}
          onDelete = {(_, items) => setSelectedEvents(items)}
          settings = {tableSettings}
        />
      </Card>
    </>
  );
};
