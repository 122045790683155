import { useCallback } from 'react';
import { useHistory }  from 'react-router-dom';

import { Breadcrumbs as CommonBreadcrumbs } from '@common/components/Breadcrumbs/Breadcrumbs';

import { routesConfig } from './routesConfig';

export const Breadcrumbs = () => {
  const history = useHistory();

  const onClick = useCallback((route: string) => {
    history.push(route);
  }, []);

  return (
    <CommonBreadcrumbs
      containerStyle = {{ margin: '0 0 20px' }}
      currentPath    = {history.location.pathname}
      onClick        = {onClick}
      routes         = {routesConfig}
    />
  );
};
