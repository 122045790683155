import { format }         from 'date-fns';
import { useMemo }        from 'react';
import { useTranslation } from 'react-i18next';

import { StatusItem }                   from '../../components/Table/TableItems';
import { CardDetails, IDetailsElement } from '../../components/Card/CardDetails';

import { ERequestedEntityStatus } from '../../enums';
import { theme as Theme }         from '../../styles/theme';

interface IEventDetails {
  event: {
    contactEmail?     : string;
    dateStart         : string;
    organizationName? : string;
    contactName?      : string;
    shareLink         : string;
    status            : ERequestedEntityStatus;
  }
}

export const EventDetails = ({ event }: IEventDetails) => {
  const { t } = useTranslation();

  const details = useMemo<IDetailsElement[]>(() => [
    { label: t('organization'), value: event.organizationName || null },
    { label: t('startDate'), value: format(new Date(event.dateStart), 'EEEE, MMM d') },
    { label: t('status'), value: <StatusItem status={event.status} /> },
    { label: t('contactName'), value: event.contactName || null },
    { color: Theme.color.blue, label: t('contactEmail'), tags: ['copy'], value: event.contactEmail || null },
    { color: Theme.color.blue, label: t('shareLink'), tags: ['copy'], value: event.shareLink },
  ], [event]);

  return (
    <CardDetails
      details = {details}
      title   = {t('eventDetails')}
    />
  );
};
