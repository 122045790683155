import { Fragment } from 'react';
import styled       from 'styled-components';

import { Empty }              from '@common/components/Empty';
import { IEssentialTipModel } from '@src/models';

interface IEssentialTipsListProps {
  tips : IEssentialTipModel[];
}

export const EssentialTipsList = ({ tips }: IEssentialTipsListProps) => (
  <EssentialTipsList.Wrapper>
    {tips.length === 0 && (
      <Empty />
    )}
    {tips.map((tip, index) => (
      <Fragment key={tip.id}>
        <p>{tip.tip}</p>
        {index !== tips.length - 1 && (
          <EssentialTipsList.Separator />
        )}
      </Fragment>
    ))}
  </EssentialTipsList.Wrapper>
);

EssentialTipsList.Separator = styled.div`
  background-color : #e6e9f4;
  height           : 1px;
  width            : 100%;
`;

EssentialTipsList.Wrapper = styled.div`
  display        : flex;
  flex-direction : column;
  gap            : 16px;

  p {
    color       : #131523;
    font-size   : 12px;
    line-height : 18.5px;
  }
`;
