import { ISvgIconOptions } from './index';

export default ({ color = '#fe9e26', height, width }: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 32 32" fill={color}>
    <g>
      <path d="M29.68,25.6l-11-21.92a3,3,0,0,0-5.44,0L2.32,25.6A3,3,0,0,0,5,30H27a3,3,0,0,0,2.72-4.4Zm-1.84,1.91A1,1,0,0,1,27,28H5a1,1,0,0,1-.88-.49,1,1,0,0,1,0-1l11-21.93h0a1,1,0,0,1,1.86,0l11,21.93A1,1,0,0,1,27.84,27.51Z" />
      <rect height="9" width="2" x="15" y="11" />
      <circle cx="16" cy="24" r="2" />
    </g>
  </svg>
);
