import { Field, Form } from 'formik';
import { TFunction }   from 'react-i18next';

import { FormButtons } from '@common/components/Button';
import { Input }       from '@common/components/Input';

import { MediaUploader } from '@components/MediaUploader/MediaUploader';

interface IPublicBioForm {
  disabled : boolean;
  t        : TFunction<'translation'>;
}

export const PublicBioForm = ({ disabled, t }: IPublicBioForm) => (
  <Form>
    <Field
      component   = {Input}
      label       = {t('displayName')}
      name        = "displayName"
      placeholder = {t('enterYourPublicName')}
    />
    <Field
      autoSize    = {{ maxRows: 6, minRows: 2 }}
      component   = {Input}
      label       = {t('bio')}
      name        = "bio"
      type        = "textarea"
      placeholder = {t('fewWordsAboutYou')}
    />
    <Field
      withResizer
      component       = {MediaUploader}
      label           = {t('photo')}
      mimeTypeOptions = {['image']}
      name            = "photoImage"
      imageStyle      = {{
        backgroundColor : '#E6E9F4',
        height          : '210px',
      }}
    />
    <Field
      component = {Input}
      label     = {t('socialMedia')}
      required  = {false}
      name      = "facebookUri"
      prefix    = "Facebook.com/"
    />
    <Field
      component    = {Input}
      name         = "instagramUri"
      prefix       = "Instagram.com/"
      wrapperStyle = {() => 'margin: 15px 0 0'}
    />
    <Field
      component    = {Input}
      name         = "twitterUri"
      prefix       = "Twitter.com/"
      wrapperStyle = {() => 'margin: 15px 0 0'}
    />
    <Field
      component   = {Input}
      required    = {false}
      label       = {t('contactEmail')}
      name        = "contactEmail"
      type        = "email"
      placeholder = {t('enterYourPublicEmail')}
    />
    <FormButtons disabled={{ main: disabled }} />
  </Form>
);
