import 'antd/dist/antd.css';

import { Suspense }      from 'react';
// eslint-disable-next-line
import { hot }           from 'react-hot-loader/root';
import { Helmet }        from 'react-helmet';
import { ThemeProvider } from 'styled-components';

import { NotificationRoot } from '@common/components/Notification';

import { theme }            from './styles/theme';
import { BaseStyles }       from './styles/baseStyles';
import { Routes }           from './routes';

const AppContainer = () => (
  <Suspense fallback="loading">
    <ThemeProvider theme={theme}>
      <>
        <Helmet titleTemplate="Newsie License" defaultTitle="Newsie License" />
        <BaseStyles />
        <NotificationRoot />
        <Routes />
      </>
    </ThemeProvider>
  </Suspense>
);

const App = hot(AppContainer);

export { App };
