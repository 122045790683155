import { useState, useCallback, useEffect } from 'react';

import { callApi } from '@utils/apiCaller';

interface IFeaturedStoryForMobile {
  created  : string;
  images   : { imageUri: string; }[]
  storyId  : number;
  title    : string;
}

export const useStoriesByBusinessLocationId = ({ businessLocationId }: { businessLocationId: number }) => {
  const [stories, setStories] = useState<IFeaturedStoryForMobile[]>([]);

  const fetchStories = useCallback(async () => {
    try {
      const { data } = await callApi<IFeaturedStoryForMobile[]>(
        `feature/Story/mobile/BusinessLocation/${businessLocationId}`,
        'get',
        { itemsCount: 6 },
      );

      setStories(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [businessLocationId]);

  useEffect(() => {
    if (businessLocationId) {
      fetchStories();
    }
  }, [businessLocationId]);

  return { stories };
};
