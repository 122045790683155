import { Tooltip }        from 'antd';
import { useMemo }        from 'react';
import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

interface IEventDeleteButtonProps {
  onClickDelete   : (withRecurrence: boolean) => void;
  recurrenceEvent : boolean;
}

export const EventDeleteButton = ({
  onClickDelete,
  recurrenceEvent,
}: IEventDeleteButtonProps) => {
  const { t } = useTranslation();

  const title = useMemo(() => (
    <div>
      <EventDeleteButton.Option onClick={() => onClickDelete(true)}>
        {t('deleteAllRecurringEvents')}
      </EventDeleteButton.Option>
      <EventDeleteButton.Option onClick={() => onClickDelete(false)}>
        {t('deleteOnlyThisEvent')}
      </EventDeleteButton.Option>
    </div>
  ), [onClickDelete]);

  return (
    <Tooltip
      color   = "#fff"
      title   = {title}
      trigger = "click"
      visible = {recurrenceEvent ? undefined : false}
    >
      <EventDeleteButton.DeleteButton
        onClick = {recurrenceEvent ? undefined : (() => onClickDelete(false))}
      >
        <p>{t('deleteEvent')}</p>
      </EventDeleteButton.DeleteButton>
    </Tooltip>
  );
};

const commonStyleRules = `
  cursor      : pointer;
  font-family : Poppins;
  font-size   : 12px;
  line-height : 16px;
`;

EventDeleteButton.DeleteButton = styled.div`
  ${commonStyleRules}

  color        : ${({ theme }) => theme.color.red};
  margin-right : 6px;
`;

EventDeleteButton.Option = styled.div`
  ${commonStyleRules}

  color      : ${({ theme }) => theme.color.black};
  padding    : 1.5px 7px;
  transition : all 0.2s;

  &:hover { color: ${({ theme }) => theme.color.red}; }
`;
