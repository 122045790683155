import ReactDOM from 'react-dom';

import { App } from './App';

// styles for RecurrenceEditorComponent from @syncfusion/ej2-react-schedule
import '../public/app.css';
import './utils/i18n';

const renderRoot = (app: JSX.Element): void => {
  ReactDOM.render(app, document.getElementById('root'));
};

renderRoot(<App />);
