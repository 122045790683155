import { EssentialTipsView } from '@modules/Tips';

import { DashboardWrapper } from './components/DashboardWrapper';
import { Carousel }         from './views/Carousel';
import { FeaturedStories }  from './views/FeaturedStories';
import { LastUpdates }      from './views/LastUpdates';
// import { Statistics }       from './views/Statistics';

export const Dashboard = () => (
  <DashboardWrapper>
    <div>
      {/* Task 3835: Hide analytics */}
      {/* <Statistics /> */}
      <Carousel />
      <FeaturedStories />
      <EssentialTipsView />
    </div>
    <LastUpdates />
  </DashboardWrapper>
);
