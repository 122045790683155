import { Skeleton }              from 'antd';
import { useCallback, useState } from 'react';
import { useTranslation }        from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { Card }                                  from '@common/components/Card';
import { ApproveModal, DenyModal, RequestModal } from '@common/components/Modal';

import { ERequestedEntityStatus } from '@common/enums';

import { routesConfig } from '@components/Breadcrumbs/routesConfig';

import { useEntityById }                               from '@hooks/useEntityById';
import { IBusinessLocation, IOrganizationDetailsById } from '@src/models';
import { callApi }                                     from '@utils/apiCaller';

import { OrganizationDetails }       from '../components/OrganizationDetails';
import { OrganizationPreview }       from '../components/OrganizationPreview';
import { OrganizationReviewWrapper } from '../components/OrganizationReviewWrapper';

export const OrganizationReview = () => {
  const history = useHistory();
  const params  = useParams<{ id?: string }>();
  const { t }   = useTranslation();

  const [fetchedOrganization] = useEntityById<IBusinessLocation>('feature/BusinessLocation/byid', Number(params.id));
  const [organizationDetails] = useEntityById<IOrganizationDetailsById>('feature/BusinessLocation/license/organization-details-byid', Number(params.id));

  const [modalType, setModalType] = useState<ERequestedEntityStatus | null>(null);

  const sendReviewResponse = useCallback(async (emailText: string | null, status: ERequestedEntityStatus) => {
    const requestData = { emailText, status };

    try {
      await callApi(`feature/BusinessLocation/${params.id}/status`, 'PATCH', requestData);

      if (status !== ERequestedEntityStatus.Approve) {
        history.push(routesConfig.organizations.endpoint);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, []);

  const onClickApprove = useCallback(() => {
    sendReviewResponse(null, ERequestedEntityStatus.Approve);
    setModalType(ERequestedEntityStatus.Approve);
  }, []);

  if (!fetchedOrganization || !organizationDetails) {
    return <Skeleton active />;
  }

  return (
    <OrganizationReviewWrapper>
      {modalType === ERequestedEntityStatus.Approve && (
        <ApproveModal
          title       = {`${t('approved')}!`}
          closeButton = {{
            label   : t('backToOrganizations'),
            onClick : () => history.push(routesConfig.organizations.endpoint),
          }}
        />
      )}
      {modalType === ERequestedEntityStatus.Deny && (
        <DenyModal
          comment  = {t('sendMessageExplainingWhy')}
          onSubmit = {(msg) => sendReviewResponse(msg, ERequestedEntityStatus.Deny)}
          title    = {t('denied')}
          button   = {{
            label   : t('backToOrganizations'),
            onClick : () => setModalType(null),
          }}
        />
      )}
      {modalType === ERequestedEntityStatus.RequestChanges && (
        <RequestModal
          comment  = {t('describeWhatNeedsToBeCorrected')}
          onCancel = {() => setModalType(null)}
          onSubmit = {(msg) => sendReviewResponse(msg, ERequestedEntityStatus.RequestChanges)}
          title    = {t('requestChanges')}
        />
      )}
      <OrganizationDetails organization={organizationDetails} />
      <Card style={{ width: '465px' }}>
        <OrganizationPreview
          organization   = {fetchedOrganization}
          onClickApprove = {onClickApprove}
          onClickDeny    = {() => setModalType(ERequestedEntityStatus.Deny)}
          onClickRequest = {() => setModalType(ERequestedEntityStatus.RequestChanges)}
        />
      </Card>
    </OrganizationReviewWrapper>
  );
};
