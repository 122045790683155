import { useCallback } from 'react';
import { useHistory }  from 'react-router-dom';

import { PasswordRecovery as CommonPasswordRecovery } from '@common/modules/Login';

import { callApi }  from '@utils/apiCaller';
import { useQuery } from '@utils/queryParser';

export const PasswordRecovery = () => {
  const history = useHistory();
  const query   = useQuery();

  const email = query.get('email');
  const token = query.get('token');

  const onResetPassword = useCallback(() => {
    history.push('/login');
  }, []);

  return (
    <CommonPasswordRecovery
      apiHandler        = {callApi}
      email             = {email || ''}
      onResetPassword   = {onResetPassword}
      resetPasswordLink = "LicenseAccount/password"
      token             = {token || ''}
      validateTokenLink = "LicenseAccount/password/token"
    />
  );
};
