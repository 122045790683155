import { Popover }        from 'antd';
import { format }         from 'date-fns';
import { useCallback }    from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory }     from 'react-router-dom';
import styled             from 'styled-components';

import { ClickableItem }     from '@common/components/Table/TableItems';
import { Button }            from '@common/components/Button';
import { setPopupContainer } from '@common/utils';

import { routesConfig } from '@components/Breadcrumbs/routesConfig';
import { IStoryModel }  from '@src/models';

interface IPopoverContentProps {
  onClick    : () => void;
  storyImage : string;
}

const { PROJECT_URL } = process.env;

const PopoverContent = ({ onClick, storyImage }: IPopoverContentProps) => {
  const { t } = useTranslation();

  return (
    <PopoverContent.Wrapper>
      <Button
        bordered       = {false}
        label          = {t('preview')}
        onClick        = {onClick}
        type           = "button"
        componentStyle = {{
          color      : '#fff',
          background : 'transparent',
        }}
      />
      <PopoverContent.Image imageUrl={storyImage} />
    </PopoverContent.Wrapper>
  );
};

PopoverContent.Wrapper = styled.div`
  align-items     : center;
  border-radius   : 4px;
  display         : flex;
  justify-content : center;
  height          : 97px;
  position        : relative;
  width           : 131px;

  > button {
    filter     : blur(1px);
    transition : all 0.2s;
    z-index    : 1;
  }

  &:hover {
    > div { filter: blur(3px); }

    > button { filter: blur(0); }
  }
`;

PopoverContent.Image = styled.div<{ imageUrl: string }>`
  background    : center / cover no-repeat ${({ imageUrl }) => `url(${imageUrl})`};
  border-radius : 4px;
  filter        : blur(0);
  height        : 100%;
  left          : 0;
  position      : absolute;
  top           : 0;
  transition    : all 0.2s;
  width         : 100%;
`;

export const StoriesListItem = ({ story }: { story: IStoryModel }) => {
  const history = useHistory();

  const onClick = useCallback(() => {
    history.push(routesConfig.storyEdit.endpoint.replace(':id', story.storyId.toString()));
  }, [story]);

  const onClickPreview = useCallback(() => {
    window.open(`${PROJECT_URL}/shared/story/${story.storyId}`, '_blank');
  }, [story.storyId]);

  return (
    <StoriesListItem.Wrapper>
      <Popover
        getPopupContainer = {setPopupContainer}
        content           = {(
          <PopoverContent
            onClick    = {onClickPreview}
            storyImage = {story.rStoryImage[0].image.imageUri}
          />
        )}
      >
        <StoriesListItem.TitleWrapper>
          <ClickableItem
            onClick = {onClick}
            title   = {story.title}
          />
        </StoriesListItem.TitleWrapper>
      </Popover>
      <StoriesListItem.Meta>
        <p>{format(new Date(story.created), 'MMMM dd, yyyy')}</p>
        {story.businessName && (
          <>
            <StoriesListItem.MetaSeparator />
            <StoriesListItem.BusinessName>
              {story.businessName}
            </StoriesListItem.BusinessName>
          </>
        )}
      </StoriesListItem.Meta>
    </StoriesListItem.Wrapper>
  );
};

StoriesListItem.BusinessName = styled.p`
  font-weight: 600;
`;

StoriesListItem.Meta = styled.div`
  align-items : center;
  display     : flex;

  p {
    font-size   : 12px;
    line-height : 17px;
  }
`;

StoriesListItem.MetaSeparator = styled.div`
  background-color : ${({ theme }) => theme.color.lightGray};
  height           : 15px;
  margin           : 0 5px;
  width            : 1px;
`;

StoriesListItem.TitleWrapper = styled.div`
  display   : inline-block;
  max-width : 100%;

  > p { width: fit-content; }
`;

StoriesListItem.Wrapper = styled.div`
  max-width: 100%;

  p {
    max-width     : 100%;
    overflow      : hidden;
    text-overflow : ellipsis;
    white-space   : nowrap;
  }

  .ant-popover-inner-content {
    padding: 15px;
  }
`;
