import { FlexItemsColumn } from '@components/Menu/FlexItemsColumn';

import { BillingInfo }  from './views/BillingInfo';
import { InvoicesList } from './views/InvoicesList';
// import { Payouts }      from './views/Payouts';

export const Billing = () => (
  <>
    <FlexItemsColumn>
      <InvoicesList />
      {/* <Payouts /> */}
    </FlexItemsColumn>
    <BillingInfo />
  </>
);
