import { ISvgIconOptions } from '../../../../models';

export default ({
  color,
  height = '20px',
  width  = '20px',
}: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 50 50">
    <rect fill="none" height="50" width="50" />
    <polygon fill={color} points="25,3.553 30.695,18.321 46.5,19.173   34.214,29.152 38.287,44.447 25,35.848 11.712,44.447 15.786,29.152 3.5,19.173 19.305,18.321 " stroke="#f0552a" strokeMiterlimit="10" strokeWidth="2" />
  </svg>
);
