import { memo }           from 'react';
import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { TMobileOrganizationPreviewProps } from '../OrganizationPreview';

type SubcategoriesProps = 'categories';

type TPreviewCategoriesProps = Pick<TMobileOrganizationPreviewProps['organization'], SubcategoriesProps>;

const PreviewCategories = ({ categories }: TPreviewCategoriesProps) => {
  const { t } = useTranslation();

  const textColor = categories.length === 0
    ? '#969696'
    : '#454545';

  const stringifyCategories = () => {
    if (categories.length === 0) {
      return Array.from({ length: 3 }, () => t('category').toLowerCase()).join(', ');
    }

    return categories
      .map((item) => item.categoryName)
      .join(', ');
  };

  return (
    <PreviewCategories.Wrapper color={textColor}>
      <p>{stringifyCategories()}</p>
    </PreviewCategories.Wrapper>
  );
};

export default memo(PreviewCategories);

PreviewCategories.Wrapper = styled.div<{ color: string }>`
  margin-bottom: 15px;

  p {
    color       : ${({ color }) => color};
    font-size   : 9px;
    line-height : 11px;
  }
`;
