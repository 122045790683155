import { Formik, FormikHelpers } from 'formik';
import { useCallback, useState } from 'react';
import { useTranslation }        from 'react-i18next';
import * as Yup                  from 'yup';

import { ResetPasswordForm } from './components/ResetPasswordForm';
import { Card }              from '../../components';
import { ECardStatuses }     from '../../enums';
import { TFunction }         from '../../models';
import {
  showNotification,
  validatePassword,
  validatePasswordCopy,
} from '../../utils';

interface IResetPasswordForm {
  oldPassword  : string;
  password     : string;
  passwordCopy : string;
}

interface ResetPasswordProps {
  onSubmit: (oldPassword: string, newPassword: string) => void | Promise<void>;
}

const INITIAL_VALUES: IResetPasswordForm = {
  oldPassword  : '',
  password     : '',
  passwordCopy : '',
};

const useValidationSchema = (t: TFunction) => Yup.object().shape({
  oldPassword  : validatePassword(t),
  password     : validatePassword(t),
  passwordCopy : validatePasswordCopy(t),
});

export const ResetPassword = ({ onSubmit }: ResetPasswordProps) => {
  const { t }            = useTranslation();
  const validationSchema = useValidationSchema(t);

  const [status, setStatus] = useState<ECardStatuses>(ECardStatuses.None);

  const onSubmitInner = useCallback(async (
    values        : IResetPasswordForm,
    formikHelpers : FormikHelpers<IResetPasswordForm>,
  ) => {
    const { oldPassword, password } = values;

    setStatus(ECardStatuses.Pending);
    try {
      await onSubmit(oldPassword, password);
      formikHelpers.resetForm();
      setStatus(ECardStatuses.Success);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setStatus(ECardStatuses.Failure);
      showNotification({
        description : t('somethingWentWrongTryAgain'),
        message     : t('requestFailed'),
        type        : 'error',
      });
    }
  }, [onSubmit]);

  return (
    <Card
      header = {t('resetPassword')}
      status = {status}
    >
      <Formik
        initialValues    = {INITIAL_VALUES}
        onSubmit         = {onSubmitInner}
        validationSchema = {validationSchema}
      >
        <ResetPasswordForm disabled={status === ECardStatuses.Pending} />
      </Formik>
    </Card>
  );
};
