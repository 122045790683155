import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation }                            from 'react-i18next';
import styled                                        from 'styled-components';

import { BaseInput, Button, Modal } from '@common/components';

interface IDeleteConfirmationProps {
  itemsWithIds : string[];
  onCancel     : () => void;
  onConfirm    : () => void;
}

export const DeleteConfirmation = ({
  itemsWithIds,
  onCancel,
  onConfirm,
}: IDeleteConfirmationProps) => {
  const { t } = useTranslation();

  const [errorVisibility, setError] = useState(false);
  const [inputValue, setValue]      = useState('');

  const prettyItems      = useMemo(() => (itemsWithIds.map((item) => item.split('__')[0])), [itemsWithIds]);
  const validationString = useMemo(() => prettyItems.slice(0, 3).join(', '), [prettyItems]);

  const message = useMemo(() => (
    `${t('pleaseEnter__String__ForConfirmation').replace('__string__', validationString)}`
  ), [validationString]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();

    if (validationString === inputValue) {
      onConfirm();
    } else {
      setError(true);
    }
  }, [inputValue, onConfirm, validationString]);

  useEffect(() => {
    setValue('');
  }, [itemsWithIds]);

  useEffect(() => {
    if (errorVisibility) {
      setError(false);
    }
  }, [inputValue]);

  return (
    <Modal
      onCancel = {onCancel}
      title    = {t('confirmYourAction')}
      visible  = {!!itemsWithIds.length}
    >
      <DeleteConfirmation.Wrapper>
        <p>{t('youWantToDeleteNextItems')}:</p>
        <DeleteConfirmation.List>
          {itemsWithIds.map((item, index) => <li key={item}>{prettyItems[index]}</li>)}
        </DeleteConfirmation.List>
        <p>{message}:</p>
        <DeleteConfirmation.Form onSubmit={onSubmit}>
          <BaseInput
            bordered
            onChange = {setValue}
            value    = {inputValue}
          />
          <DeleteConfirmation.Error visible={errorVisibility}>
            {t('wrongConfirmationText')}
          </DeleteConfirmation.Error>
          <Button
            label    = {t('submit')}
            disabled = {!inputValue}
          />
        </DeleteConfirmation.Form>
      </DeleteConfirmation.Wrapper>
    </Modal>
  );
};

DeleteConfirmation.Error = styled.p<{ visible: boolean }>`
  bottom      : -19px;
  color       : ${({ theme }) => theme.color.red};
  font-size   : 12px;
  left        : 12px;
  line-height : 14px;
  opacity     : ${({ visible }) => Number(visible)};
  position    : absolute;
  transition  : all 0.3s;
`;

DeleteConfirmation.Form = styled.form`
  margin-top : 10px;
  position   : relative;

  > button {
    float      : right;
    margin-top : 7px;
  }
`;

DeleteConfirmation.List = styled.ul`
  list-style          : circle;
  list-style-position : inside;
  margin              : 5px 0 5px 10px;
  max-height          : 230px;
  overflow-y          : auto;

  li {
    overflow      : hidden;
    text-overflow : ellipsis;
    white-space   : nowrap;
  }
`;

DeleteConfirmation.Wrapper = styled.div`
  color: ${({ theme }) => theme.color.black};
`;
