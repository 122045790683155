import { Field, Form }    from 'formik';
import { useTranslation } from 'react-i18next';

import { Input }       from '../../../components';
import { FormButtons } from '../../../components/Button/FormButtons';

interface IResetPasswordForm {
  disabled: boolean;
}

export const ResetPasswordForm = ({ disabled }: IResetPasswordForm) => {
  const { t } = useTranslation();

  return (
    <Form>
      <Field
        component   = {Input}
        label       = {t('currentPassword')}
        name        = "oldPassword"
        placeholder = "******"
        type        = "password"
      />
      <Field
        component   = {Input}
        label       = {t('newPassword')}
        name        = "password"
        placeholder = "******"
        type        = "password"
      />
      <Field
        component   = {Input}
        label       = {t('newPasswordAgain')}
        name        = "passwordCopy"
        placeholder = "******"
        type        = "password"
      />
      <FormButtons disabled={{ main: disabled }} />
    </Form>
  );
};
