import { format }                                    from 'date-fns';
import { Formik, FormikProps }                       from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TFunction, useTranslation }                 from 'react-i18next';
import { useHistory, useParams }                     from 'react-router-dom';
import * as Yup                                      from 'yup';

import { parseStringToDate } from '@common/components/DatePicker/DatePicker';
import { Card }              from '@common/components/Card';
import { TOption }           from '@common/models';
import { ECardStatuses }     from '@common/enums';
import {
  validateTheCorrectTimeDirection,
  validateTheTimeDifference,
} from '@common/utils';

import { callApi }              from '@utils/apiCaller';
import { extractEntityChannel } from '@utils/extractEntityChannel';

import { routesConfig }    from '@components/Breadcrumbs/routesConfig';
import { useEntityById }   from '@hooks/useEntityById';
import { useAccountState } from '@src/AccountContext';

import {
  IBusinessLocation,
  IImage,
  IStoryModel,
} from '@models/index';

import { StoryForm } from '../components/StoryForm';

type TOmitStoryValues = 'businessId'
| 'businessLocation'
| 'businessLocationId'
| 'businessName'
| 'created'
| 'rStoryChannel'
| 'rStoryImage'
| 'rStoryTerritory'
| 'shortDescription'
| 'startDateUtc'
| 'storyId'
| 'tags';

export type TStoryFormModel = Omit<IStoryModel, TOmitStoryValues> & {
  businessId       : number | null;
  businessLocation : TOption | null;
  imageId?         : number;
  rStoryChannel    : TOption[];
  rStoryImage      : Pick<IImage, 'imageUri' | 'mimeType'> | null;
  rStoryTerritory  : { territoryId: number }[];
  startDateUtc     : string | null;
  storyId?         : number;
  tags             : string;
};

type TStoryPostModel = Omit<TStoryFormModel, 'businessLocation' | 'rStoryChannel' | 'rStoryImage' | 'tags'> & {
  businessId         : number | null,
  businessLocationId : number | null,
  shortDescription   : string;
  tags               : string[];
  rStoryChannel      : { channelId: number }[];
  rStoryImage        : [{
    imageId? : number,
    storyId? : number,
    image    : {
      imageUri : string;
      imageId? : number;
      mimeType : 'image';
    }
  }]
}

const useValidationSchema = (t: TFunction<'translation'>) => Yup.object().shape({
  author : Yup.string()
    .required(t('requiredField')),
  description : Yup.string()
    .required(t('requiredField')),
  endDateUtc : Yup.string()
    .test(...validateTheCorrectTimeDirection(t, 'startDateUtc'))
    .test(...validateTheTimeDifference(t, 'startDateUtc'))
    .nullable(),
  rStoryImage : Yup.object()
    .shape({ imageUri: Yup.string().required() })
    .typeError(t('uploadImageVideo'))
    .required(),
  rStoryChannel : Yup.array()
    .min(1, t('requiredField'))
    .of(Yup.object()
      .shape({ value: Yup.number().required() })),
  startDateUtc : Yup.string()
    .required(t('requiredField'))
    .typeError(t('requiredField')),
  tags : Yup.array()
    .ensure(),
  title : Yup.string()
    .max(300, `${t('maxCharactersLength')} 300`)
    .required(t('requiredField')),
});

export const Story = () => {
  const history          = useHistory();
  const params           = useParams<{ id?: string }>();
  const { t }            = useTranslation();
  const validationSchema = useValidationSchema(t);

  const [status, setStatus] = useState<ECardStatuses>(ECardStatuses.None);

  const { currentTerritory }             = useAccountState();
  const [editableStory, isStoryFetching] = useEntityById<IStoryModel>('feature/story', Number(params.id));

  const initialValues = useMemo<TStoryFormModel>(() => ({
    author           : '',
    description      : '',
    endDateUtc       : null,
    startDateUtc     : null,
    status           : 0,
    rStoryTerritory  : [{ territoryId: currentTerritory?.territoryId as number }],
    title            : '',
    ...editableStory,
    businessId       : editableStory?.businessId || null,
    imageId          : editableStory?.rStoryImage[0].imageId || undefined,
    rStoryChannel    : extractEntityChannel(currentTerritory, editableStory?.rStoryChannel),
    storyId          : editableStory?.storyId,
    businessLocation : editableStory?.businessLocation ? {
      label  : editableStory.businessLocation.name,
      source : null,
      value  : editableStory.businessLocation.businessLocationId,
    } : null,
    rStoryImage : editableStory ? {
      imageUri : editableStory.rStoryImage[0].image.imageUri,
      mimeType : 'image',
    } : null,
    tags : editableStory?.tags.length
      ? `#${editableStory.tags.map((item) => item.replace(/ /g, '')).join(' #')}`
      : '',
  }), [currentTerritory, editableStory]);

  const getShortDescription = useCallback((html: string) => {
    const temp           = document.createElement('div');
    temp.innerHTML       = html;
    const plainText      = temp.textContent || temp.innerText || '';
    let shortDescription = plainText;

    while (shortDescription.startsWith('\n')) {
      shortDescription = shortDescription.replace(/^(\n)/, '');
    }

    if (shortDescription.length > 247) {
      shortDescription = shortDescription.slice(0, 247).concat('...');
    }

    return shortDescription;
  }, []);

  const onSubmit = useCallback(async (values: TStoryFormModel) => {
    const { businessLocation, endDateUtc, startDateUtc, ...restValues } = values;
    const businessData = businessLocation?.source as IBusinessLocation | null;

    const requestData: TStoryPostModel = {
      ...restValues,
      businessId         : businessData?.businessId || values.businessId,
      businessLocationId : businessData?.businessLocationId || Number(values.businessLocation?.value) || null,
      endDateUtc         : endDateUtc && format(parseStringToDate(endDateUtc, null), 'yyyy-MM-dd\'T\'HH:mm:ss.SSS'),
      startDateUtc       : startDateUtc && format(parseStringToDate(startDateUtc, null), 'yyyy-MM-dd\'T\'HH:mm:ss.SSS'),
      shortDescription   : getShortDescription(restValues.description),
      tags               : values.tags.replace(' ', '').split('#').filter((item) => item.length),
      rStoryChannel      : values.rStoryChannel.map((item) => ({ channelId: item.value as number })),
      rStoryImage        : [{
        imageId : values.imageId,
        storyId : values.storyId,
        image   : {
          imageId  : values.imageId,
          imageUri : values.rStoryImage?.imageUri as string,
          mimeType : 'image',
        },
      }],
    };

    setStatus(ECardStatuses.Pending);
    try {
      await callApi('feature/story', editableStory ? 'put' : 'post', requestData);
      history.push(routesConfig.dashboard.endpoint);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setStatus(ECardStatuses.Failure);
    }
  }, [editableStory]);

  useEffect(() => {
    setStatus(isStoryFetching ? ECardStatuses.Initialization : ECardStatuses.None);
  }, [isStoryFetching]);

  return (
    <Card
      header = {t('storyDetails')}
      status = {status}
      style  = {{
        padding : '38px 32px 63px',
        width   : '949px',
      }}
    >
      <Formik
        enableReinitialize
        initialValues    = {initialValues}
        onSubmit         = {onSubmit}
        validationSchema = {validationSchema}
      >
        {({ ...props }: FormikProps<TStoryFormModel>) => (
          <StoryForm
            {...props}
            disabled = {status === ECardStatuses.Pending}
          />
        )}
      </Formik>
    </Card>
  );
};
