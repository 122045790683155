import { ISvgIconOptions } from '../../../../models';

export default ({
  color,
  height = '30px',
  width  = '30px',
}: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 792 792" fill={color}>
    <g>
      <path
        className="st0"
        d="M604.3,143.3H155.7c-33,0-59.8,26.8-59.8,59.8v55.8c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5v-55.8 c0-18.1,14.7-32.8,32.8-32.8h448.6c18.1,0,32.8,14.7,32.8,32.8v225h-49.8c-7.5,0-13.5,6-13.5,13.5s6,13.5,13.5,13.5h49.8V495 c0,18.1-14.7,32.8-32.8,32.8H155.7c-18.1,0-32.8-14.7-32.8-32.8v-39.9h390.8c7.5,0,13.5-6,13.5-13.5s-6-13.5-13.5-13.5H122.9V368 c0-7.5-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v127c0,33,26.8,59.8,59.8,59.8H292c-2.7,3.6-6.1,7.6-10.2,11.7 c-17.4,17.5-38.5,28-62.7,31.3c-6.7,0.9-11.7,6.6-11.7,13.4v43.4c0,7.4,5.9,13.4,13.3,13.5l57.9,0.9c0.1,0,0.1,0,0.2,0 c7.4,0,13.4-5.9,13.5-13.3c0.1-7.5-5.8-13.6-13.3-13.7l-44.6-0.7v-18.8c15-3.3,28-8.9,39-15.4c0.7,0.1,1.4,0.2,2.1,0.2h236 c12.6,8.8,28.2,16.4,47.2,20.6v18.4l-161.1-2.5c-0.1,0-0.1,0-0.2,0c-7.4,0-13.4,5.9-13.5,13.3c-0.1,7.5,5.8,13.6,13.3,13.7 l174.8,2.7c0.1,0,0.1,0,0.2,0c3.5,0,6.9-1.4,9.5-3.9s4-6,4-9.6v-43.4c0-6.8-5-12.5-11.7-13.4c-24.2-3.3-45.3-13.8-62.7-31.3 c-6.2-6.3-10.9-12.4-14.1-17.1h107c33,0,59.8-26.8,59.8-59.8V203C664.1,170.1,637.3,143.3,604.3,143.3z M467.5,557.2 c0.5,1,5.2,10.9,15.1,23.1H306c9.7-10.8,15.6-20.6,18.2-25.4h142.4C466.8,555.6,467.1,556.4,467.5,557.2z"
      />
      <path
        className="st0"
        d="M365.8,478c-7.5,0-13.5,6-13.5,13.5s6,13.5,13.5,13.5h54.6c7.5,0,13.5-6,13.5-13.5s-6-13.5-13.5-13.5H365.8z"
      />
    </g>
  </svg>
);
