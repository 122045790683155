import { cloneElement, memo } from 'react';
import styled                 from 'styled-components';

import { TMobileOrganizationPreviewProps } from '../OrganizationPreview';
import { theme as Theme }                  from '../../../../styles/theme';
import {
  DirectionIcon,
  HoursIcon,
  OfferIcon,
  PhoneIcon,
  WebsiteIcon,
} from '../icons';

type ButtonsProps = 'location' | 'offer' | 'operationalHours' | 'phoneNumber' | 'websiteUri';

type TPreviewButtonsProps = Pick<TMobileOrganizationPreviewProps['organization'], ButtonsProps>;

enum EIcon {
  Phone      = 'call',
  Directions = 'directions',
  Website    = 'website',
  Hours      = 'hours',
  Offer      = 'offer',
}

const BUTTONS = [
  { icon: <PhoneIcon />, label: EIcon.Phone },
  { icon: <DirectionIcon />, label: EIcon.Directions },
  { icon: <WebsiteIcon />, label: EIcon.Website },
  { icon: <HoursIcon />, label: EIcon.Hours },
  { icon: <OfferIcon />, label: EIcon.Offer, big: true },
];

const getColor = (data: unknown) => (
  data ? Theme.color.orange : '#969696'
);

const PreviewButtons = ({
  location,
  offer,
  operationalHours,
  phoneNumber,
  websiteUri,
}: TPreviewButtonsProps) => {
  const bubblesColor = ({
    [EIcon.Directions] : getColor(location),
    [EIcon.Hours]      : getColor(operationalHours),
    [EIcon.Offer]      : getColor(offer),
    [EIcon.Phone]      : getColor(phoneNumber),
    [EIcon.Website]    : getColor(websiteUri),
  });

  const getIcon = (item: typeof BUTTONS[0]) => (
    <div>
      {cloneElement(item.icon, { color: bubblesColor[item.label] })}
    </div>
  );

  return (
    <PreviewButtons.Wrapper>
      <PreviewButtons.ButtonsRow>
        {BUTTONS.map((item) => (
          <PreviewButtons.Button
            color = {bubblesColor[item.label]}
            key   = {item.label}
          >
            {item.big ? (
              <PreviewButtons.BigButton>
                {getIcon(item)}
              </PreviewButtons.BigButton>
            ) : (
              <PreviewButtons.CommonButton>
                {getIcon(item)}
                <p>{item.label}</p>
              </PreviewButtons.CommonButton>
            )}
          </PreviewButtons.Button>
        ))}
      </PreviewButtons.ButtonsRow>
      {/* button content */}
    </PreviewButtons.Wrapper>
  );
};

export default memo(PreviewButtons);

PreviewButtons.ButtonsRow = styled.div`
  align-items     : flex-end;
  display         : flex;
  justify-content : space-between;
  margin-top      : -7px;
`;

PreviewButtons.Button = styled.div<{ color: string }>`
  > div {
    align-items : center;
    display     : flex;
  }

  > div > div {
    align-items      : center;
    background-color : #fff;
    border-color     : ${({ color }) => color};
    border-radius    : 50%;
    border-style     : solid;
    box-shadow       : 0 1px 2px 0 #00000034;
    display          : flex;
    justify-content  : center;
  }

  p {
    color          : ${({ color }) => color};
    font-size      : 7px;
    line-height    : 11px;
    margin-top     : 5px;
    text-align     : center;
    text-transform : uppercase;
  }
`;

PreviewButtons.BigButton = styled.div`
  > div {
    border-width : 2px;
    height       : 82px;
    width        : 82px;
  }
`;

PreviewButtons.CommonButton = styled.div`
  flex-direction : column;
  width          : 38px;

  > div {
    border-width : 1px;
    height       : 38px;
    width        : 100%;
  }
`;

PreviewButtons.Wrapper = styled.div`
  border-bottom  : 1px solid #eee;
  margin         : -7px 0 13px;
  padding-bottom : 15px;
  position       : relative;
`;
