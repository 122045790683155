import { Formik, FormikProps }                       from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TFunction, useTranslation }                 from 'react-i18next';
import { useHistory, useParams }                     from 'react-router-dom';
import * as Yup                                      from 'yup';

import { TOption }       from '@common/models';
import { ECardStatuses } from '@common/enums';
import { Card }          from '@common/components/Card';
import {
  validateTheCorrectTimeDirection,
  validateTheTimeDifference,
} from '@common/utils';

import { callApi }              from '@utils/apiCaller';
import { extractEntityChannel } from '@utils/extractEntityChannel';

import { routesConfig }        from '@components/Breadcrumbs/routesConfig';
import { useEntityById }       from '@hooks/useEntityById';
import { IImage, ISlideModel } from '@models/index';
import { useAccountState }     from '@src/AccountContext';

import { SlideForm } from '../components/SlideForm';

type TSlidePostModel = Omit<TSlideFormModel, 'rSlideChannel'> & {
  rSlideChannel: {
    channelId : number;
    slideId   : number | null;
  }[];
}

export type TSlideFormModel = Omit<ISlideModel, 'dateEnd' | 'dateStart' | 'image' | 'imageId' | 'rSlideChannel' | 'rSlideTerritory' | 'slideId'> & {
  dateEnd            : Date | null;
  dateStart          : Date | null;
  image              : Pick<IImage, 'imageId' | 'imageUri' | 'mimeType'> | null;
  imageId?           : number;
  occurrenceChoice   : boolean;
  rSlideChannel      : TOption[];
  rSlideTerritory    : { territoryId: number }[];
  slideId?           : number;
  withRegularRepeats : boolean;
}

const useValidationSchema = (t: TFunction<'translation'>) => Yup.object().shape({
  timeZone : Yup.string().required(),
  name     : Yup.string()
    .max(100, `${t('maxCharactersLength')} 100`)
    .required(t('requiredField')),
  text : Yup.string()
    .max(200, `${t('maxCharactersLength')} 200`)
    .nullable(),
  websiteUri : Yup.string()
    .url(t('notValidUrl'))
    .max(200, `${t('maxCharactersLength')} 200`)
    .nullable(),
  dateStart : Yup.string()
    .typeError(t('requiredField'))
    .required(),
  dateEnd : Yup.string()
    .typeError(t('requiredField'))
    .test(...validateTheCorrectTimeDirection(t, 'dateStart'))
    .test(...validateTheTimeDifference(t, 'dateStart'))
    .required(),
  image : Yup.object()
    .shape({ imageUri: Yup.string().required() })
    .typeError(t('uploadImageVideo'))
    .required(),
  rSlideChannel : Yup.array()
    .min(1, t('requiredField'))
    .required(),
});

export const Slide = () => {
  const history          = useHistory();
  const params           = useParams<{ id?: string }>();
  const { t }            = useTranslation();
  const validationSchema = useValidationSchema(t);

  const [status, setStatus] = useState<ECardStatuses>(ECardStatuses.None);

  const { currentTerritory }             = useAccountState();
  const [editableSlide, isSlideFetching] = useEntityById<ISlideModel>('feature/slide', Number(params.id));

  const initialValues = useMemo<TSlideFormModel>(() => ({
    allDay             : false,
    dateEnd            : null,
    dateStart          : null,
    image              : null,
    name               : '',
    occurrenceChoice   : false,
    text               : '',
    slideRecurrenceId  : null,
    timeZone           : Intl.DateTimeFormat().resolvedOptions().timeZone,
    websiteUri         : '',
    rSlideTerritory    : [{ territoryId: currentTerritory?.territoryId as number }],
    ...editableSlide,
    rSlideChannel      : extractEntityChannel(currentTerritory, editableSlide?.rSlideChannel),
    withRegularRepeats : !!editableSlide?.slideRecurrence?.recurrenceRule,
    slideRecurrence    : editableSlide?.slideRecurrence || {
      recurrenceDateStart : null,
      recurrenceRule      : '',
    },
  }), [editableSlide]);

  const onSubmit = useCallback(async (values: TSlideFormModel) => {
    const getSlideRecurrence = () => {
      if (!values.withRegularRepeats || !values.slideRecurrence?.recurrenceRule) { return null; }

      if (!editableSlide || !editableSlide.slideRecurrenceId) {
        return { ...values.slideRecurrence, recurrenceDateStart: values.dateStart };
      }

      return values.slideRecurrence;
    };

    const requestData: TSlidePostModel = {
      ...values,
      image             : { ...(values.image as Pick<IImage, 'imageUri' | 'mimeType'>), imageId: editableSlide?.imageId || null },
      slideRecurrence   : getSlideRecurrence(),
      slideRecurrenceId : (editableSlide && getSlideRecurrence()) ? editableSlide.slideRecurrenceId : null,
      websiteUri        : values.image?.mimeType === 'image' ? values.websiteUri : null,
      rSlideChannel     : values.rSlideChannel.map((item) => ({
        channelId : item.value as number,
        slideId   : editableSlide?.slideId || null,
      })),
    };

    setStatus(ECardStatuses.Pending);
    try {
      const withRecurring = values.occurrenceChoice
        || (!requestData.slideRecurrenceId && !!requestData.slideRecurrence?.recurrenceRule);

      await callApi(`feature/slide${editableSlide ? `?withRecurring=${withRecurring}` : ''}`, editableSlide ? 'put' : 'post', requestData);
      history.push(routesConfig.dashboard.endpoint);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setStatus(ECardStatuses.Failure);
    }
  }, [editableSlide]);

  useEffect(() => {
    setStatus(isSlideFetching ? ECardStatuses.Initialization : ECardStatuses.None);
  }, [isSlideFetching]);

  return (
    <Card
      header = {t('slideDetails')}
      status = {status}
      style  = {{
        padding : '38px 32px 63px',
        width   : '949px',
      }}
    >
      <Formik
        enableReinitialize
        initialValues    = {initialValues}
        onSubmit         = {onSubmit}
        validationSchema = {validationSchema}
      >
        {({ ...props }: FormikProps<TSlideFormModel>) => (
          <SlideForm
            {...props}
            disabled = {status === ECardStatuses.Pending}
          />
        )}
      </Formik>
    </Card>
  );
};
