import { Modal as ModalAnt } from 'antd';
import styled                from 'styled-components';

import { ECardStatuses }          from '@common/enums';
import { CardHeader, CardStatus } from '@common/components/Card';

import { theme as Theme }          from '@styles/theme';
import { DeniedIcon as CloseIcon } from '@public/svg';

interface ModalProps {
  bodyStyle? : (theme: typeof Theme) => string;
  children   : React.ReactNode;
  onCancel   : () => void;
  status?    : ECardStatuses;
  title?     : string;
  width?     : string;
}

export const Modal = ({
  bodyStyle,
  children,
  onCancel,
  status,
  title,
  width,
}: ModalProps) => (
  <ModalAnt
    centered
    visible
    bodyStyle = {{ padding: '0' }}
    closable  = {false}
    footer    = {null}
    onCancel  = {onCancel}
    width     = {width}
  >
    <Modal.Body bodyStyle={bodyStyle}>
      {status && (
        <CardStatus
          status       = {status || ECardStatuses.None}
          wrapperStyle = {() => 'transform: translateY(-10px);'}
        />
      )}
      {title && (
        <Modal.Header>
          <CardHeader title={title} />
          <Modal.CloseIconWrapper onClick={onCancel}>
            <CloseIcon
              color  = {Theme.color.darkGray}
              height = "14px"
              width  = "14px"
            />
          </Modal.CloseIconWrapper>
        </Modal.Header>
      )}
      <Modal.Content paddingTop={title ? '31px' : '0'}>
        {children}
      </Modal.Content>
    </Modal.Body>
  </ModalAnt>
);

Modal.CloseIconWrapper = styled.div`
  cursor: pointer;
`;

Modal.Content = styled.div<{ paddingTop: string }>`
  padding: ${({ paddingTop }) => `${paddingTop} 40px 0`};
`;

Modal.Header = styled.div`
  align-items     : center;
  border-bottom   : 1px solid ${({ theme }) => theme.color.lightGray};
  display         : flex;
  justify-content : space-between;
  padding         : 0 45px 20px 40px;
  text-transform  : uppercase;

  h2 {
    font-size   : 16px;
    line-height : 21px;
  }

  > div:first-child { margin-bottom: 0; }
`;

Modal.Title = styled.h2`
  color       : ${({ theme }) => theme.color.black};
  font-size   : 16px;
  line-height : 21px;
`;

Modal.Body = styled.div<{ bodyStyle: ModalProps['bodyStyle'] }>`
  overflow : hidden;
  padding  : 21px 0 41px;
  position : relative;

  ${({ bodyStyle, theme }) => bodyStyle && bodyStyle(theme)}
`;
