import styled from 'styled-components';

export const LastUpdatesWrapper = styled.div`
  display        : flex;
  gap            : 20px;
  flex-direction : column;

  > div {
    min-width : 303px;
    width     : 303px;
  }
`;
