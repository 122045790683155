import { message }        from 'antd';
import { useState }       from 'react';
import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { LinkOutlined, CheckOutlined } from '@ant-design/icons';

enum EAnimation {
  None           = 'none',
  RotateForward  = 'rotate-forward 0.3s',
  RotateBackward = 'rotate-backward 0.3s',
}

const ANIMATION_DURATION = 300;

export const OrganizationCopyIcon = () => {
  const { t } = useTranslation();

  const [animation, setAnimation] = useState(EAnimation.None);
  const [icon, setIcon]           = useState(<LinkOutlined />);

  const finishAnimation = () => {
    setAnimation(EAnimation.RotateBackward);

    setTimeout(() => {
      setIcon(<LinkOutlined />);
    }, ANIMATION_DURATION / 2);

    setTimeout(() => {
      setAnimation(EAnimation.None);
    }, ANIMATION_DURATION);
  };

  const startAnimation = () => {
    message.info(t('sharingLinkIsCopied'), 0.8);
    setAnimation(EAnimation.RotateForward);

    setTimeout(() => {
      setIcon(<CheckOutlined />);
    }, ANIMATION_DURATION / 2);

    setTimeout(() => {
      finishAnimation();
    }, ANIMATION_DURATION * 2);
  };

  return (
    <OrganizationCopyIcon.Wrapper
      animation = {animation}
      onClick   = {animation === EAnimation.None ? startAnimation : undefined}
    >
      {icon}
    </OrganizationCopyIcon.Wrapper>
  );
};

OrganizationCopyIcon.Wrapper = styled.div<{ animation: string }>`
  @keyframes rotate-forward {
    from { transform: rotate3d(0); }
    50% { transform: rotate3d(1, 0, 0, 90deg); }
    to { transform: rotate3d(0); }
  }

  @keyframes rotate-backward {
    from { transform: rotate3d(0); }
    50% { transform: rotate3d(1, 0, 0, 90deg); }
    to { transform: rotate3d(0); }
  }

  animation: ${({ animation }) => animation};
`;
