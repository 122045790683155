import { initializeDynamicLinkGenerator } from '@common/initializers';

const { FIREBASE_WEB_API_KEY }  = process.env;
const { PROJECT_URL }           = process.env;
const { ANDROID_PACKAGE_NAME }  = process.env;
const { IOS_BUNDLE_ID }         = process.env;
const { IOS_APP_STORE_ID }      = process.env;
const { APPCENTER_ANDROID_URL } = process.env;
const { APPCENTER_IOS_URL }     = process.env;

export const createFirebaseDynamicLink = initializeDynamicLinkGenerator({
  firebaseWebApiKey   : FIREBASE_WEB_API_KEY || '',
  projectUrl          : PROJECT_URL || '',
  androidPackageName  : ANDROID_PACKAGE_NAME || '',
  iosBundleId         : IOS_BUNDLE_ID || '',
  iosAppStoreId       : IOS_APP_STORE_ID || '',
  appCenterAndroidUrl : APPCENTER_ANDROID_URL || '',
  appCenterIosUrl     : APPCENTER_IOS_URL || '',
});
