import { memo }           from 'react';
import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { StarIcon }                        from '../icons';
import { TMobileOrganizationPreviewProps } from '../OrganizationPreview';
import { theme as Theme }                  from '../../../../styles/theme';

type FollowButtonProps = 'name';

type TPreviewFollowButtonProps = Pick<TMobileOrganizationPreviewProps['organization'], FollowButtonProps>;

const PreviewFollowButton = ({ name }: TPreviewFollowButtonProps) => {
  const { t } = useTranslation();

  return (
    <PreviewFollowButton.Wrapper>
      <div>
        <StarIcon color={Theme.color.orange} />
        <p>{t('follow')} {name || t('organization')}</p>
      </div>
    </PreviewFollowButton.Wrapper>
  );
};

export default memo(PreviewFollowButton);

PreviewFollowButton.Wrapper = styled.div`
  padding-bottom: 20px;

  div {
    align-items     : center;
    border          : 1px solid ${({ theme }) => theme.color.orange};
    border-radius   : 30px;
    box-shadow      : inset 0 1px 1px 0 #0003;
    display         : flex;
    gap             : 7px;
    height          : 38px;
    justify-content : center;
    padding         : 0 20px;
    width           : 100%;
  }

  p {
    color          : ${({ theme }) => theme.color.orange};
    font-size      : 12px;
    font-weight    : 600;
    line-height    : 16px;
    overflow       : hidden;
    text-overflow  : ellipsis;
    text-transform : uppercase;
    white-space    : nowrap;
  }
`;
