import styled from 'styled-components';

export const NotificationRoot = () => (
  <NotificationRoot.Container id="notification-root" />
);

NotificationRoot.Container = styled.div`
  color : ${({ theme }) => theme.color.red};

  .ant-notification-notice-message { font-weight: 600; }
`;
