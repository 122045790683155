import { ISvgIconOptions } from '../../../../models';

export default ({
  color,
  height = '30px',
  width  = '30px',
}: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 792 792" fill={color}>
    <g>
      <path
        className="st0"
        d="M642.9,325L463.4,145.5C441.1,123.3,411.5,111,380,111s-61.1,12.3-83.4,34.5L117.1,325 c-22.3,22.3-34.5,51.9-34.5,83.4s12.3,61.1,34.5,83.4l179.5,179.5c22.3,22.3,51.9,34.5,83.4,34.5s61.1-12.3,83.4-34.5l36.8-36.8 c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-36.8,36.8c-35.5,35.5-93.2,35.5-128.6,0L136.2,472.7c-35.5-35.5-35.5-93.2,0-128.6 l179.5-179.5c35.5-35.5,93.2-35.5,128.6,0l179.5,179.5c35.5,35.5,35.5,93.2,0,128.6l-56,56c-5.3,5.3-5.3,13.8,0,19.1 s13.8,5.3,19.1,0l56-56c22.3-22.3,34.5-51.9,34.5-83.4S665.1,347.3,642.9,325z"
      />
      <path
        className="st0"
        d="M257.9,318.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-59.4,59.4c-13.6,13.6-21,31.6-21,50.8 c0,19.2,7.5,37.2,21,50.8l152,152c13.6,13.6,31.6,21,50.8,21c19.2,0,37.2-7.5,50.8-21l152-152c13.6-13.6,21-31.6,21-50.8 c0-19.2-7.5-37.2-21-50.8l-152-152c-13.6-13.6-31.6-21-50.8-21c-19.2,0-37.2,7.5-50.8,21l-23.8,23.8c-5.3,5.3-5.3,13.8,0,19.1 s13.8,5.3,19.1,0l23.8-23.8c8.5-8.5,19.7-13.1,31.7-13.1s23.2,4.7,31.7,13.1l152,152c8.5,8.5,13.1,19.7,13.1,31.7 s-4.7,23.2-13.1,31.7l-152,152c-8.5,8.5-19.7,13.1-31.7,13.1s-23.2-4.7-31.7-13.1l-152-152c-8.5-8.5-13.1-19.7-13.1-31.7 s4.7-23.2,13.1-31.7L257.9,318.5z"
      />
      <path
        className="st0"
        d="M325.9,383.4c-11,10-16.7,24-16.7,40.4v62.9c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5v-62.9 c0-8.8,2.6-15.5,7.9-20.4c11.8-10.8,34.9-11.5,42.8-10.8c0.4,0,0.8,0.1,1.2,0.1h62.5c0.8,0,1.6-0.1,2.3-0.2l-48.5,55.1 c-4.9,5.6-4.4,14.1,1.2,19.1c2.6,2.3,5.7,3.4,8.9,3.4c3.7,0,7.5-1.5,10.1-4.6L490,391c4.5-5.1,4.5-12.6,0.1-17.7l-56.5-65.4 c-4.9-5.6-13.4-6.3-19-1.4s-6.3,13.4-1.4,19l34.7,40.1h-59.2C382.2,365.1,347.7,363.5,325.9,383.4z"
      />
    </g>
  </svg>
);
