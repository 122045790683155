import { useCallback } from 'react';

import { callApi } from '@utils/apiCaller';

import { ResetPassword as CommonResetPassword } from '@common/modules/ResetPassword';

export const ResetPassword = () => {
  const onSubmit = useCallback(async (oldPassword: string, newPassword: string) => {
    await callApi('LicenseAccount/password/update', 'PUT', { oldPassword, password: newPassword });
  }, []);

  return (
    <CommonResetPassword onSubmit={onSubmit} />
  );
};
