import { format }                                    from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation }                            from 'react-i18next';
import { useHistory }                                from 'react-router-dom';

import { Table, TableProps } from '@common/components/Table/Table';
import { Card, CardHeader }  from '@common/components/Card';
import { ECardStatuses }     from '@common/enums';

import { routesConfig }  from '@components/Breadcrumbs/routesConfig';

import { useFetchedEntity } from '@hooks/useFetchedEntity';
import { callApi }          from '@utils/apiCaller';
import { ISlideModel }      from '@src/models';

export const Carousel = () => {
  const history = useHistory();
  const { t }   = useTranslation();

  const [status, setStatus]    = useState<ECardStatuses>(ECardStatuses.None);
  const [currentPage, setPage] = useState<number>(1);

  const [fetchedSlides, isFetching, refetchSlides] = useFetchedEntity<ISlideModel>(`feature/slide?page=${currentPage - 1}&itemsPerPage=10&sortingKey=name`);

  const header = useMemo(() => (
    <CardHeader
      buttons = {[{ action: () => history.push(routesConfig.slideAdd.endpoint), label: t('createSlide') }]}
      title   = {t('carousel')}
    />
  ), []);

  const tableHeaders = useMemo<TableProps['headers']>(() => [
    { id: 'title', label: t('title') },
    { id: 'type', label: t('type') },
    { id: 'starts', label: t('starts') },
    { id: 'ends', label: t('ends') },
  ], []);

  const tableContent = useMemo<TableProps['content']>(() => ((fetchedSlides?.items || []).map((item) => ({
    title  : { onClick: () => history.push(routesConfig.slideEdit.endpoint.replace(':id', item.slideId.toString())), tag: 'link', title: item.name },
    type   : `${t(item.image.mimeType || '')}`,
    starts : format(new Date(item.dateStart), 'MMM dd, yyyy'),
    ends   : format(new Date(item.dateEnd), 'MMM dd, yyyy'),
    id     : item.slideId,
  }))), [fetchedSlides]);

  const tableSettings = useMemo<TableProps['settings']>(() => ({
    columns : [
      { flex: 5 },
      { flex: 2 },
      { flex: 2, minWidth: 85 },
      { flex: 2 },
    ],
    pagination : {
      setPage,
      current : currentPage,
      total   : fetchedSlides?.totalCount,
    },
  }), [currentPage, fetchedSlides?.totalCount]);

  const deleteSlides = useCallback(async (slidesIds: (number | string)[]) => {
    setStatus(ECardStatuses.Pending);

    try {
      for (let i = 0; i < slidesIds.length;) {
        // eslint-disable-next-line no-await-in-loop
        await callApi(`feature/slide/${slidesIds[i]}`, 'DELETE');
        i += 1;
      }

      setStatus(ECardStatuses.Success);
      refetchSlides();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setStatus(ECardStatuses.Failure);
    }
  }, [currentPage]);

  useEffect(() => {
    setStatus(isFetching ? ECardStatuses.Pending : ECardStatuses.None);
  }, [isFetching]);

  return (
    <Card
      header = {header}
      status = {status}
      style  = {{ width: '100%' }}
    >
      <Table
        content  = {tableContent}
        headers  = {tableHeaders}
        onDelete = {deleteSlides}
        settings = {tableSettings}
      />
    </Card>
  );
};
