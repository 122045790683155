import { format }               from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useTranslation }       from 'react-i18next';

import { CardDetails, IDetailsElement } from '@common/components/Card/CardDetails';
import { StatusItem }                   from '@common/components/Table/TableItems';

import { IOrganizationDetailsById } from '@src/models';
import { theme as Theme }           from '@styles/theme';

interface IOrganizationDetails {
  organization: IOrganizationDetailsById;
}

export const OrganizationDetails = ({ organization }: IOrganizationDetails) => {
  const { t } = useTranslation();

  const getArrayString = useCallback((array: Record<string, unknown>[], key: string) => array.map((item) => item[key]).join(', '), []);

  const contactEmail = useMemo(() => ({
    label   : t('contactEmail'),
    value   : organization.contactEmail,
    onClick : () => {
      if (organization.contactEmail) {
        return window.open(`mailto:${organization.contactEmail}`);
      }

      return null;
    },
  }), [organization]);

  const details = useMemo<IDetailsElement[]>(() => [
    { label: t('businessName'), value: organization.name },
    { label: t('signedUp'), value: format(new Date(organization.created), 'MMMM d, y') },
    { label: t('status'), value: <StatusItem status={organization.status} /> },
    { label: t('areas'), value: getArrayString(organization.rBusinessTerritory, 'territoryName') },
    { label: t('channel'), value: getArrayString(organization.rBusinessLocationChannel, 'channelName') },
    { label: t('subcategories'), value: getArrayString(organization.rBusinessBusinessCategory, 'categoryName') },
    { label: t('contactName'), value: organization.contactName },
    contactEmail,
    { label: t('contactNumber'), value: organization.phoneNumber },
    { color: Theme.color.blue, label: t('uniqueLink'), tags: ['copy'], value: organization.tPromoCode[0]?.firebaseDynamicLink },
  ], [organization]);

  return (
    <CardDetails
      details = {details}
      title   = {t('organizationDetails')}
    />
  );
};
