import { AxiosResponse }        from 'axios';
import { useMemo, useRef }      from 'react';
import useSWR, { KeyedMutator } from 'swr';

import { TFilterValue, ITableDataRules } from '@common/components/FiltrationSorting';

import { useAccountState } from '@src/AccountContext';
import { callApi }         from '@utils/apiCaller';

type TData<T> = { totalCount: number; items: T[] };

const addFiltrationRulesToQuery = (route: string, filtration: ITableDataRules['filtration']) => {
  const filtrationKeys = Object.keys(filtration);

  const result = filtrationKeys.reduce((acc, curr) => {
    const filterRule: TFilterValue = filtration[curr].value;

    if (Array.isArray(filterRule)) {
      return `${acc}&${filterRule.map((item) => `${curr}=${typeof item === 'string' ? item : item.value}`).join('&')}`;
    }
    if (typeof filterRule === 'object') {
      return `${acc}&${curr}=${filterRule.value}`;
    }

    return `${acc}&${curr}=${filterRule}`;
  }, route);

  return result;
};

export const useFetchedEntity = <T>(
  route               : string,
  tableDataRules?     : ITableDataRules | null,
  options = { useTerritoryId: true },
): [TData<T> | null, boolean, KeyedMutator<AxiosResponse<TData<T>>>] => {
  const { currentTerritory } = useAccountState();

  const fetchedData = useRef<TData<T> | null>(null);

  const endpoint = useMemo(() => {
    let result = route;

    if (route.includes('/undefined')) {
      return null;
    }

    if (options.useTerritoryId && !currentTerritory?.territoryId) {
      return null;
    }

    if (options.useTerritoryId && currentTerritory?.territoryId) {
      result = `${result}&territoryId=${currentTerritory.territoryId}`;
    }

    if (tableDataRules) {
      const { filtration, sorting } = tableDataRules;

      if (sorting) {
        result = `${result}&sortingKey=${sorting.value}&sortingOrder=${sorting.order}`;
      }

      if (filtration) {
        result = addFiltrationRulesToQuery(result, filtration);
      }
    }

    return result;
  }, [route, options, tableDataRules]);

  const { data, isValidating, mutate } = useSWR<AxiosResponse<TData<T>>>(endpoint, callApi);

  if (data?.data) {
    fetchedData.current = data.data;
  }

  // TODO: mutate must be custom function with optional(or required) argument currentPage for correct refetching
  return [fetchedData.current, isValidating, mutate];
};
