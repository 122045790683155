import { memo, useMemo }  from 'react';
import { useTranslation } from 'react-i18next';
import styled             from 'styled-components';

import { RelatedEntitiesList }             from './index';
import { TMobileOrganizationPreviewProps } from '../OrganizationPreview';
import { getDateTimeIntervals }            from '../../../../utils';

interface IEventTimeProps {
  allDay    : boolean;
  dateEnd   : string | null;
  dateStart : string;
  timeZone  : string;
}

type TPreviewStoriesProps = {
  events : NonNullable<TMobileOrganizationPreviewProps['events']>;
  name   : TMobileOrganizationPreviewProps['organization']['name'];
};

const EventTime = ({
  allDay,
  ...event
}: IEventTimeProps) => {
  const { dateInterval, timeInterval } = useMemo(() => getDateTimeIntervals({ ...event }, 'eee, MMM d', 'h:mm a'), [event]);

  return (
    <>
      {dateInterval}
      {!allDay && (
        <>
          {' | '}
          <EventTime.Bold>
            {timeInterval}
          </EventTime.Bold>
        </>
      )}
    </>
  );
};

const PreviewEvents = ({
  events,
  name,
}: TPreviewStoriesProps) => {
  const { t } = useTranslation();

  const mappedEvents = useMemo(() => events.map((event) => ({
    ...event,
    date : <EventTime {...event} />,
    id   : event.eventId,
  })), [events]);

  if (events.length === 0) {
    return null;
  }

  return (
    <RelatedEntitiesList
      items = {mappedEvents}
      title = {t('eventsByNAME', { name })}
    />
  );
};

export default memo(PreviewEvents);

EventTime.Bold = styled.span`
  color: #454545;
`;
