import { useCallback, useEffect, useState } from 'react';
import { useTranslation }                   from 'react-i18next';
import styled                               from 'styled-components';

import { NewsieRobotHead } from '../../../public/png';

export interface IRouteItem {
  endpoint        : string;
  localizationKey : string;
  path            : IRouteItem[];
}

interface IBreadcrumbs {
  containerStyle? : { margin?: string; };
  currentPath     : string;
  onClick         : (route: string) => void;
  routes          : { [key: string]: IRouteItem };
}

const INITIAL_CONTAINER_STYLE = { margin: '0 0 40px' };

export const Breadcrumbs = ({ containerStyle, currentPath, onClick, routes }: IBreadcrumbs) => {
  const { t } = useTranslation();

  const [currentPage, setPage] = useState<IRouteItem | null>(null);

  const buildPath = useCallback((endpoint: string) => {
    const pages: IRouteItem[] = Object.values(routes);

    const matchedPage = pages.find((item) => item.endpoint === endpoint);

    setPage(matchedPage || null);
  }, [currentPath]);

  const onClickPathItem = useCallback((item: IRouteItem) => {
    onClick(item.endpoint);
  }, [onClick]);

  const parseUrl = useCallback(() => {
    const endpointWithDynamicId = currentPath.includes('/edit/')
      || currentPath.includes('/review/')
      || currentPath.includes('/clone/')
      || currentPath.includes('/preview/')
      || currentPath.includes('/submit/');

    let existingEndpoint = currentPath;

    if (endpointWithDynamicId) {
      const pathArray = currentPath.split('/').filter((item) => item.length);

      existingEndpoint = pathArray.reduce((prev, curr, index) => {
        if (index === pathArray.length - 1) {
          return `${prev}/:id`;
        }

        return `${prev}/${curr}`;
      }, '');
    }

    buildPath(existingEndpoint);
  }, [currentPath, buildPath]);

  useEffect(() => {
    parseUrl();
  }, [currentPath]);

  if (!currentPage) {
    return null;
  }

  return (
    <Breadcrumbs.Wrapper>
      <Breadcrumbs.IconWrapper>
        <img src={NewsieRobotHead} alt="NewsieRobotHead" />
      </Breadcrumbs.IconWrapper>
      <Breadcrumbs.Items {...INITIAL_CONTAINER_STYLE} {...containerStyle}>
        {currentPage.path.map((item) => (
          <Breadcrumbs.Item
            clickable
            key     = {item.localizationKey}
            onClick = {() => onClickPathItem(item)}
          >
            {t(item.localizationKey)}
          </Breadcrumbs.Item>
        ))}
        <Breadcrumbs.Item
          clickable = {false}
          key       = {currentPage.localizationKey}
        >
          {t(currentPage.localizationKey)}
        </Breadcrumbs.Item>
      </Breadcrumbs.Items>
    </Breadcrumbs.Wrapper>
  );
};

Breadcrumbs.Items = styled.div<{ margin: string }>`
  display : flex;
  margin  : ${({ margin }) => margin};
`;

Breadcrumbs.IconWrapper = styled.div`
  margin: -15px 6px 0 0;

  img {
    height : 50px;
    width  : 50px;
  }
`;

Breadcrumbs.Item = styled.p<{ clickable: boolean }>`
  color       : ${({ clickable, theme }) => (clickable ? theme.color.blue : theme.color.black)};
  cursor      : ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  font-size   : 24px;
  font-weight : ${({ clickable }) => (clickable ? 400 : 600)} !important;
  line-height : 33px;

  ${({ clickable, theme }) => clickable && `
    &:after {
      color   : ${theme.color.black};
      content : '>';
      margin  : 0 5px;
  `}
`;

Breadcrumbs.Wrapper = styled.div`
  display : flex;
`;
