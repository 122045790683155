import { format }                           from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation }                   from 'react-i18next';
import styled                               from 'styled-components';

import { LoadingOutlined } from '@ant-design/icons';

import { NewsieLogo }    from '@public/svg';
import { IInvoiceModel } from '@src/models';
import { callApi }       from '@utils/apiCaller';

import { Modal }        from './Modal';
import { ReceiptField } from './ReceiptField';

interface InvoiceModalProps {
  closeModal : () => void;
  invoiceId  : number;
}

export const InvoiceModal = ({ closeModal, invoiceId }: InvoiceModalProps) => {
  const { t } = useTranslation();

  const [fetchedInvoice, setInvoice] = useState<IInvoiceModel | null>(null);

  const fetchInvoice = async () => {
    try {
      const { data } = await callApi(`Subscription/transaction/${invoiceId}/invoice`);

      setInvoice(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const onClickEmail = useCallback(() => {
    window.open('mailto:support@localnewsie.com');
  }, []);

  useEffect(() => {
    fetchInvoice();
  }, [invoiceId]);

  return (
    <Modal
      onCancel  = {closeModal}
      title     = {fetchedInvoice ? `${t('invoice')} #${fetchedInvoice.id}` : undefined}
      width     = "718px"
      bodyStyle = {() => `
        padding: 21px 0 49px;

        > div:nth-child(2) { padding-top: 0; }
      `}
    >
      <InvoiceModal.Wrapper>
        {!fetchedInvoice ? (
          <InvoiceModal.LoaderWrapper>
            <LoadingOutlined />
          </InvoiceModal.LoaderWrapper>
        ) : (
          <>
            <InvoiceModal.Section>
              <InvoiceModal.Header>
                <NewsieLogo width="150px" />
                <h1>{t('thankYou')}!</h1>
              </InvoiceModal.Header>
            </InvoiceModal.Section>
            <InvoiceModal.Section>
              <InvoiceModal.ReceiptHeader>
                {t('orderReceipt')}
              </InvoiceModal.ReceiptHeader>
              <InvoiceModal.Receipt>
                <div>
                  <ReceiptField
                    label = {t('date')}
                    value = {[format(new Date(fetchedInvoice.createdDate), 'EEEE, MMMM d, yyyy')]}
                  />
                  <ReceiptField
                    label = {t('organizationId')}
                    value = {[fetchedInvoice.businessLocationId]}
                  />
                  <ReceiptField
                    label = {t('organizationName')}
                    value = {[fetchedInvoice.businessLocationName]}
                  />
                </div>
                <div>
                  <ReceiptField
                    label   = {t('billingTo')}
                    oneLine = {false}
                    value   = {[
                      `${fetchedInvoice.cardBrand.charAt(0).toUpperCase()}${fetchedInvoice.cardBrand.slice(1)}... ${fetchedInvoice.cardLast4}`,
                      fetchedInvoice.address.line1.split(',')[0],
                      `${fetchedInvoice.address.state}, ${fetchedInvoice.address.country} ${fetchedInvoice.address.postal_code}`,
                    ]}
                  />
                </div>
              </InvoiceModal.Receipt>
            </InvoiceModal.Section>
            <InvoiceModal.Section>
              <InvoiceModal.Row>
                <h3>{t('description')}</h3>
                <h3>{t('price')}</h3>
              </InvoiceModal.Row>
              {fetchedInvoice.territoriesPrice.branches[0].territories.map((territory) => (
                <InvoiceModal.Row key={territory.territoryId}>
                  <p>{territory.territoryName}</p>
                  <p>{'$'}{territory.territoryPrice}</p>
                </InvoiceModal.Row>
              ))}
              <InvoiceModal.Row>
                <h3>{t('total')}</h3>
                <h3>{'$'}{fetchedInvoice.territoriesPrice.amount}</h3>
              </InvoiceModal.Row>
            </InvoiceModal.Section>
            <InvoiceModal.Section withoutSeparator>
              <InvoiceModal.Comment>
                <p>{t('retainReceiptForReference')}</p>
                <p>{t('salesIncludedInPrice')}</p>
                <p>
                  {`${t('forQuestionsEmail')} `}
                  <InvoiceModal.SupportEmail onClick={onClickEmail}>
                    support@localnewsie.com
                  </InvoiceModal.SupportEmail>
                </p>
              </InvoiceModal.Comment>
            </InvoiceModal.Section>
          </>
        )}
      </InvoiceModal.Wrapper>
    </Modal>
  );
};

InvoiceModal.Comment = styled.div`
  p,
  span {
    color       : #727272;
    font-size   : 12px;
    line-height : 16px;
  }
`;

InvoiceModal.Header = styled.div`
  align-items     : center;
  display         : flex;
  justify-content : space-between;

  h1 {
    color       : ${({ theme }) => theme.color.black};
    font-size   : 25px;
    font-weight : 700;
  }
`;

InvoiceModal.LoaderWrapper = styled.div`
  color           : ${({ theme }) => theme.color.blue};
  display         : flex;
  font-size       : 30px;
  justify-content : center;
  margin-top      : 49px;
`;

InvoiceModal.Receipt = styled.div`
  display         : flex;
  justify-content : space-between;

  > div { width: 252px; }
`;

InvoiceModal.Row = styled.div`
  display         : flex;
  justify-content : space-between;

  h3,
  p {
    font-size   : 16px;
    line-height : 29px;
  }

  h3 { font-weight : 700; }

  p { font-weight : 300; }
`;

InvoiceModal.ReceiptHeader = styled.h2`
  font-size     : 24px;
  font-weight   : 400;
  line-height   : 33px;
  margin-bottom : 11px;
`;

InvoiceModal.Section = styled.div<{ withoutSeparator?: boolean }>`
  border              : 0 solid ${({ theme }) => theme.color.lightGray};
  border-bottom-width : ${({ withoutSeparator }) => (withoutSeparator ? 0 : 1)}px;
  padding             : ${({ withoutSeparator }) => (withoutSeparator ? '26px 0 0' : '26px 0')};
`;

InvoiceModal.SupportEmail = styled.span`
  cursor          : pointer;
  text-decoration : underline;
  transition      : all 0.2s;

  &:hover { color: ${({ theme }) => theme.color.blue}; }
`;

InvoiceModal.Wrapper = styled.div``;
