import { ISvgIconOptions } from './index';

export default ({ height = '22px', width = '22px' }: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 515 515">
    <path
      fill = "#cee1f2"
      d    = "M80,140V60c0-22,18-40,40-40H60c-22,0-40,18-40,40v80c0,22,14,40,36,40c12.59,0,23.87-5.9,31.22-15.08 C82.57,158.07,80,149.41,80,140z"
    />
    <g>
      <path
        fill = "#0058ff"
        d    = "M492,229.15c-11.05,0-20,8.95-20,20V452c0,11.03-8.97,20-20,20H60c-11.03,0-20-8.97-20-20V249.15 c0-11.05-8.95-20-20-20s-20,8.95-20,20V452c0,33.08,26.92,60,60,60h392c33.08,0,60-26.92,60-60V249.15 C512,238.1,503.05,229.15,492,229.15z"
      />
      <path
        fill = "#0058ff"
        d    = "M216,432c11.05,0,20-8.95,20-20V256c0-11.05-8.95-20-20-20H96c-11.05,0-20,8.95-20,20v156 c0,11.05,8.95,20,20,20s20-8.95,20-20V276h80v136C196,423.05,204.95,432,216,432z"
      />
      <path
        fill = "#0058ff"
        d    = "M452,0H60C26.92,0,0,26.92,0,60v80c0,34.21,24.08,60,56,60c15.35,0,29.38-5.8,40-15.32 c10.62,9.52,24.65,15.32,40,15.32s29.38-5.8,40-15.32c10.62,9.52,24.65,15.32,40,15.32s29.38-5.8,40-15.32 c10.62,9.52,24.65,15.32,40,15.32s29.38-5.8,40-15.32c10.62,9.52,24.65,15.32,40,15.32s29.38-5.8,40-15.32 c10.62,9.52,24.65,15.32,40,15.32c31.92,0,56-25.79,56-60V60C512,26.92,485.08,0,452,0z M472,140c0,9.23-4.19,20-16,20 c-11.03,0-20-8.97-20-20c0-11.05-8.95-20-20-20s-20,8.95-20,20c0,11.03-8.97,20-20,20s-20-8.97-20-20c0-11.05-8.95-20-20-20 s-20,8.95-20,20c0,11.03-8.97,20-20,20s-20-8.97-20-20c0-11.05-8.95-20-20-20s-20,8.95-20,20c0,11.03-8.97,20-20,20s-20-8.97-20-20 c0-11.05-8.95-20-20-20s-20,8.95-20,20c0,11.03-8.97,20-20,20s-20-8.97-20-20c0-11.05-8.95-20-20-20s-20,8.95-20,20 c0,11.03-8.97,20-20,20c-11.81,0-16-10.77-16-20V60c0-11.03,8.97-20,20-20h392c11.03,0,20,8.97,20,20V140z"
      />
    </g>
    <path
      fill = "#f0552a"
      d    = "M276,256v116c0,11.05,8.95,20,20,20h120c11.05,0,20-8.95,20-20V256c0-11.05-8.95-20-20-20H296 C284.95,236,276,244.95,276,256z M316,276h80v76h-80V276z"
    />
  </svg>
);
