import { AxiosResponse }                    from 'axios';
import { Field, Form, FormikProps }         from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation }                   from 'react-i18next';
import styled                               from 'styled-components';

import { TOption } from '@common/models';

import { FormButtons }      from '@common/components/Button';
import { Input }            from '@common/components/Input';
import { BorderlessSelect } from '@common/components/Select';

import { useAccountState } from '@src/AccountContext';
import { IChannelModel }   from '@src/models';
import { callApi }         from '@utils/apiCaller';

import { TFeedFormModel } from '../views/FeedModal';

interface IFeedForm extends FormikProps<TFeedFormModel> {
  disabled: boolean;
}

export const FeedForm = ({ disabled, setFieldValue }: IFeedForm) => {
  const { t }                = useTranslation();
  const { currentTerritory } = useAccountState();

  const [channels, setChannels] = useState<TOption[]>([]);

  const fetchChannels = useCallback(async () => {
    try {
      const { data } = await callApi(`feature/Channel/search?territoryId=${currentTerritory?.territoryId}`) as AxiosResponse<IChannelModel[]>;

      setChannels(data.map((item) => ({
        label : item.name,
        value : item.channelId,
      })));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, []);

  const setInitialOption = useCallback((options: TOption[]) => {
    const initialOption = options.find((item) => item.label === currentTerritory?.territoryName);

    setFieldValue('channelId', initialOption?.value || options[0].value);
  }, [setFieldValue]);

  useEffect(() => {
    fetchChannels();
  }, []);

  return (
    <FeedForm.Form>
      <Field
        bordered
        component    = {Input}
        label        = {t('publisherName')}
        name         = "publisherName"
        wrapperStyle = {() => 'margin: 0'}
      />
      <Field
        bordered
        component    = {Input}
        label        = {t('publisherUrl')}
        name         = "publisherUri"
        wrapperStyle = {() => 'margin: 23px 0 0'}
      />
      <Field
        bordered
        autoSize     = {{ minRows: 6, maxRows: 6 }}
        component    = {Input}
        label        = {t('notes')}
        maxLength    = {600}
        name         = "notes"
        required     = {false}
        type         = "textarea"
        wrapperStyle = {() => 'margin: 23px 0 0'}
      />
      <FeedForm.Footer>
        <Field
          label        = "Assign to:"
          component    = {BorderlessSelect}
          onEmptyField = {setInitialOption}
          options      = {channels}
          name         = "channelId"
        />
        <FormButtons
          disabled        = {{ main: disabled || !channels.length }}
          mainButtonLabel = {t('submit')}
        />
      </FeedForm.Footer>
    </FeedForm.Form>
  );
};

FeedForm.Column = styled.div<{ rightSide?: boolean }>`
  flex  : 1;
  width : 100%;

  ${({ rightSide }) => rightSide && `
    display         : flex;
    flex-direction  : column;
    justify-content : space-between;
  `}
`;

FeedForm.Footer = styled.div`
  align-items     : center;
  display         : flex;
  justify-content : space-between;

  > div:last-child { width: auto; }
`;

FeedForm.Form = styled(Form)`
  > div div p { text-transform: none; }
`;
