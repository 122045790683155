import { Formik }               from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation }       from 'react-i18next';

import { Card }              from '@common/components/Card';
import { MemberDetailsForm } from '@common/modules/MemberDetails/components/MemberDetailsForm';

interface IValues {
  firstName : string;
  lastName  : string;
  email     : string;
  phone     : string;
}

export const BillingInfo = () => {
  const { t } = useTranslation();

  const initialValues = useMemo<IValues>(() => ({
    firstName : '',
    lastName  : '',
    email     : '',
    phone     : '',
  }), []);

  const onSubmit = useCallback((values: IValues) => {
    console.log(values, 'values');
  }, []);

  return (
    <Card header={t('billingInfo')}>
      <Formik
        initialValues = {initialValues}
        onSubmit      = {onSubmit}
      >
        <MemberDetailsForm disabled={false} />
      </Formik>
    </Card>
  );
};
