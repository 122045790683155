/* eslint-disable camelcase */

import { useCallback, useEffect, useState } from 'react';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fcWidget                : { [key: string]: any };
    fcSettings              : { onInit: () => void; };
    fcWidgetMessengerConfig : {
      externalId : string | null;
      siteId     : 'License' | 'Organisation';
    };
  }
}

interface IResponse {
  status : number;
  data   : { [key: string]: unknown };
}

interface IUserProfileBasic {
  firstName : string;
  lastName  : string;
  email     : string;
  phone     : string | null;
  restoreId : string | null;
}

interface ILicenseMeta {
  cf_license_territory_names : string;
  cf_license_territory_ids   : string;
}

interface IOrganisationMeta {
  cf_organisation_name            : string;
  cf_organisation_id              : string;
  cf_organisation_territory_ids   : string;
  cf_organisation_territory_names : string;
}

export type UserProfileType<SiteId extends 'License' | 'Organisation'> = SiteId extends 'License'
  ? IUserProfileBasic & ILicenseMeta
  : IUserProfileBasic & IOrganisationMeta;

let isChatInitialized = false;

export const useChat = <SiteId extends 'License' | 'Organisation'>(
  siteId      : SiteId,
  userProfile : UserProfileType<SiteId> | null,
) => {
  const [isChatReady, setChatReadyStatus] = useState(isChatInitialized);

  const toggleChat = useCallback(async () => {
    if (window.fcWidget.isOpen()) {
      window.fcWidget.close();
    } else {
      if (isChatReady && userProfile) {
        await window.fcWidget.user.setProperties(userProfile);
      }

      window.fcWidget.open();
    }
  }, [isChatReady, userProfile]);

  useEffect(() => {
    if (isChatReady && userProfile && userProfile.email) {
      window.fcWidget.user.get(async (response?: IResponse) => {
        const status = response && response.status;
        // TODO: Add updating properties on update profile info
        if (status !== 200) {
          await window.fcWidget.user.setProperties(userProfile);

          window.fcWidget.on('user:created', async (responseOnCreated?: IResponse) => {
            const statusOnCreated = responseOnCreated && responseOnCreated.status;
            const data = responseOnCreated && responseOnCreated.data;

            if (statusOnCreated === 200) {
              await window.fcWidget.setExternalId(userProfile.email);

              if (data?.restoreId) {
              // Update restoreId in database
              }
            }
          });
        }
      });
    }
  }, [userProfile, isChatReady]);

  useEffect(() => {
    const scriptNode = document.createElement('script');

    if (userProfile && !isChatInitialized) {
      window.fcWidgetMessengerConfig = {
        siteId,
        externalId : userProfile.email,
      };

      window.fcSettings = {
        onInit() {
          isChatInitialized = true;

          setChatReadyStatus(true);
        },
      };

      scriptNode.async = true;
      scriptNode.src   = '//eu.fw-cdn.com/10401733/283628.js';
      scriptNode.type  = 'text/javascript';

      scriptNode.setAttribute('chat', 'false');

      document.body.appendChild(scriptNode);
    }
  }, [userProfile?.email]);

  return { isChatReady, toggleChat };
};
