import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import { Dashboard }          from '@modules/Dashboard/Dashboard';
import { Profile }            from '@modules/Profile/Profile';
import { Channel }            from '@modules/Channel/Channel';
import { Organizations }      from '@modules/Organizations/Organizations';
import { Billing }            from '@modules/Billing/Billing';
import { Login }              from '@modules/Login/Login';
import { PasswordRecovery }   from '@modules/Login/PasswordRecovery';
import { Slide }              from '@modules/Dashboard/views/Slide';
import { Story }              from '@modules/Dashboard/views/Story';
import { Event }              from '@modules/Events/views/Event';
import { EventsList }         from '@modules/Events/views/EventsList';
import { EventReview }        from '@modules/Events/views/EventReview';
import { OrganizationReview } from '@modules/Organizations/views/OrganizationReview';

import { MenuItemContainer } from '@components/Menu/MenuItemContainer';

import { useAccountState, AccountProvider } from './AccountContext';
import { routesConfig }                     from './components/Breadcrumbs/routesConfig';

const RoutesSwitch = () => {
  const { isAuthorized } = useAccountState();

  return (
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/restore-password" exact component={PasswordRecovery} />
      {isAuthorized && (
      <MenuItemContainer>
        <Route
          exact
          component = {Channel}
          path      = {routesConfig.channel.endpoint}
        />
        <Route
          exact
          component = {Billing}
          path      = {routesConfig.billing.endpoint}
        />
        <Route
          exact
          component = {Dashboard}
          path      = {routesConfig.dashboard.endpoint}
        />
        <Route
          exact
          component = {Organizations}
          path      = {routesConfig.organizations.endpoint}
        />
        <Route
          exact
          component = {OrganizationReview}
          path      = {routesConfig.organizationsReview.endpoint}
        />
        <Route
          exact
          component = {Profile}
          path      = {routesConfig.profile.endpoint}
        />
        <Route
          exact
          component = {EventsList}
          path      = {routesConfig.events.endpoint}
        />
        <Route
          exact
          component = {Event}
          path      = {[
            routesConfig.eventAdd.endpoint,
            routesConfig.eventClone.endpoint,
            routesConfig.eventEdit.endpoint,
          ]}
        />
        <Route
          exact
          component = {EventReview}
          path      = {routesConfig.eventReview.endpoint}
        />
        <Route
          exact
          component = {Slide}
          path      = {[routesConfig.slideAdd.endpoint, routesConfig.slideEdit.endpoint]}
        />
        <Route
          exact
          component = {Story}
          path      = {[routesConfig.storyAdd.endpoint, routesConfig.storyEdit.endpoint]}
        />
        <Redirect to={routesConfig.dashboard.endpoint} />
      </MenuItemContainer>
      )}
      <Redirect to="/login" />
    </Switch>
  );
};

export const Routes = () => (
  <BrowserRouter>
    <AccountProvider>
      <RoutesSwitch />
    </AccountProvider>
  </BrowserRouter>
);
