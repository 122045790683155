import { AxiosResponse }   from 'axios';
import { useMemo, useRef } from 'react';
import useSWR              from 'swr';

import { useAccountState } from '@src/AccountContext';
import { callApi }         from '@utils/apiCaller';

interface IBellItemResponse {
  count : number;
  ids   : number[];
}

export interface IUnapprovalItems {
  events        : number[];
  organizations : number[];
}

export const useUnapprovalItems = (): [IUnapprovalItems, boolean] => {
  const { currentTerritory } = useAccountState();

  const counters = useRef<IUnapprovalItems>({ events: [], organizations: [] });

  const { data: events, isValidating: isEventsFetching } = useSWR<AxiosResponse<IBellItemResponse>>(
    currentTerritory ? `feature/Event/unapproval-count/${currentTerritory?.territoryId}` : null,
    callApi,
  );

  const { data: organizations, isValidating: isOrganizationsFetching } = useSWR<AxiosResponse<IBellItemResponse>>(
    currentTerritory ? `feature/BusinessLocation/unapproval-count/${currentTerritory?.territoryId}` : null,
    callApi,
  );

  const isFetching = useMemo(() => (
    isEventsFetching || isOrganizationsFetching
  ), [isEventsFetching, isOrganizationsFetching]);

  if (events?.data && organizations?.data) {
    counters.current = {
      events        : events.data.ids,
      organizations : organizations.data.ids,
    };
  }

  return [counters.current, isFetching];
};
