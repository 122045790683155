import { AxiosResponse } from 'axios';
import { useRef }        from 'react';
import useSWR            from 'swr';

import { ITagModel } from '@common/models';
import { callApi }   from '@utils/apiCaller';

type TTagsHook = () => {
  tags: ITagModel[];
}

const useTags: TTagsHook = () => {
  const tags = useRef<ITagModel[]>([]);

  const { data } = useSWR<AxiosResponse<ITagModel[]>>(
    'api/Tags',
    callApi,
  );

  if (data?.data) {
    tags.current = data.data;
  }

  return { tags: tags.current };
};

export { useTags };
