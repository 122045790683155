import { memo, useState } from 'react';
import styled             from 'styled-components';

import {
  IRelatedEntitiesItemModel,
  RelatedEntitiesItem,
} from './index';

import { ChevronIcon } from '../../../../../public/svg';

interface IComponent {
  items: IRelatedEntitiesItemModel[];
  title: string;
}

const Component = ({
  items,
  title,
}: IComponent) => {
  const [collapsed, setCollapsedStatus] = useState(true);

  const visibleItems = collapsed ? items.slice(0, 2) : items;

  const onPressArrow = () => setCollapsedStatus(!collapsed);

  return (
    <Component.Wrapper>
      <h2>{title}</h2>
      {visibleItems.map((item) => (
        <>
          <Component.Spacer />
          <RelatedEntitiesItem item={item} />
        </>
      ))}
      <Component.ArrowButton
        collapsed = {collapsed}
        onClick   = {onPressArrow}
      >
        <ChevronIcon
          height = "40px"
          width  = "112px"
        />
      </Component.ArrowButton>
    </Component.Wrapper>
  );
};

export default memo(Component);

Component.ArrowButton = styled.div<{ collapsed: boolean }>`
  align-items     : center;
  cursor          : pointer;
  display         : flex;
  height          : 50px;
  justify-content : center;
  margin          : 5px 0;
  transition      : all 0.3s;

  ${({ collapsed }) => collapsed && `
    transform: rotate(180deg);
  `}
`;

Component.Spacer = styled.div`
  height: 13px;
`;

Component.Wrapper = styled.div`
  margin-top: 7px;

  div,
  h2,
  p { font-family: 'Poppins'; }

  h2 {
    color     : ${({ theme }) => theme.color.darkGray};
    font-size : 16px;
  }
`;
