export interface IRouteItem {
  endpoint        : string;
  localizationKey : string;
  path            : IRouteItem[];
}

const dashboard: IRouteItem = {
  endpoint        : '/dashboard',
  localizationKey : 'dashboard',
  path            : [],
};

const billing: IRouteItem = {
  endpoint        : '/dashboard/billing',
  localizationKey : 'billing',
  path            : [dashboard],
};

const channel: IRouteItem = {
  endpoint        : '/dashboard/channel',
  localizationKey : 'channel',
  path            : [dashboard],
};

const events: IRouteItem = {
  endpoint        : '/dashboard/events',
  localizationKey : 'events',
  path            : [dashboard],
};

const eventAdd: IRouteItem = {
  endpoint        : '/dashboard/events/add',
  localizationKey : 'createEvent',
  path            : [dashboard, events],
};

const eventClone: IRouteItem = {
  endpoint        : '/dashboard/events/clone/:id',
  localizationKey : 'cloneTheEvent',
  path            : [dashboard, events],
};

const eventEdit: IRouteItem = {
  endpoint        : '/dashboard/events/edit/:id',
  localizationKey : 'editTheEvent',
  path            : [dashboard, events],
};

const eventReview: IRouteItem = {
  endpoint        : '/dashboard/events/review/:id',
  localizationKey : 'reviewAndApprove',
  path            : [dashboard, events],
};

const helpCenter: IRouteItem = {
  endpoint        : 'http://forum.localnewsie.com',
  localizationKey : 'helpCenter',
  path            : [dashboard],
};

const organizations: IRouteItem = {
  endpoint        : '/dashboard/organizations',
  localizationKey : 'organizations',
  path            : [dashboard],
};

const organizationsReview: IRouteItem = {
  endpoint        : '/dashboard/organizations/review/:id',
  localizationKey : 'reviewAndApprove',
  path            : [dashboard, organizations],
};

const profile: IRouteItem = {
  endpoint        : '/dashboard/profile',
  localizationKey : 'profile',
  path            : [dashboard],
};

const slideAdd: IRouteItem = {
  endpoint        : '/dashboard/slides/add',
  localizationKey : 'createSlide',
  path            : [dashboard],
};

const slideEdit: IRouteItem = {
  endpoint        : '/dashboard/slides/edit/:id',
  localizationKey : 'editTheSlide',
  path            : [dashboard],
};

const storyAdd: IRouteItem = {
  endpoint        : '/dashboard/stories/add',
  localizationKey : 'createStory',
  path            : [dashboard],
};

const storyEdit: IRouteItem = {
  endpoint        : '/dashboard/stories/edit/:id',
  localizationKey : 'editTheStory',
  path            : [dashboard],
};

export const routesConfig = {
  billing,
  channel,
  dashboard,
  events,
  eventAdd,
  eventClone,
  eventEdit,
  eventReview,
  helpCenter,
  organizations,
  organizationsReview,
  profile,
  slideAdd,
  slideEdit,
  storyAdd,
  storyEdit,
};
