import { ISvgIconOptions } from './index';

export default ({ height = '22px', width = '22px' }: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 515 515">
    <path
      fill = "#cee1f2"
      d    = "M111.25,491.54C60.12,487.11,20,444.2,20,391.93V223.65c0-31.19,14.55-60.6,39.35-79.51l136-103.73 c35.82-27.32,85.47-27.32,121.29,0l9.2,7.02c-24.56-2.42-49.83,4.22-70.49,19.98L93.61,190.78C85.03,197.32,80,207.49,80,218.28 v200.65C80,447.52,92.01,473.31,111.25,491.54L111.25,491.54z"
    />
    <path
      fill = "#0058ff"
      d    = "M392,511.93H120c-66.17,0-120-53.83-120-120V223.65c0-37.19,17.66-72.86,47.23-95.42l136-103.73 c42.85-32.68,102.7-32.68,145.55,0L372,57.45V31.93c0-11.05,8.95-20,20-20s20,8.95,20,20v65.91c0,7.6-4.31,14.55-11.12,17.92 c-6.81,3.37-14.95,2.59-21-2.01l-75.36-57.42c-28.57-21.79-68.47-21.79-97.04-0.01l-136,103.73C51.77,175.08,40,198.86,40,223.65 v168.27c0,44.11,35.89,80,80,80h272c44.11,0,80-35.89,80-80V223.65c0-25.1-11.64-48.96-31.13-63.83 c-8.78-6.7-10.47-19.25-3.77-28.03c6.7-8.78,19.25-10.47,28.03-3.77c29.35,22.38,46.87,58.13,46.87,95.63v168.27 C512,458.09,458.17,511.93,392,511.93z"
    />
    <path
      fill = "#f0552a"
      d    = "M241,255.92c0,13.81-11.19,25-25,25s-25-11.19-25-25s11.19-25,25-25S241,242.12,241,255.92z M296,230.93 c-13.81,0-25,11.19-25,25s11.19,25,25,25s25-11.19,25-25C321,242.12,309.81,230.93,296,230.93z M216,310.93c-13.81,0-25,11.19-25,25 s11.19,25,25,25s25-11.19,25-25C241,322.12,229.81,310.93,216,310.93z M296,310.93c-13.81,0-25,11.19-25,25s11.19,25,25,25 s25-11.19,25-25C321,322.12,309.81,310.93,296,310.93z"
    />
  </svg>
);
