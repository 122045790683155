import { useMemo }        from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory }     from 'react-router-dom';

import { Card }                                   from '@common/components/Card';
import { FooterButtons, FOOTER_BUTTONS_POSITION } from '@common/components/Button/FooterButtons';

import { ERequestedEntityStatus } from '@common/enums';
import { IEventModel }            from '@common/models';

import { routesConfig }          from '@components/Breadcrumbs/routesConfig';
import { useFetchedEntity }      from '@hooks/useFetchedEntity';
import { useAccountState }       from '@src/AccountContext';
import { IOrganizationListItem } from '@src/models';

import { NewEvents }          from '../components/NewEvents';
import { NewOrganizations }   from '../components/NewOrganizations';
import { LastUpdatesWrapper } from '../components/LastUpdatesWrapper';

export interface INewOrganizationValues {
  iconUri      : string;
  name         : string;
  signedUpDate : string;
}

export interface INewEventValues {
  iconUri   : string;
  label     : string;
  startDate : string;
  status    : ERequestedEntityStatus;
}

export const LastUpdates = () => {
  const history              = useHistory();
  const { currentTerritory } = useAccountState();
  const { t }                = useTranslation();

  const [fetchedOrganizations] = useFetchedEntity<IOrganizationListItem>(
    `feature/BusinessLocation/license/${currentTerritory?.territoryId}?page=0&itemsPerPage=5`,
    null,
    { useTerritoryId: false },
  );
  const [fetchedEvents]        = useFetchedEntity<IEventModel>('feature/event?page=0&itemsPerPage=5');

  const eventsFooterButtons = useMemo(() => [
    { label: `${t('viewAll')} >`, onClick: () => history.push(routesConfig.events.endpoint) },
  ], []);

  const organizationsFooterButtons = useMemo(() => [
    { label: `${t('viewAll')} >`, onClick: () => history.push(routesConfig.organizations.endpoint) },
  ], []);

  return (
    <LastUpdatesWrapper>
      <Card
        header = {t('newOrganizations')}
        footer = {(
          <FooterButtons
            buttons      = {fetchedOrganizations?.items.length ? organizationsFooterButtons : []}
            position     = {FOOTER_BUTTONS_POSITION.HORIZONTAL_FULL_WIDTH}
            wrapperStyle = {(theme) => `
              border-top  : 1px solid ${theme.color.lightGray};
              padding     : 0;
              padding-top : 28px;

              button { padding: 0; }

              button:first-child {
                font-size   : 14px;
                font-weight : 500;
              }
            `}
          />
        )}
      >
        <NewOrganizations organizations={fetchedOrganizations?.items || []} />
      </Card>
      <Card
        header = {t('newEvents')}
        footer = {(
          <FooterButtons
            buttons  = {fetchedOrganizations?.items.length ? eventsFooterButtons : []}
            position = {FOOTER_BUTTONS_POSITION.HORIZONTAL_FULL_WIDTH}
            wrapperStyle = {(theme) => `
              border-top  : 1px solid ${theme.color.lightGray};
              padding     : 0;
              padding-top : 28px;

              button { padding: 0; }

              button:first-child {
                font-size   : 14px;
                font-weight : 500;
              }
            `}
          />
        )}
      >
        <NewEvents events={fetchedEvents?.items || []} />
      </Card>
    </LastUpdatesWrapper>
  );
};
