import { format }         from 'date-fns';
import { memo, useMemo }  from 'react';
import { useTranslation } from 'react-i18next';

import { RelatedEntitiesList }             from './index';
import { TMobileOrganizationPreviewProps } from '../OrganizationPreview';

type TPreviewStoriesProps = {
  stories : NonNullable<TMobileOrganizationPreviewProps['stories']>;
};

const PreviewStories = ({ stories }: TPreviewStoriesProps) => {
  const { t } = useTranslation();

  const mappedStories = useMemo(() => stories.map((story) => ({
    ...story,
    date : format(new Date(story.created), 'MMM d, yyyy'),
    id   : story.storyId,
  })), [stories]);

  if (stories.length === 0) {
    return null;
  }

  return (
    <RelatedEntitiesList
      items = {mappedStories}
      title = {t('featuredStories')}
    />
  );
};

export default memo(PreviewStories);
