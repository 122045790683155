import { useEffect, useState } from 'react';
import { useTranslation }      from 'react-i18next';

import { Card }          from '@common/components';
import { ECardStatuses } from '@common/enums';

import { EssentialTipsList } from '../components/EssentialTipsList';
import { useEssentialTips }  from '../hooks/useEssentialTips';

export const EssentialTipsView = () => {
  const { t } = useTranslation();

  const [status, setStatus] = useState(ECardStatuses.None);

  const { essentialTips, essentialTipsFetching } = useEssentialTips();

  useEffect(() => {
    setStatus(essentialTipsFetching ? ECardStatuses.Pending : ECardStatuses.None);
  }, [essentialTipsFetching]);

  return (
    <Card
      header = {t('essentialTips')}
      status = {status}
    >
      <EssentialTipsList tips={essentialTips} />
    </Card>
  );
};
