import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation }                            from 'react-i18next';
import { useHistory }                                from 'react-router-dom';

import {
  ContentRow,
  ContentSeparator,
  ContentWrapper,
  NotificationsBell as CommonNotificationBell,
} from '@common/components/NotificationsBell';

import { useUnapprovalItems } from '@hooks/useUnapprovalItems';
import {
  EBellStorageKey,
  getBellSettingsFromLocalStorage,
  setBellSettingsToLocalStorage,
} from '@utils/bellStorageHandlers';

import { routesConfig } from '../Breadcrumbs/routesConfig';

interface IPopoverContentProps {
  onClickBellItem : (bellItem: EBellStorageKey) => void;
  items         : {
    events        : number;
    organizations : number;
  };
}

const PopoverContent = ({ items, onClickBellItem }: IPopoverContentProps) => {
  const { t } = useTranslation();

  return (
    <ContentWrapper>
      {!!items.organizations && (
        <ContentRow onClick={() => onClickBellItem(EBellStorageKey.Organizations)}>
          {items.organizations > 1 ? (
            <p><span>{items.organizations} {t('organizations')}</span> {t('needApproval')}</p>
          ) : (
            <p><span>{items.organizations} {t('organization')}</span> {t('needsApproval')}</p>
          )}
        </ContentRow>
      )}
      {!!items.events && (
        <>
          {!!items.organizations && <ContentSeparator />}
          <ContentRow onClick={() => onClickBellItem(EBellStorageKey.Events)}>
            {items.events > 1 ? (
              <p><span>{items.events} {t('events')}</span> {t('needApproval')}</p>
            ) : (
              <p><span>{items.events} {t('event')}</span> {t('needsApproval')}</p>
            )}
          </ContentRow>
        </>
      )}
    </ContentWrapper>
  );
};

export const NotificationBell = () => {
  const history                = useHistory();
  const [counters, isFetching] = useUnapprovalItems();

  const [filteredItems, setItems] = useState({ events: 0, organizations: 0 });

  const filterItems = () => {
    const { events: prevEvents, organizations: prevOrganizations } = getBellSettingsFromLocalStorage();
    const { events, organizations } = counters;

    const newEvents = {
      new     : events.filter((eventId) => !prevEvents.watched.includes(eventId)),
      watched : prevEvents.watched.filter((eventId) => events.includes(eventId)),
    };

    const newOrganizations = {
      new     : organizations.filter((organizationId) => !prevOrganizations.watched.includes(organizationId)),
      watched : prevOrganizations.watched.filter((organizationId) => organizations.includes(organizationId)),
    };

    setBellSettingsToLocalStorage({ events: newEvents, organizations: newOrganizations });
    setItems({ events: newEvents.new.length, organizations: newOrganizations.new.length });
  };

  const onClickBellItem = useCallback((eventType: EBellStorageKey) => {
    const { events, organizations } = getBellSettingsFromLocalStorage();

    if (eventType === EBellStorageKey.Events) {
      setBellSettingsToLocalStorage({ events: { new: [], watched: [...events.watched, ...events.new] } });
      history.push(routesConfig.events.endpoint);
    } else if (eventType === EBellStorageKey.Organizations) {
      setBellSettingsToLocalStorage({
        organizations : {
          new     : [],
          watched : [...organizations.watched, ...organizations.new],
        },
      });
      history.push(routesConfig.organizations.endpoint);
    }

    filterItems();
  }, [filterItems]);

  const badgeCount = useMemo(() => (
    Object.values(filteredItems).filter((item: number) => item > 0).length
  ), [filteredItems]);

  const content = useMemo(() => (
    <PopoverContent
      items           = {filteredItems}
      onClickBellItem = {onClickBellItem}
    />
  ), [filteredItems]);

  useEffect(() => {
    if (!isFetching && (!!counters.events.length || !!counters.organizations.length)) {
      filterItems();
    }
  }, [isFetching]);

  return (
    <CommonNotificationBell
      content            = {content}
      notificationsCount = {badgeCount}
    />
  );
};
