import { ISvgIconOptions } from './index';

export default ({ height = '22px', width = '22px' }: ISvgIconOptions) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={height} width={width} viewBox="0 0 515 515">
    <path
      fill = "#cee1f2"
      d    = "M285,248.84c-9.2,3.96-19.34,6.16-30,6.16c-41.97,0-76-34.03-76-76s34.03-76,76-76c10.66,0,20.8,2.2,30,6.16 c-27.05,11.64-46,38.52-46,69.84S257.95,237.2,285,248.84L285,248.84z M80,256c0-120.18,89.83-219.37,206-234.11 c-9.82-1.25-19.84-1.89-30-1.89C125.66,20,20,125.66,20,256s105.66,236,236,236c10.16,0,20.18-0.64,30-1.89 C169.83,475.37,80,376.18,80,256L80,256z"
    />
    <path
      fill = "#0058ff"
      d    = "M256,512c-68.38,0-132.67-26.63-181.02-74.98C26.63,388.67,0,324.38,0,256S26.63,123.33,74.98,74.98 C123.33,26.63,187.62,0,256,0s132.67,26.63,181.02,74.98C485.37,123.33,512,187.62,512,256s-26.63,132.67-74.98,181.02 C388.67,485.37,324.38,512,256,512L256,512z M256,40C136.9,40,40,136.9,40,256s96.9,216,216,216s216-96.9,216-216S375.1,40,256,40z M255,275c-52.93,0-96-43.07-96-96s43.07-96,96-96s96,43.07,96,96S307.93,275,255,275z M255,123c-30.88,0-56,25.12-56,56 s25.12,56,56,56s56-25.12,56-56S285.88,123,255,123z"
    />
    <path
      fill = "#f0552a"
      d    = "M369.05,396c-5.79,0-11.53-2.5-15.48-7.33c-20.65-25.21-51.16-39.67-83.7-39.67h-23.73 c-32.54,0-63.05,14.46-83.7,39.67c-7,8.54-19.6,9.8-28.15,2.8c-8.55-7-9.8-19.6-2.8-28.15C159.77,328.8,201.56,309,246.14,309 h23.73c44.58,0,86.37,19.8,114.65,54.33c7,8.55,5.75,21.15-2.8,28.15C377.99,394.52,373.51,396,369.05,396L369.05,396z"
    />
  </svg>
);
